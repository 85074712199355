/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.18.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChildResponse } from '../models';
// @ts-ignore
import { DeleteChildResponse } from '../models';
// @ts-ignore
import { FinalizeOnboardingRequest } from '../models';
// @ts-ignore
import { FinalizeOnboardingResponse } from '../models';
// @ts-ignore
import { UpdateChildConfigRequest } from '../models';
// @ts-ignore
import { UpdateChildConfigResponse } from '../models';
// @ts-ignore
import { UpdateChildInfoRequest } from '../models';
// @ts-ignore
import { UpdateChildInfoResponse } from '../models';
// @ts-ignore
import { UpdateDeviceInfoRequest } from '../models';
/**
 * ChildrenApi - axios parameter creator
 * @export
 */
export const ChildrenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChild: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('deleteChild', 'childId', childId)
            const localVarPath = `/main/children/{childId}`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeOnboarding: async (finalizeOnboardingRequest: FinalizeOnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizeOnboardingRequest' is not null or undefined
            assertParamExists('finalizeOnboarding', 'finalizeOnboardingRequest', finalizeOnboardingRequest)
            const localVarPath = `/main/children/finalizeOnboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeOnboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChild: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutGlobally: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('signOutGlobally', 'childId', childId)
            const localVarPath = `/main/children/{childId}/globalSignOut`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildConfigRequest} updateChildConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildConfig: async (childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateChildConfig', 'childId', childId)
            // verify required parameter 'updateChildConfigRequest' is not null or undefined
            assertParamExists('updateChildConfig', 'updateChildConfigRequest', updateChildConfigRequest)
            const localVarPath = `/main/children/{childId}/config`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChildConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildInfoRequest} updateChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildInfo: async (childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateChildInfo', 'childId', childId)
            // verify required parameter 'updateChildInfoRequest' is not null or undefined
            assertParamExists('updateChildInfo', 'updateChildInfoRequest', updateChildInfoRequest)
            const localVarPath = `/main/children/{childId}/info`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChildInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceInfo: async (childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateDeviceInfo', 'childId', childId)
            // verify required parameter 'updateDeviceInfoRequest' is not null or undefined
            assertParamExists('updateDeviceInfo', 'updateDeviceInfoRequest', updateDeviceInfoRequest)
            const localVarPath = `/main/children/{childId}/deviceInfo`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChildrenApi - functional programming interface
 * @export
 */
export const ChildrenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChildrenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChild(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChild(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeOnboarding(finalizeOnboardingRequest: FinalizeOnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizeOnboardingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeOnboarding(finalizeOnboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChild(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChild(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signOutGlobally(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signOutGlobally(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildConfigRequest} updateChildConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChildConfig(childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateChildConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChildConfig(childId, updateChildConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildInfoRequest} updateChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChildInfo(childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateChildInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChildInfo(childId, updateChildInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceInfo(childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceInfoRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceInfo(childId, updateDeviceInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChildrenApi - factory interface
 * @export
 */
export const ChildrenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChildrenApiFp(configuration)
    return {
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChild(childId: string, options?: any): AxiosPromise<DeleteChildResponse> {
            return localVarFp.deleteChild(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeOnboarding(finalizeOnboardingRequest: FinalizeOnboardingRequest, options?: any): AxiosPromise<FinalizeOnboardingResponse> {
            return localVarFp.finalizeOnboarding(finalizeOnboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChild(options?: any): AxiosPromise<ChildResponse> {
            return localVarFp.getChild(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutGlobally(childId: string, options?: any): AxiosPromise<void> {
            return localVarFp.signOutGlobally(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildConfigRequest} updateChildConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildConfig(childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options?: any): AxiosPromise<UpdateChildConfigResponse> {
            return localVarFp.updateChildConfig(childId, updateChildConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildInfoRequest} updateChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildInfo(childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options?: any): AxiosPromise<UpdateChildInfoResponse> {
            return localVarFp.updateChildInfo(childId, updateChildInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceInfo(childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options?: any): AxiosPromise<UpdateDeviceInfoRequest> {
            return localVarFp.updateDeviceInfo(childId, updateDeviceInfoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChildrenApi - object-oriented interface
 * @export
 * @class ChildrenApi
 * @extends {BaseAPI}
 */
export class ChildrenApi extends BaseAPI {
    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public deleteChild(childId: string, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).deleteChild(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public finalizeOnboarding(finalizeOnboardingRequest: FinalizeOnboardingRequest, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).finalizeOnboarding(finalizeOnboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChild(options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChild(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public signOutGlobally(childId: string, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).signOutGlobally(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {UpdateChildConfigRequest} updateChildConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public updateChildConfig(childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).updateChildConfig(childId, updateChildConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {UpdateChildInfoRequest} updateChildInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public updateChildInfo(childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).updateChildInfo(childId, updateChildInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public updateDeviceInfo(childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).updateDeviceInfo(childId, updateDeviceInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
