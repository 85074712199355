import { NoNotification } from "./no-notification";
import { useNotificationContext } from "./notification-context.tsx";
import { NotificationList } from "./notification-list.tsx";

export const AllNotificationPanel = () => {
  const {
    allNotifications: { hasNextPage, isLoading, isNextPageLoading, loadNextPage, data: notifications },
  } = useNotificationContext();
  if (notifications.length === 0) {
    return <NoNotification panel="all" />;
  }
  return (
    <NotificationList
      notifications={notifications}
      loadNextPage={loadNextPage}
      hasNextPage={hasNextPage}
      isNextPageLoading={isNextPageLoading}
      isLoading={isLoading}
    />
  );
};
