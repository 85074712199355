import { NotificationInteraction } from "@/openapi/models/notification-interaction";
import {
  NotificationLogResponseDto,
} from "@/openapi/models/notification-log-response-dto";
import { NotificationObjectType } from "../../openapi";

export const getNotificationObject = (notification: NotificationLogResponseDto) => {
  switch (notification.notificationObjectType) {
    case NotificationObjectType.Contact:
      return notification.contact;
    case NotificationObjectType.App:
      return notification.app;
    case NotificationObjectType.Geofence:
      return notification.geofence;
    case NotificationObjectType.PaymentFailed:
      return notification.paymentFailed;
    default:
      return null;
  }
};

export const isNotificationUnread = (notification: NotificationLogResponseDto) => {
  return notification.interaction === undefined;
};

export const markNotificationAsRead = (notification: NotificationLogResponseDto): NotificationLogResponseDto => {
  switch (notification.notificationObjectType) {
    case NotificationObjectType.Contact:
      if (notification.contact) {
        return {
          ...notification,
          interaction: NotificationInteraction.Seen,
          contact: {
            ...notification.contact,
            interaction: NotificationInteraction.Seen,
          },
        };
      }
      return notification;
    case NotificationObjectType.App:
      if (notification.app) {
        return {
          ...notification,
          interaction: NotificationInteraction.Seen,
          app: {
            ...notification.app,
            interaction: NotificationInteraction.Seen,
          },
        };
      }
      return notification;
    case NotificationObjectType.Geofence:
      if (notification.geofence) {
        return {
          ...notification,
          interaction: NotificationInteraction.Seen,
          geofence: {
            ...notification.geofence,
            interaction: NotificationInteraction.Seen,
          },
        };
      }
      return notification;
    case NotificationObjectType.PaymentFailed:
      if (notification.paymentFailed) {
        return {
          ...notification,
          interaction: NotificationInteraction.Seen,
          paymentFailed: {
            ...notification.paymentFailed,
            interaction: NotificationInteraction.Seen,
          },
        };
      }
      return { ...notification, interaction: NotificationInteraction.Seen };
    default:
      return { ...notification, interaction: NotificationInteraction.Seen };
  }
};
