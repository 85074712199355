import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useNotificationLogs, useNotificationOps } from "./hooks";
import { notificationReducerInitialState } from "./notification-reducer";
import { isNotificationUnread } from "./utils";
import { NotificationObjectCompletedAction, NotificationObjectType, SafeListMode } from "@/openapi";
import { useSafeListMode } from "@/util/child-helper";
import { useIsWatchChild } from "@/hooks";

interface NotificationContextProps {
  isNotificationPanelOpen: boolean;
  toggleNotificationPanel: () => void;
  allNotifications: ReturnType<typeof useNotificationLogs> & {
    unreadNotifications: ReturnType<typeof useNotificationLogs>["data"];
  };
  actionNotifications: ReturnType<typeof useNotificationLogs> & {
    unreadNotifications: ReturnType<typeof useNotificationLogs>["data"];
  };
  markAllAsRead: () => void;
  markAsRead: (id: string) => void;
  updateNotificationCompletedAction: (id: string, completedAction: NotificationObjectCompletedAction) => void;
  refetch: () => void;
  setCompletedAction: (ids: string[], completedAction: NotificationObjectCompletedAction) => void;
  getNotificationsByObjectType: (objectType: NotificationObjectType) => ReturnType<typeof useNotificationLogs>["data"];
}

const notificationContextInitialState = {
  ...notificationReducerInitialState,
  unreadNotifications: [],
  loadNextPage: () => Promise.resolve(),
  markAllAsRead: () => Promise.resolve(),
  markAsRead: () => Promise.resolve(),
  updateNotificationCompletedAction: () => Promise.resolve(),
  removeNotification: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
};

export const NotificationContext = createContext<NotificationContextProps>({
  isNotificationPanelOpen: false,
  toggleNotificationPanel: () => {},
  allNotifications: notificationContextInitialState,
  actionNotifications: notificationContextInitialState,
  markAllAsRead: () => {},
  markAsRead: () => {},
  updateNotificationCompletedAction: () => {},
  refetch: () => Promise.resolve(),
  setCompletedAction: () => {},
  getNotificationsByObjectType: () => [],
});

interface NotificationProviderProps {
  children: React.ReactNode;
}

const excludeCompletedAction = [NotificationObjectCompletedAction.Deleted];

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const safeListMode = useSafeListMode();
  const isWatchChild = useIsWatchChild();
  const notificationObjectTypes = useMemo(() => {
    if (isWatchChild) {
      return [NotificationObjectType.PaymentFailed];
    }
    if (safeListMode === SafeListMode.Approved) {
      return [NotificationObjectType.Contact, NotificationObjectType.App, NotificationObjectType.PaymentFailed];
    } else {
      return [NotificationObjectType.App, NotificationObjectType.PaymentFailed];
    }
  }, [isWatchChild, safeListMode]);

  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
  const allNotificationsState = useNotificationLogs({ limit: 100, offset: 0, excludeCompletedAction });
  const actionNotificationsState = useNotificationLogs({
    limit: 100,
    offset: 0,
    isActionCompleted: false,
    notificationObjectType: notificationObjectTypes,
  });

  const { markAllAsRead, markAsRead, updateNotificationCompletedAction, setCompletedAction } = useNotificationOps(
    allNotificationsState,
    actionNotificationsState,
  );

  const unreadAllNotifications = useMemo(() => allNotificationsState.data.filter(isNotificationUnread), [allNotificationsState.data]);

  const unreadActionNotifications = useMemo(
    () => actionNotificationsState.data.filter(isNotificationUnread),
    [actionNotificationsState.data],
  );

  const toggleNotificationPanel = useCallback(() => {
    setIsNotificationPanelOpen((prev) => !prev);
  }, []);

  const getNotificationsByObjectType = useCallback(
    (objectType: NotificationObjectType) => {
      const notifications = allNotificationsState.data.filter((notification) => notification.notificationObjectType === objectType);
      const actionNotifications = actionNotificationsState.data.filter(
        (notification) => notification.notificationObjectType === objectType,
      );
      return [...new Map([...notifications, ...actionNotifications].map((item) => [item.id, item])).values()];
    },
    [actionNotificationsState.data, allNotificationsState.data],
  );

  const allNotifications = useMemo(
    () => ({ ...allNotificationsState, unreadNotifications: unreadAllNotifications }),
    [allNotificationsState, unreadAllNotifications],
  );

  const actionNotifications = useMemo(
    () => ({ ...actionNotificationsState, unreadNotifications: unreadActionNotifications }),
    [actionNotificationsState, unreadActionNotifications],
  );

  const refetch = useCallback(() => {
    allNotificationsState.refetch();
    actionNotificationsState.refetch();
  }, [actionNotificationsState, allNotificationsState]);

  const value = useMemo(
    () => ({
      isNotificationPanelOpen,
      toggleNotificationPanel,
      allNotifications,
      actionNotifications,
      markAllAsRead,
      markAsRead,
      updateNotificationCompletedAction,
      refetch,
      setCompletedAction,
      getNotificationsByObjectType,
    }),
    [
      isNotificationPanelOpen,
      toggleNotificationPanel,
      allNotifications,
      actionNotifications,
      markAllAsRead,
      markAsRead,
      updateNotificationCompletedAction,
      refetch,
      setCompletedAction,
      getNotificationsByObjectType,
    ],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotificationContext must be used within a NotificationProvider");
  }

  return context;
};
