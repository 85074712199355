import { AppNotificationLogDto, NotificationObjectCompletedAction } from "@/openapi";
import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { NotificationStatus, NotificationTitle, StyledButton } from "./common";
import { useNotificationContext } from "@/components/NotificationCenter/notification-context.tsx";
import { usePageNavigation } from "@/util/navigation-helper.ts";

interface Props {
  notification: AppNotificationLogDto;
  isSameChild?: boolean;
}

export const AppRequest: React.FC<Props> = ({ notification, isSameChild = false }) => {
  const pageNavigate = usePageNavigation();
  const { toggleNotificationPanel } = useNotificationContext();

  const handleReviewApp = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      toggleNotificationPanel();
      pageNavigate("/apps/pending");
    },
    [pageNavigate, toggleNotificationPanel],
  );

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", rowGap: 1 }}
    >
      <NotificationTitle>
        <b>{notification.notificationObjectMetadata.props.childName}</b> requested a new app named{" "}
        <b>{notification.notificationObjectMetadata.props.appName}</b>
      </NotificationTitle>
      {notification.completedAction &&
      (notification.completedAction === NotificationObjectCompletedAction.Approved ||
        notification.completedAction === NotificationObjectCompletedAction.Rejected) ? (
        notification.completedAction === NotificationObjectCompletedAction.Approved ? (
          <NotificationStatus status="success" text="App approved" />
        ) : (
          <NotificationStatus status="error" text="App rejected" />
        )
      ) : isSameChild ? (
        <Box sx={{ display: "flex", columnGap: 2, justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
          <StyledButton variant="contained" size="small" onClick={handleReviewApp}>
            Review App
          </StyledButton>
        </Box>
      ) : null}
    </Box>
  );
};
