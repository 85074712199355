import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const usePageNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = useCallback(
    (to: string, { replace = false, state = {} } = {}) => {
      if (location.pathname === to) {
        return;
      }

      if (replace) {
        navigate(to, { replace: true, state });
      } else {
        navigate(to, { state });
      }
    },
    [location.pathname, navigate],
  );

  return navigateTo;
};
