import React from "react";
import {
  AppsApi,
  AppCategoriesApi,
  AppDescriptionsApi,
  CallLogsApi,
  CaregiversApi,
  ChildrenApi,
  ContactGroupsApi,
  ContactsApi,
  DayPresetsApi,
  FcmTokensApi,
  GeolocationsApi,
  MessagesApi,
  ModesApi,
  RoutinesApi,
  DeviceControlApi,
  DeviceAuthApi,
} from "../openapi";
import {
  getAppsService,
  getAppCategoriesService,
  getAppsDescriptionService,
  getModesService,
  getCallLogService,
  getCaregiversService,
  getChildrenService,
  getContactGroupsService,
  getContactsService,
  getDaysPresetService,
  getFCMService,
  getGeolocationsService,
  getMessagesService,
  getRoutinesService,
  getDeviceDataById,
  getBiometricsService,
  getDeviceGroupsService,
  getDeviceAuthService,
} from "../util/api-helper";
import { BiometricsApi } from "../openapi/api/biometrics-api";

interface CtxInterface {
  getAppsService: () => Promise<AppsApi>;
  getAppCategoriesService: () => Promise<AppCategoriesApi>;
  getAppsDescriptionService: () => Promise<AppDescriptionsApi>;
  getModesService: () => Promise<ModesApi>;
  getCallLogService: () => Promise<CallLogsApi>;
  getCaregiversService: () => Promise<CaregiversApi>;
  getChildrenService: () => Promise<ChildrenApi>;
  getContactGroupsService: () => Promise<ContactGroupsApi>;
  getContactsService: () => Promise<ContactsApi>;
  getDaysPresetService: () => Promise<DayPresetsApi>;
  getFCMService: () => Promise<FcmTokensApi>;
  getGeolocationsService: () => Promise<GeolocationsApi>;
  getMessagesService: () => Promise<MessagesApi>;
  getRoutinesService: () => Promise<RoutinesApi>;
  getDeviceDataById: (deviceId: string) => Promise<Response>;
  getBiometricsService: () => Promise<BiometricsApi>;
  getDeviceGroupsService: () => Promise<DeviceControlApi>;
  getDeviceAuthService: () => Promise<DeviceAuthApi>;
}
const contextValues: CtxInterface = {
  getAppsService,
  getAppCategoriesService,
  getAppsDescriptionService,
  getModesService,
  getCallLogService,
  getCaregiversService,
  getChildrenService,
  getContactGroupsService,
  getContactsService,
  getDaysPresetService,
  getFCMService,
  getGeolocationsService,
  getMessagesService,
  getRoutinesService,
  getDeviceDataById,
  getBiometricsService,
  getDeviceGroupsService,
  getDeviceAuthService,
};
export const APIContext = React.createContext(contextValues);

export const APIProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  return <APIContext.Provider value={contextValues}>{props.children}</APIContext.Provider>;
};

export default APIProvider;
