/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactGroupsResponse } from '../models';
// @ts-ignore
import { CreateContactGroupRequest } from '../models';
// @ts-ignore
import { CreateContactGroupResponse } from '../models';
// @ts-ignore
import { UpdateContactGroupRequest } from '../models';
// @ts-ignore
import { UpdateContactGroupResponse } from '../models';
/**
 * ContactGroupsApi - axios parameter creator
 * @export
 */
export const ContactGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateContactGroupRequest} createContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactGroup: async (createContactGroupRequest: CreateContactGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContactGroupRequest' is not null or undefined
            assertParamExists('createContactGroup', 'createContactGroupRequest', createContactGroupRequest)
            const localVarPath = `/main/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildContactGroups: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildContactGroups', 'childId', childId)
            const localVarPath = `/main/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateContactGroupRequest} updateContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactGroup: async (updateContactGroupRequest: UpdateContactGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateContactGroupRequest' is not null or undefined
            assertParamExists('updateContactGroup', 'updateContactGroupRequest', updateContactGroupRequest)
            const localVarPath = `/main/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactGroupsApi - functional programming interface
 * @export
 */
export const ContactGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateContactGroupRequest} createContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactGroup(createContactGroupRequest: CreateContactGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateContactGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactGroup(createContactGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildContactGroups(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactGroupsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildContactGroups(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateContactGroupRequest} updateContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactGroup(updateContactGroupRequest: UpdateContactGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateContactGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactGroup(updateContactGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactGroupsApi - factory interface
 * @export
 */
export const ContactGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateContactGroupRequest} createContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactGroup(createContactGroupRequest: CreateContactGroupRequest, options?: any): AxiosPromise<CreateContactGroupResponse> {
            return localVarFp.createContactGroup(createContactGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildContactGroups(childId: string, options?: any): AxiosPromise<Array<ContactGroupsResponse>> {
            return localVarFp.getChildContactGroups(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateContactGroupRequest} updateContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactGroup(updateContactGroupRequest: UpdateContactGroupRequest, options?: any): AxiosPromise<UpdateContactGroupResponse> {
            return localVarFp.updateContactGroup(updateContactGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactGroupsApi - object-oriented interface
 * @export
 * @class ContactGroupsApi
 * @extends {BaseAPI}
 */
export class ContactGroupsApi extends BaseAPI {
    /**
     * 
     * @param {CreateContactGroupRequest} createContactGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupsApi
     */
    public createContactGroup(createContactGroupRequest: CreateContactGroupRequest, options?: AxiosRequestConfig) {
        return ContactGroupsApiFp(this.configuration).createContactGroup(createContactGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupsApi
     */
    public getChildContactGroups(childId: string, options?: AxiosRequestConfig) {
        return ContactGroupsApiFp(this.configuration).getChildContactGroups(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateContactGroupRequest} updateContactGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupsApi
     */
    public updateContactGroup(updateContactGroupRequest: UpdateContactGroupRequest, options?: AxiosRequestConfig) {
        return ContactGroupsApiFp(this.configuration).updateContactGroup(updateContactGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
