import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const NotificationDot = styled(Box)(() => ({
  height: "18px",
  width: "18px",
  borderRadius: "50%",
  backgroundColor: "#2EA9FF",
  color: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
}));
