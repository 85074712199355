/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.28.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchDaysRequest } from '../models';
// @ts-ignore
import { BatchDaysResponse } from '../models';
// @ts-ignore
import { CalendarDayResponse } from '../models';
// @ts-ignore
import { CreateCalendarDayRequest } from '../models';
// @ts-ignore
import { CreateDayPresetRequest } from '../models';
// @ts-ignore
import { CreateWeekDaysRequest } from '../models';
// @ts-ignore
import { CreateWeekDaysResponse } from '../models';
// @ts-ignore
import { DayPresetResponse } from '../models';
// @ts-ignore
import { DayPresetsResponse } from '../models';
// @ts-ignore
import { UpdateCalendarDayRequest } from '../models';
// @ts-ignore
import { UpdateDayPresetRequest } from '../models';
// @ts-ignore
import { UpdateWeekDayRequest } from '../models';
// @ts-ignore
import { WeekDayResponse } from '../models';
/**
 * DayPresetsApi - axios parameter creator
 * @export
 */
export const DayPresetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCalendarDayRequest} createCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarDay: async (createCalendarDayRequest: CreateCalendarDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalendarDayRequest' is not null or undefined
            assertParamExists('createCalendarDay', 'createCalendarDayRequest', createCalendarDayRequest)
            const localVarPath = `/main/dayPresets/calendarDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDayPresetRequest} createDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDayPreset: async (createDayPresetRequest: CreateDayPresetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDayPresetRequest' is not null or undefined
            assertParamExists('createDayPreset', 'createDayPresetRequest', createDayPresetRequest)
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDayPresetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWeekDaysRequest} createWeekDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWeekDays: async (createWeekDaysRequest: CreateWeekDaysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWeekDaysRequest' is not null or undefined
            assertParamExists('createWeekDays', 'createWeekDaysRequest', createWeekDaysRequest)
            const localVarPath = `/main/dayPresets/weekDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWeekDaysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dayPresetId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDayPreset: async (dayPresetId: string, replacementId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dayPresetId' is not null or undefined
            assertParamExists('deleteDayPreset', 'dayPresetId', dayPresetId)
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dayPresetId !== undefined) {
                localVarQueryParameter['dayPresetId'] = dayPresetId;
            }

            if (replacementId !== undefined) {
                localVarQueryParameter['replacementId'] = replacementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarDays: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/dayPresets/calendarDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPresets: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekDays: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/dayPresets/weekDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchDaysRequest} batchDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchDays: async (batchDaysRequest: BatchDaysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchDaysRequest' is not null or undefined
            assertParamExists('updateBatchDays', 'batchDaysRequest', batchDaysRequest)
            const localVarPath = `/main/dayPresets/batchDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchDaysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarDay: async (updateCalendarDayRequest: UpdateCalendarDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCalendarDayRequest' is not null or undefined
            assertParamExists('updateCalendarDay', 'updateCalendarDayRequest', updateCalendarDayRequest)
            const localVarPath = `/main/dayPresets/calendarDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalendarDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDayPresetRequest} updateDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDayPreset: async (updateDayPresetRequest: UpdateDayPresetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDayPresetRequest' is not null or undefined
            assertParamExists('updateDayPreset', 'updateDayPresetRequest', updateDayPresetRequest)
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDayPresetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWeekDayRequest} updateWeekDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeekDay: async (updateWeekDayRequest: UpdateWeekDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWeekDayRequest' is not null or undefined
            assertParamExists('updateWeekDay', 'updateWeekDayRequest', updateWeekDayRequest)
            const localVarPath = `/main/dayPresets/weekDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWeekDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DayPresetsApi - functional programming interface
 * @export
 */
export const DayPresetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DayPresetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCalendarDayRequest} createCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendarDay(createCalendarDayRequest: CreateCalendarDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendarDay(createCalendarDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDayPresetRequest} createDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDayPreset(createDayPresetRequest: CreateDayPresetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDayPreset(createDayPresetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWeekDaysRequest} createWeekDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWeekDays(createWeekDaysRequest: CreateWeekDaysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWeekDaysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWeekDays(createWeekDaysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dayPresetId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDayPreset(dayPresetId: string, replacementId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDayPreset(dayPresetId, replacementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarDays(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarDayResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarDays(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayPresets(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayPresetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayPresets(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekDays(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeekDayResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekDays(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BatchDaysRequest} batchDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatchDays(batchDaysRequest: BatchDaysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchDaysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBatchDays(batchDaysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarDay(updateCalendarDayRequest: UpdateCalendarDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarDay(updateCalendarDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDayPresetRequest} updateDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDayPreset(updateDayPresetRequest: UpdateDayPresetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDayPreset(updateDayPresetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWeekDayRequest} updateWeekDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWeekDay(updateWeekDayRequest: UpdateWeekDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeekDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWeekDay(updateWeekDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DayPresetsApi - factory interface
 * @export
 */
export const DayPresetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DayPresetsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCalendarDayRequest} createCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarDay(createCalendarDayRequest: CreateCalendarDayRequest, options?: any): AxiosPromise<CalendarDayResponse> {
            return localVarFp.createCalendarDay(createCalendarDayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDayPresetRequest} createDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDayPreset(createDayPresetRequest: CreateDayPresetRequest, options?: any): AxiosPromise<DayPresetResponse> {
            return localVarFp.createDayPreset(createDayPresetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWeekDaysRequest} createWeekDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWeekDays(createWeekDaysRequest: CreateWeekDaysRequest, options?: any): AxiosPromise<CreateWeekDaysResponse> {
            return localVarFp.createWeekDays(createWeekDaysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dayPresetId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDayPreset(dayPresetId: string, replacementId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteDayPreset(dayPresetId, replacementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarDays(childrenId?: string, options?: any): AxiosPromise<Array<CalendarDayResponse>> {
            return localVarFp.getCalendarDays(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPresets(childrenId?: string, options?: any): AxiosPromise<DayPresetsResponse> {
            return localVarFp.getDayPresets(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekDays(childrenId?: string, options?: any): AxiosPromise<Array<WeekDayResponse>> {
            return localVarFp.getWeekDays(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchDaysRequest} batchDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchDays(batchDaysRequest: BatchDaysRequest, options?: any): AxiosPromise<BatchDaysResponse> {
            return localVarFp.updateBatchDays(batchDaysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarDay(updateCalendarDayRequest: UpdateCalendarDayRequest, options?: any): AxiosPromise<CalendarDayResponse> {
            return localVarFp.updateCalendarDay(updateCalendarDayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDayPresetRequest} updateDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDayPreset(updateDayPresetRequest: UpdateDayPresetRequest, options?: any): AxiosPromise<DayPresetResponse> {
            return localVarFp.updateDayPreset(updateDayPresetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWeekDayRequest} updateWeekDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeekDay(updateWeekDayRequest: UpdateWeekDayRequest, options?: any): AxiosPromise<WeekDayResponse> {
            return localVarFp.updateWeekDay(updateWeekDayRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DayPresetsApi - object-oriented interface
 * @export
 * @class DayPresetsApi
 * @extends {BaseAPI}
 */
export class DayPresetsApi extends BaseAPI {
    /**
     * 
     * @param {CreateCalendarDayRequest} createCalendarDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public createCalendarDay(createCalendarDayRequest: CreateCalendarDayRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).createCalendarDay(createCalendarDayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDayPresetRequest} createDayPresetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public createDayPreset(createDayPresetRequest: CreateDayPresetRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).createDayPreset(createDayPresetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWeekDaysRequest} createWeekDaysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public createWeekDays(createWeekDaysRequest: CreateWeekDaysRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).createWeekDays(createWeekDaysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dayPresetId 
     * @param {string} [replacementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public deleteDayPreset(dayPresetId: string, replacementId?: string, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).deleteDayPreset(dayPresetId, replacementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public getCalendarDays(childrenId?: string, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).getCalendarDays(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public getDayPresets(childrenId?: string, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).getDayPresets(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public getWeekDays(childrenId?: string, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).getWeekDays(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchDaysRequest} batchDaysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public updateBatchDays(batchDaysRequest: BatchDaysRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).updateBatchDays(batchDaysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public updateCalendarDay(updateCalendarDayRequest: UpdateCalendarDayRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).updateCalendarDay(updateCalendarDayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDayPresetRequest} updateDayPresetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public updateDayPreset(updateDayPresetRequest: UpdateDayPresetRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).updateDayPreset(updateDayPresetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWeekDayRequest} updateWeekDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPresetsApi
     */
    public updateWeekDay(updateWeekDayRequest: UpdateWeekDayRequest, options?: AxiosRequestConfig) {
        return DayPresetsApiFp(this.configuration).updateWeekDay(updateWeekDayRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
