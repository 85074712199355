import { pinwheelPalette } from "@/styles/theme";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, IconButtonProps, alpha } from "@mui/material";
import { forwardRef } from "react";
import { NotificationDot } from "../NotificationDot";

interface Props extends IconButtonProps {
  notificationCount: number;
}

export const NotificationButton = forwardRef<HTMLButtonElement, Props>(({ notificationCount, sx, ...rest }, ref) => {
  return (
    <IconButton
      ref={ref}
      {...rest}
      aria-label="notification button"
      aria-controls="notification-menu"
      aria-haspopup="true"
      sx={{
        ...sx,
        position: "relative",
      }}
    >
      <FontAwesomeIcon title="Notifications" icon={faBell} color={alpha(pinwheelPalette.black[100], 0.75)} />
      {notificationCount > 0 ? (
        <NotificationDot
          sx={{
            position: "absolute",
            border: `2px solid ${pinwheelPalette.white[100]}`,
            right: "0px",
            top: "5px",
            height: "16px",
            width: "16px",
            fontSize: notificationCount > 9 ? "10px!important" : "12px",
          }}
        >
          {notificationCount > 9 ? "9+" : notificationCount}
        </NotificationDot>
      ) : null}
    </IconButton>
  );
});

NotificationButton.displayName = "NotificationButton";
