import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { Contact, ContactsState } from "./contactsModel";

const initialState: ContactsState = {
  contacts: undefined,
};

export const contactsSlice = createAppSlice({
  name: "contacts",
  initialState,
  reducers: (create) => ({
    storeContacts: create.reducer((state, action: PayloadAction<Contact[]>) => {
      state.contacts = action.payload;
    }),
    createContact: create.reducer((state, action: PayloadAction<Omit<Contact, "groupIds">>) => {
      const contact = {
        ...action.payload,
        groupIds: [],
      };
      state.contacts = state.contacts ? [...state.contacts, contact] : [contact];
    }),
    updateContact: create.reducer((state, action: PayloadAction<Omit<Contact, "groupIds">>) => {
      const updatedContact = action.payload;
      state.contacts = state.contacts?.map((contact) => (contact.id === updatedContact.id ? { ...contact, ...updatedContact } : contact));
    }),
    deleteContact: create.reducer((state, action: PayloadAction<string>) => {
      state.contacts = state.contacts?.filter((contact) => contact.id !== action.payload);
    }),
    updateContactGroups: create.reducer((state, action: PayloadAction<string>) => {
      state.contacts = state.contacts?.filter((contact) => contact.id !== action.payload);
    }),
    clearStore: create.reducer((state) => {
      state.contacts = initialState.contacts;
    }),
  }),
  selectors: {
    selectContacts: (state) => state.contacts,
  },
});

export const { storeContacts, createContact, updateContact, deleteContact, updateContactGroups, clearStore } =
  contactsSlice.actions;

export const { selectContacts } = contactsSlice.selectors;
