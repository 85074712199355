import { useState } from "react";
import { Box, Button, SxProps, Tab, Theme } from "@mui/material";
import { NotificationCenterHeader } from "./notification-cernter-header";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { pinwheelPalette } from "@/styles/theme";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationDot } from "../NotificationDot";
import { AllNotificationPanel } from "./all-notification-panel";
import { ActionNotificationPanel } from "./action-notification-panel";
import { useNotificationContext } from "./notification-context";

interface Props {
  sx?: SxProps<Theme>;
  onClose: () => void;
}

export const NotificationCenter = ({ sx, onClose }: Props) => {
  const {
    allNotifications: { unreadNotifications },
    actionNotifications: { data: actionNotificationsData },
    markAllAsRead,
  } = useNotificationContext();
  const [activeTab, setActiveTab] = useState<"all" | "action">("all");

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={sx} width="100%" height="100%" p="16px 0">
      <NotificationCenterHeader onClose={onClose} />
      <Box id="notification-tab-container" width="100%" flex="1 1 0%" sx={{ overflowY: "auto" }} display="flex" flexDirection="column">
        <TabContext value={activeTab} key="notification-tab-context">
          <Box
            id="notification-tab"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px="16px"
            sx={{
              borderBottom: `1px solid ${pinwheelPalette.lightGrey[100]}`,
            }}
          >
            <TabList onChange={(_event, newValue) => setActiveTab(newValue)}>
              <Tab
                label={
                  <Box display="flex" flexDirection="row" gap="4px">
                    All
                    {unreadNotifications.length > 0 ? (
                      <NotificationDot
                        sx={{
                          fontSize: unreadNotifications.length > 9 ? "10px" : "12px",
                        }}
                      >
                        {unreadNotifications.length > 9 ? "9+" : unreadNotifications.length}
                      </NotificationDot>
                    ) : null}
                  </Box>
                }
                sx={{ p: 0, minWidth: 0, mr: "16px" }}
                value="all"
              />
              <Tab
                label={
                  <Box display="flex" flexDirection="row" gap="4px">
                    Actions
                    {actionNotificationsData.length > 0 ? (
                      <NotificationDot
                        sx={{
                          fontSize: actionNotificationsData.length > 9 ? "10px" : "12px",
                        }}
                      >
                        {actionNotificationsData.length > 9 ? "9+" : actionNotificationsData.length}
                      </NotificationDot>
                    ) : null}
                  </Box>
                }
                sx={{ p: 0, minWidth: 0 }}
                value="action"
              />
            </TabList>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                px: 0,
                color: pinwheelPalette.blue[50],
                ".MuiButton-startIcon": {
                  svg: {
                    fontSize: "14px",
                  },
                },
              }}
              disabled={unreadNotifications.length === 0}
              startIcon={<FontAwesomeIcon icon={faCheckDouble} />}
              onClick={markAllAsRead}
              disableRipple={true}
            >
              Mark all as read
            </Button>
          </Box>
          <Box id="notification-tab-panel" display="flex" width="100%" height="100%" flex="1 1 0%" sx={{ overflowY: "auto" }}>
            <TabPanel
              sx={{
                p: 0,
                width: "100%",
                height: "100%",
                flex: "1 1 0%",
                display: activeTab === "all" ? "flex" : "none",
              }}
              value="all"
              id="all-notification-panel"
            >
              <AllNotificationPanel />
            </TabPanel>
            <TabPanel
              sx={{
                p: 0,
                width: "100%",
                height: "100%",
                flex: "1 1 0%",
                display: activeTab === "action" ? "flex" : "none",
              }}
              value="action"
              id="action-notification-panel"
            >
              <ActionNotificationPanel />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};
