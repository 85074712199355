import * as Sentry from "@sentry/browser";
import firebaseInit from "./firebaseInit";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { getFCMService } from "./api-helper";
import { deviceType } from "./helper";
import { DeviceType, FcmTokenRequest } from "../openapi";

interface DeviceData {
  deviceId: string;
  fcmToken: string;
}

const getDeviceId = () => {
  return uuidv4();
};

const createFcmToken = async (deviceData: FcmTokenRequest) => {
  try {
    const fcmCrudService = await getFCMService();
    await fcmCrudService.create(deviceData);
  } catch (err) {
    Sentry.captureException({ deviceType: deviceData.deviceType, err });
  }
};

const deleteFcmToken = async (deviceId: string) => {
  try {
    const fcmCrudService = await getFCMService();
    await fcmCrudService._delete(deviceId);
  } catch (err) {
    Sentry.captureException({ device: deviceType, err });
  }
};

const saveWebToken = async (fcmToken: string, deviceId: string) => {
  if (fcmToken && deviceId) {
    localStorage.setItem(
      "FCMToken",
      JSON.stringify({
        deviceId: deviceId,
        fcmToken: fcmToken
      })
    );
    await createFcmToken({
      deviceType: deviceType,
      deviceId: deviceId,
      fcmToken
    });
  }
};

const firebaseApp = async () => {
  const firebaseApp = await firebaseInit();
  const messaging =
    firebaseApp && firebaseApp.messaging && firebaseApp.messaging.isSupported()
      ? firebaseApp.messaging()
      : null;
  return messaging;
};

let fcmApp: firebase.messaging.Messaging | null = null;

const getFcmToken = async () => {
  fcmApp = await firebaseApp();
  if (fcmApp) {
    try {
      return await fcmApp.getToken();
    } catch (err) {
      Sentry.captureException({ deviceType: deviceType, err });
    }
  } else {
    console.log("Your browser is not supporte notification");
    return undefined;
  }
};

const fcmLocalStore = (): DeviceData | null => {
  const fcmStore = localStorage.getItem("FCMToken");
  const deviceStore = fcmStore ? JSON.parse(fcmStore) : null;
  return {
    fcmToken: deviceStore && deviceStore.fcmToken,
    deviceId: deviceStore && deviceStore.deviceId,
  };
};

const setDeviceInformation = async (fcmToken: string, deviceId?: string) => {
  const fcmStore = fcmLocalStore();
  if (fcmStore?.fcmToken !== fcmToken || (deviceId && fcmStore?.deviceId !== deviceId)) {
    if (fcmStore !== null){
      await deleteLocalDeviceInfo(fcmStore);
    }
    saveWebToken(fcmToken, deviceId ?? getDeviceId());
  }
}

const enableNotifications = async () => {
  if (deviceType === DeviceType.Browser) {
    if ("Notification" in window) {
      const notificationPermission = await Notification.requestPermission();
      if (notificationPermission === "granted") {
        const createdFcmToken = await getFcmToken();
        if (createdFcmToken !== undefined) {
          setDeviceInformation(createdFcmToken);
        }
      } else {
        console.log("browser notification permission is block", notificationPermission);
      }
    }
  } else if (deviceType === DeviceType.Mobile) {
    // we want to fire the event to React Native so it knows we want to request notification permissions
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "enableNotifications" }));
  }
};

const deleteNotification = async () => {
  const fcmStore = fcmLocalStore();
  if (fcmStore && fcmStore.deviceId) {
    await deleteLocalDeviceInfo(fcmStore);
  }
};

const deleteLocalDeviceInfo = async (fcmStore: DeviceData) => {
  localStorage.removeItem("FCMToken");
  await deleteFcmToken(fcmStore.deviceId);
}

const rnSetAppData = async (object: string) => {
  const deviceInfo = JSON.parse(object);
  const fcmStore = fcmLocalStore();
  if (deviceInfo && fcmStore?.fcmToken !== deviceInfo.fcmToken && deviceInfo.deviceId) {
    setDeviceInformation(deviceInfo.fcmToken, deviceInfo.deviceId);
  }
};

const unregisterFcmToken = async () => {
  if (fcmApp) {
    fcmApp.deleteToken();
  }
};

export { deleteNotification, enableNotifications, rnSetAppData, unregisterFcmToken };
