import { PayloadAction } from "@reduxjs/toolkit";
import { BasicModeResponse } from "@/openapi";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { Mode, ModesState } from "../modesReducer/modesModel";

const initialState: ModesState = {
  modes: undefined,
};

function computeUpdatesToMode(
  modification: { modeId: string; addIds?: string[]; removeIds?: string[] },
  key: "appIds" | "contactGroupIds",
  modes?: Mode[],
): Mode[] | undefined {
  return modes?.map((mode) => {
    if (mode.id !== modification.modeId) {
      return mode;
    }
    const newItems = mode[key].filter((id: string) => !modification.removeIds?.includes(id));
    if (modification.addIds) {
      newItems?.push(...modification.addIds);
    }
    return { ...mode, [key]: newItems };
  });
}

export const modesSlice = createAppSlice({
  name: "modes",
  initialState,
  reducers: (create) => ({
    storeModes: create.reducer((state, action: PayloadAction<Mode[]>) => {
      state.modes = action.payload;
    }),
    createMode: create.reducer((state, action: PayloadAction<Mode>) => {
      state.modes = state.modes ? [...state.modes, action.payload] : [action.payload];
    }),
    updateMode: create.reducer((state, action: PayloadAction<BasicModeResponse>) => {
      const updatedMode = action.payload;
      state.modes = state.modes?.map((mode) => (mode.id === updatedMode.id ? { ...mode, ...updatedMode } : mode));
    }),
    deleteMode: create.reducer((state, action: PayloadAction<string>) => {
      state.modes = state.modes?.filter((mode) => mode.id !== action.payload);
    }),
    updateModeApps: create.reducer((state, action: PayloadAction<{ modeId: string; addIds?: string[]; removeIds?: string[] }>) => {
      state.modes = computeUpdatesToMode(action.payload, "appIds", state.modes);
    }),
    updateModeContactGroups: create.reducer((state, action: PayloadAction<{ modeId: string; addIds?: string[]; removeIds?: string[] }>) => {
      state.modes = computeUpdatesToMode(action.payload, "contactGroupIds", state.modes);
    }),
    clearStore: create.reducer((state) => {
      state.modes = initialState.modes;
    }),
  }),
  selectors: {
    selectModes: (state) => state.modes,
  },
});

export const { storeModes, createMode, updateMode, deleteMode, updateModeApps, updateModeContactGroups, clearStore } = modesSlice.actions;

export const { selectModes } = modesSlice.selectors;
