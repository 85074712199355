import { pinwheelPalette } from "@/styles/theme";
import { Typography, alpha } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";

interface Props {
  timestamp: Date;
}

export const NotificationEventTimestamp = ({ timestamp }: Props) => {
  const humanizeTimestamp = useMemo(() => {
    // Today at 10:00 AM
    // Yesterday at 10:00 AM
    // Oct 20, 2023 at 10:00 AM
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const date = moment(timestamp);
    if (date.isSame(today, "d")) {
      return `Today at ${date.format("h:mm A")}`;
    } else if (date.isSame(yesterday, "d")) {
      return `Yesterday at ${date.format("h:mm A")}`;
    } else {
      return `${date.format("MMM D, YYYY")} at ${date.format("h:mm A")}`;
    }
  }, [timestamp]);

  return (
    <Typography variant="caption" sx={{ color: alpha(pinwheelPalette.black[100], 0.55), width: "100%", textAlign: "left" }}>
      {humanizeTimestamp}
    </Typography>
  );
};
