import { AlertColor } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import CustomizedSnackbars from "@/components/snackbar";

export interface SnackbarInterface {
  success: (text: string, timeout?: number) => void;
  error: (text: string, timeout?: number) => void;
  close: () => void;
}

const contextValues: SnackbarInterface = {
  success: () => {},
  error: () => {},
  close: () => {},
};

export const SnackbarContext = React.createContext(contextValues);

interface SnackbarConfiguraton {
  text: string;
  color: AlertColor;
  timeout: number;
}

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [config, setConfig] = useState<SnackbarConfiguraton | undefined>(undefined);

  const success = useCallback((text: string, timeout = 3000) => {
    setConfig({
      text: text,
      color: "success",
      timeout: timeout,
    });
  }, []);

  const error = useCallback((text: string, timeout = 3000) => {
    setConfig({
      text: text,
      color: "error",
      timeout: timeout,
    });
  }, []);

  const close = useCallback(() => {
    setConfig(undefined);
  }, []);

  const value = useMemo(
    () => ({
      success,
      error,
      close,
    }),
    [close, error, success],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {props.children}
      {config !== undefined ? (
        <CustomizedSnackbars text={config.text} color={config.color} timeout={config.timeout} handleClose={close} />
      ) : null}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
