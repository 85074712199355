import { useEffect } from "react";
import upvoty from "../../util/upvoty";

export interface UpvotyOptions {
  ssoToken?: string;
  baseUrl: string;
  boardHash?: string;
  startPage?: string
}

interface Props {
  options: UpvotyOptions
}

const UpvotyWidget = ({ options }: Props) => {
  useEffect(() => {
    upvoty.init("render", options);
    return () => {
      upvoty.destroy();
    };
  }, []);
  return <div data-upvoty className="upvoty-widget" />;
};

export default UpvotyWidget;
