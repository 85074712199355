import { Card, CardContent, Typography, CardActions, Button } from "@mui/material";

const Error = () => {
  return (
    <Card sx={{ 
      border: "none", 
      boxShadow: "none", 
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: {
        xs: "calc(100vh - 170px)"
      }
      }}>
      <CardContent>
        <Typography gutterBottom variant="h3">
          Page is not found
        </Typography>
      </CardContent>
      <CardActions>
        <Button href="#/dashboard" sx={{
          "&:hover": {
            color: "#ffffff"
          }
        }}>
          Back to Dashboard
        </Button>
      </CardActions>
    </Card>
    )
}

export default Error
