import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { CalendarDayState, CalendarDay, WeekDay } from "./calendarDaysModel";

const initialState: CalendarDayState = {
  calendarDays: undefined,
  weekDays: undefined,
};

export const calendarDaysSlice = createAppSlice({
  name: "calendarDays",
  initialState,
  reducers: (create) => ({
    storeCalendarDays: create.reducer((state, action: PayloadAction<CalendarDay[]>) => {
      state.calendarDays = action.payload;
    }),
    createCalendarDay: create.reducer((state, action: PayloadAction<CalendarDay>) => {
      state.calendarDays = state.calendarDays ? [...state.calendarDays, action.payload] : [action.payload];
    }),
    updateCalendarDay: create.reducer((state, action: PayloadAction<CalendarDay>) => {
      const updatedCalendarDay = action.payload;
      state.calendarDays = state.calendarDays?.map((calendarDay) =>
        calendarDay.id === updatedCalendarDay.id ? { ...calendarDay, ...updatedCalendarDay } : calendarDay,
      );
    }),
    deleteCalendarDay: create.reducer((state, action: PayloadAction<string>) => {
      state.calendarDays = state.calendarDays?.filter((calendarDay) => calendarDay.id !== action.payload);
    }),
    storeWeekDays: create.reducer((state, action: PayloadAction<WeekDay[]>) => {
      state.weekDays = action.payload;
    }),
    updateWeekDay: create.reducer((state, action: PayloadAction<WeekDay>) => {
      const updatedWeekDay = action.payload;
      state.weekDays = state.weekDays?.map((weekDay) => (weekDay.id === updatedWeekDay.id ? { ...weekDay, ...updatedWeekDay } : weekDay));
    }),
    reassignDayPresetId: create.reducer((state, action: PayloadAction<{ oldDayPresetId: string; newDayPresetId: string }>) => {
      const { oldDayPresetId, newDayPresetId } = action.payload;
      state.calendarDays = state.calendarDays?.map((calendarDay) => {
        if (calendarDay.dayPresetId === oldDayPresetId) {
          calendarDay.dayPresetId = newDayPresetId;
        }
        return calendarDay;
      });
      state.weekDays = state.weekDays?.map((weekDay) => {
        if (weekDay.dayPresetId === oldDayPresetId) {
          weekDay.dayPresetId = newDayPresetId;
        }
        return weekDay;
      });
    }),
    clearStore: create.reducer((state) => {
      state.calendarDays = initialState.calendarDays;
      state.weekDays = initialState.weekDays;
    }),
  }),
  selectors: {
    selectCalendarDays: (state) => state.calendarDays,
    selectWeekDays: (state) => state.weekDays,
  },
});

export const {
  storeCalendarDays,
  createCalendarDay,
  updateCalendarDay,
  deleteCalendarDay,
  storeWeekDays,
  updateWeekDay,
  reassignDayPresetId,
  clearStore,
} = calendarDaysSlice.actions;

export const { selectCalendarDays, selectWeekDays } = calendarDaysSlice.selectors;
