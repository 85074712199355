import { handleApiError } from "../util/error-handlers";
import { getChildrenService, getCaregiversService } from "./api-helper";

import { UpdateChildConfigRequest, UpdateDeviceInfoRequest, UpdateChildInfoRequest, CreateChildRequest } from "../openapi";

const getChildren = async () => {
  try {
    const caregiverServices = await getCaregiversService();
    const childrens = (await caregiverServices.getChildren()).data;
    return childrens;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const deleteChild = async (child: { id: string }) => {
  try {
    const childrenServices = await getChildrenService();
    const childrens = (await childrenServices.deleteChild(child.id)).data;
    return childrens;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const updateChildConfig = async (childId: string, data: UpdateChildConfigRequest) => {
  try {
    const childrenServices = await getChildrenService();
    const children = (await childrenServices.updateChildConfig(
      childId,
      data
    )).data;
    return children;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const updateDeviceInfo = async (childId: string, data: UpdateDeviceInfoRequest) => {
  try {
    const childrenServices = await getChildrenService();
    const children = (await childrenServices.updateDeviceInfo(
      childId,
      data
    )).data;
    return children;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const updateChildInfo = async (childId: string, data: UpdateChildInfoRequest) => {
  try {
    const childrenServices = await getChildrenService();
    const children = (await childrenServices.updateChildInfo(
      childId,
      data
    )).data;
    return children;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const createChildren = async (data: CreateChildRequest) => {
  try {
    const caregiverServices = await getCaregiversService();
    const children = (await caregiverServices.createChild(data)).data;
    return children;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const getCaregiver = async () => {
  try {
    const caregiverServices = await getCaregiversService();
    const caregiver = (await caregiverServices.getCaregiver()).data;
    return caregiver;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

const signOutChildFromAllDevices = async (childId: string) => {
  try {
    const childrenServices = await getChildrenService();
    const response = (await childrenServices.signOutGlobally(childId)).data;
    return response;
  } catch (error) {
    handleApiError(error);
    return undefined;
  }
};

export {
  deleteChild,
  updateChildConfig,
  updateDeviceInfo,
  updateChildInfo,
  getChildren,
  createChildren,
  getCaregiver,
  signOutChildFromAllDevices
};
