/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * CaregiversPublicEndpointsApi - axios parameter creator
 * @export
 */
export const CaregiversPublicEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutCaregiverGlobally: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('signOutCaregiverGlobally', 'email', email)
            const localVarPath = `/main/public/caregivers/globalSignOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaregiversPublicEndpointsApi - functional programming interface
 * @export
 */
export const CaregiversPublicEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaregiversPublicEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signOutCaregiverGlobally(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signOutCaregiverGlobally(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaregiversPublicEndpointsApi - factory interface
 * @export
 */
export const CaregiversPublicEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaregiversPublicEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutCaregiverGlobally(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.signOutCaregiverGlobally(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaregiversPublicEndpointsApi - object-oriented interface
 * @export
 * @class CaregiversPublicEndpointsApi
 * @extends {BaseAPI}
 */
export class CaregiversPublicEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversPublicEndpointsApi
     */
    public signOutCaregiverGlobally(email: string, options?: AxiosRequestConfig) {
        return CaregiversPublicEndpointsApiFp(this.configuration).signOutCaregiverGlobally(email, options).then((request) => request(this.axios, this.basePath));
    }
}
