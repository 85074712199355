import { fetchAuthSession } from "aws-amplify/auth";
import {Configuration} from "../openapi";

export default class ApiConfiguration extends Configuration{

  constructor() {
    super();
    this.basePath = (import.meta.env.VITE_REST_API_BASE_URL) ? import.meta.env.VITE_REST_API_BASE_URL : undefined;
    this.apiKey = async () => {
      try {
        const authSession = await fetchAuthSession();
        const accessToken = authSession.tokens?.accessToken.toString();
        return accessToken ?? "";
      } catch (e) {
        // Special case when string is returned (for example from cognito sometimes returns string for error)
        if (typeof e=== "string") {
          const errorObj = new Error(e);
          if (e === "No current user") {
            (errorObj as Error & {noAuth: boolean}).noAuth = true;
          }
          throw errorObj;
        }

        const error = e as Error;
        const { message } = error;
        error.message = `Auth error happened in serviceConfig.apiKey ${message}`;
        throw error;
      }
    }
  }

}
