import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { Group, GroupsState } from "./groupsModel";

const initialState: GroupsState = {
  groups: undefined,
};

export const groupsSlice = createAppSlice({
  name: "groups",
  initialState,
  reducers: (create) => ({
    storeGroups: create.reducer((state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    }),
    createGroup: create.reducer((state, action: PayloadAction<Group>) => {
      state.groups = state.groups ? [...state.groups, action.payload] : [action.payload];
    }),
    updateGroup: create.reducer((state, action: PayloadAction<Group>) => {
      const updatedGroup = action.payload;
      state.groups = state.groups?.map((group) => (group.id === updatedGroup.id ? { ...group, ...updatedGroup } : group));
    }),
    deleteGroup: create.reducer((state, action: PayloadAction<string>) => {
      state.groups = state.groups?.filter((group) => group.id !== action.payload);
    }),
    clearStore: create.reducer((state) => {
      state.groups = initialState.groups;
    }),
  }),
  selectors: {
    selectGroups: (state) => state.groups,
  },
});

export const { storeGroups, createGroup, updateGroup, deleteGroup, clearStore } = groupsSlice.actions;

export const { selectGroups } = groupsSlice.selectors;
