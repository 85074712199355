import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { AppTimeLimit } from "@/util/api-helper";
import { AppUsageState, AppTime, AppTimeLimitWeekData, TimeLimitResponse } from "./appsUsageModel";

const initialState: AppUsageState = {
  appsUsage: undefined,
};

const mapAppsTime = (items: TimeLimitResponse) => {
  const mapData = new Map<string, AppTimeLimitWeekData[]>();
  const data: AppTime[] = [];
  const entries = Object.entries(items);
  
  entries.map(day => {
    day[1].map((item: AppTimeLimit) => {
      const newDataObject: AppTimeLimitWeekData = {
        day: day[0].toLocaleLowerCase() as AppTimeLimitWeekData["day"],
        time: item.minutes,
      };
      const weekDaysTimes = mapData.get(item.packageName) || [];
      weekDaysTimes.push(newDataObject);
      mapData.set(item.packageName, weekDaysTimes);
    });
  });
  
  Array.from(mapData).map(item => {
    data.push({
      packageName: item[0],
      minutes: item[1],
    })
  });
  
  return mapData;
};

export const appsUsageSlice = createAppSlice({
  name: "appsUsage",
  initialState,
  reducers: (create) => ({
    storeAppUsages: create.reducer((state, action: PayloadAction<TimeLimitResponse>) => {
      state.appsUsage = mapAppsTime(action.payload);
    }),
    updateAppUsage: create.reducer((state, action: PayloadAction<AppTime>) => {
      if (action.payload && action.payload.packageName && state.appsUsage) {
        state.appsUsage.set(action.payload.packageName, action.payload.minutes!);
      }
    }),
    deleteAppUsage: create.reducer((state, action: PayloadAction<string>) => {
      if (action.payload && state.appsUsage?.get(action.payload)) {
        state.appsUsage.delete(action.payload);
      }
    }),
    clearStore: create.reducer((state) => {
      state.appsUsage = initialState.appsUsage;
    }),
  }),
  selectors: {
    selectAppsUsage: (state) => state.appsUsage,
  },
});

export const { storeAppUsages, updateAppUsage, deleteAppUsage, clearStore } = appsUsageSlice.actions;

export const { selectAppsUsage } = appsUsageSlice.selectors;
