import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { NotificationBody, NotificationStatus, NotificationTitle, StyledButton } from "./common";
import { ContactNotificationLogDto, ContactStatus, NotificationObjectCompletedAction, SafeListMode, UpdateContactRequest } from "@/openapi";
import { useAlert } from "@/hooks/useAlert";
import { useContactUpdate } from "@/util/contact-helper";
import { useNotificationContext } from "../notification-context";
import { usePageNavigation } from "@/util/navigation-helper";

interface Props {
  notificationId: string;
  completedAction?: NotificationObjectCompletedAction;
  contactNotification: ContactNotificationLogDto;
  isSameChild?: boolean;
}

export const ContactRequest = ({ notificationId, completedAction, contactNotification, isSameChild = false }: Props) => {
  const {
    updateNotificationCompletedAction,
    toggleNotificationPanel,
    actionNotifications: { removeNotification },
    markAsRead,
  } = useNotificationContext();
  const pageNavigate = usePageNavigation();
  const alert = useAlert();
  const { isLoading, updateContact } = useContactUpdate();
  const isCaregiverManaged = useMemo(
    () => contactNotification.notificationObjectMetadata.props.safeListMode === SafeListMode.Approved,
    [contactNotification.notificationObjectMetadata.props.safeListMode],
  );

  const handleApprove = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      try {
        const contact: UpdateContactRequest = {
          childrenId: contactNotification.childrenId,
          id: contactNotification.notificationObjectMetadata.props.contactId,
          name: contactNotification.notificationObjectMetadata.props.contactName,
          phoneNumber: contactNotification.notificationObjectMetadata.props.contactNumber,
          status: ContactStatus.Approved,
          isEmergency: contactNotification.notificationObjectMetadata.props.contactIsEmergency,
          note: contactNotification.notificationObjectMetadata.props.contactNote,
        };
        await updateContact(contact);
        removeNotification(notificationId);
        updateNotificationCompletedAction(notificationId, NotificationObjectCompletedAction.Approved);
        markAsRead(notificationId);
        alert.success("Contact is successfully approved.");
        toggleNotificationPanel();
        pageNavigate(`/contacts/Approved/${contactNotification.notificationObjectMetadata.props.contactId}`);
      } catch (error) {
        alert.error("Failed to approve the contact. Please try again later.");
      }
    },
    [
      alert,
      contactNotification,
      markAsRead,
      notificationId,
      pageNavigate,
      removeNotification,
      toggleNotificationPanel,
      updateContact,
      updateNotificationCompletedAction,
    ],
  );

  const handleReject = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      try {
        const contact: UpdateContactRequest = {
          childrenId: contactNotification.childrenId,
          id: contactNotification.notificationObjectMetadata.props.contactId,
          name: contactNotification.notificationObjectMetadata.props.contactName,
          phoneNumber: contactNotification.notificationObjectMetadata.props.contactNumber,
          status: ContactStatus.Rejected,
          isEmergency: contactNotification.notificationObjectMetadata.props.contactIsEmergency,
          note: contactNotification.notificationObjectMetadata.props.contactNote,
        };
        await updateContact(contact);
        removeNotification(notificationId);
        updateNotificationCompletedAction(notificationId, NotificationObjectCompletedAction.Rejected);
        markAsRead(notificationId);
        alert.success("Contact is successfully rejected.");
        toggleNotificationPanel();
        pageNavigate(`/contacts/Rejected/${contactNotification.notificationObjectMetadata.props.contactId}`);
      } catch (error) {
        alert.error("Failed to reject the contact. Please try again later.");
      }
    },
    [
      contactNotification,
      updateContact,
      removeNotification,
      notificationId,
      updateNotificationCompletedAction,
      markAsRead,
      alert,
      toggleNotificationPanel,
      pageNavigate,
    ],
  );

  const renderAction = useMemo(() => {
    if (contactNotification.notificationObjectMetadata.props.safeListMode !== SafeListMode.Approved) {
      return null;
    }
    if (
      completedAction &&
      (completedAction === NotificationObjectCompletedAction.Approved || completedAction === NotificationObjectCompletedAction.Rejected)
    ) {
      return completedAction === NotificationObjectCompletedAction.Approved ? (
        <NotificationStatus status="success" text="Contact approved" />
      ) : (
        <NotificationStatus status="error" text="Contact rejected" />
      );
    }
    if (!isSameChild || !isCaregiverManaged) {
      return null;
    }
    return (
      <Box sx={{ display: "flex", columnGap: 2, justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
        <StyledButton variant="outlined" color="error" size="small" onClick={handleReject} disabled={isLoading}>
          Reject
        </StyledButton>
        <StyledButton variant="contained" size="small" onClick={handleApprove} disabled={isLoading}>
          Approve
        </StyledButton>
      </Box>
    );
  }, [
    completedAction,
    contactNotification.notificationObjectMetadata.props.safeListMode,
    handleApprove,
    handleReject,
    isCaregiverManaged,
    isLoading,
    isSameChild,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        rowGap: 2,
      }}
    >
      <NotificationTitle>
        <b>{contactNotification.notificationObjectMetadata.props.childName}</b> added a new contact named{" "}
        <b>{contactNotification.notificationObjectMetadata.props.contactName}</b>
      </NotificationTitle>
      {contactNotification.notificationObjectMetadata.props.contactNote ? (
        <NotificationBody>{contactNotification.notificationObjectMetadata.props.contactNote}</NotificationBody>
      ) : null}
      {renderAction}
    </Box>
  );
};
