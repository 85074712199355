import { confirmSignIn, fetchAuthSession, signIn, signOut } from "aws-amplify/auth";
import { handleApiError } from "./error-handlers";

export const setIsBiometricSupported = (supported: boolean) => {
  window.localStorage.setItem("biometric.isBiometricSupported", supported ? "true" : "false");
};

export const setHasUserEnabledBiometric = (enabled: boolean) => {
  window.localStorage.setItem("biometric.hasUserEnabledBiometric", enabled ? "true" : "false");
};

export const setNativeAppDeviceId = (deviceId: string) => {
  window.localStorage.setItem("biometric.nativeAppDeviceId", deviceId);
};

export const biometricLoginFailed = () => {
  signOut().then(() => {
    window.location.href = `${window.location.origin}/#/`;
  });
};

export const biometricLogin = (username: string, deviceId: string, deviceToken: string) => {
  (async () => {
    try {
      const session = await fetchAuthSession();
      if (session.tokens?.accessToken && session.tokens?.idToken) {
        await signOut();
      }
      await signIn({
        username,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      });
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: JSON.stringify({
          DEVICE_TOKEN: deviceToken,
          DEVICE_ID: deviceId,
        }),
      });

      if (isSignedIn) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "biometricLoginSuccess",
          }),
        );
        const pathname = window.location.href.replace(window.location.origin, "");
        if (pathname === "/#/welcome") {
          window.location.href = `${window.location.origin}/#/`;
        }
      } else {
        const error = new Error("Biometric login challenge failed: " + username);
        await handleApiError(error);
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "biometricLoginFailed" }));
      }
    } catch (err) {
      const error = new Error("Biometric login challenge failed: " + (err as Error).message);
      handleApiError(error);
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "biometricLoginFailed" }));
    } finally {
      window.document.dispatchEvent(new CustomEvent("biometricLoginEnd"));
    }
  })();
};

export const biometricLoginCancel = () => {
  signOut().then(() => {
    window.location.href = "/#/login";
  });
};
