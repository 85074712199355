import { Dialog, DialogContent } from "@mui/material";
import { NotificationCenter } from "./notification-center";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const NotificationCenterMobileDialog = ({ open, onClose }: Props) => {
  return (
    <Dialog fullScreen={true} open={open} onClose={onClose}>
      <DialogContent
        sx={{
          p: "0px",
          height: "100%",
        }}
      >
        <NotificationCenter onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};
