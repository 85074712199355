import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { amplifyConfiguration } from "./aws-exports";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";

Amplify.configure(amplifyConfiguration);

Sentry.init({
  dsn: "https://e12ef69a995b427fbb510530107e99a7@o323155.ingest.sentry.io/5199507",
  // eslint-disable-next-line no-undef
  release: "pinwheel-caregiver-admin@" + import.meta.env.VITE_APP_VERSION,
  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  // integrations: [new TracingIntegrations.BrowserTracing()],
  ignoreErrors: ["Incorrect username or password."], // Don't report username or password error
  // To set a uniform sample rate
  tracesSampleRate: 0.2,

  // eslint-disable-next-line no-undef
  environment: import.meta.env.VITE_APP_ENV

  // To show modal window for a feedback in case of error
  /* beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  } */
});

const root = createRoot(document.getElementById("root")!);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
