import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { BlockTimeOption, DefaultDataConfigState, SelectedApps, SelectedBlockTime } from "./deviceConfigModel";

const initialState: DefaultDataConfigState = {
  blockTimeOption: {
    nightLimit: true,
  },
  selectedBlockTime: {
    wakeUpTime: "",
    sleepTime: "",
  },
  selectedApps: {
    apps: [],
  },
};

export const deviceConfigSlice = createAppSlice({
  name: "deviceConfig",
  initialState,
  reducers: (create) => ({
    storeBlockTimeOption: create.reducer((state, action: PayloadAction<BlockTimeOption>) => {
      state.blockTimeOption = action.payload;
    }),
    storeSelectedBlockTime: create.reducer((state, action: PayloadAction<SelectedBlockTime>) => {
      state.selectedBlockTime = action.payload;
    }),
    storeSelectedApps: create.reducer((state, action: PayloadAction<SelectedApps>) => {
      state.selectedApps = action.payload;
    }),
    clearStore: create.reducer((state) => {
      state.blockTimeOption = initialState.blockTimeOption;
      state.selectedBlockTime = initialState.selectedBlockTime;
      state.selectedApps = initialState.selectedApps;
    }),
  }),
  selectors: {
    selectBlockTimeOption: (state) => state.blockTimeOption,
    selectSelectedBlockTime: (state) => state.selectedBlockTime,
    selectSelectedApps: (state) => state.selectedApps,
  },
});

export const { storeBlockTimeOption, storeSelectedBlockTime, storeSelectedApps, clearStore } = deviceConfigSlice.actions;

export const { selectBlockTimeOption, selectSelectedBlockTime, selectSelectedApps } = deviceConfigSlice.selectors;
