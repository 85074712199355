import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { Routine, RoutinesState } from "../routinesReducer/routinesModel";

const initialState: RoutinesState = {
  routines: undefined,
};

export const routinesSlice = createAppSlice({
  name: "routines",
  initialState,
  reducers: (create) => ({
    storeRoutines: create.reducer((state, action: PayloadAction<Routine[]>) => {
      state.routines = action.payload;
    }),
    createRoutine: create.reducer((state, action: PayloadAction<Routine>) => {
      const routine = action.payload;
      state.routines = state.routines ? [...state.routines, routine] : [routine];
    }),
    updateRoutine: create.reducer((state, action: PayloadAction<Routine>) => {
      const updatedRoutine = action.payload;
      state.routines = state.routines?.map((routine) => (routine.id === updatedRoutine.id ? { ...routine, ...updatedRoutine } : routine));
    }),
    deleteRoutine: create.reducer((state, action: PayloadAction<string>) => {
      state.routines = state.routines?.filter((routine) => routine.id !== action.payload);
    }),
    clearStore: create.reducer((state) => {
      state.routines = initialState.routines;
    }),
  }),
  selectors: {
    selectRoutines: (state) => state.routines,
  },
});

export const { storeRoutines, createRoutine, updateRoutine, deleteRoutine, clearStore } = routinesSlice.actions;

export const { selectRoutines } = routinesSlice.selectors;
