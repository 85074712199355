export enum ErrorCodes {
  SupportedCountryRequired = 601,
  ValidTaxableAddressRequired = 602,
  ValidPaymentMethodRequired = 603,
  EmailClaimFailedToDeliverCode = 701,
  EmailClaimTooManyAttemptsToSendCode = 702,
  EmailClaimTooManyAttemptsToVerifyCode = 703,
  EmailClaimInvalidCode = 704,
  EmailClaimInvalidEmail = 705,
  LoginCodeExpired = 901,
}
