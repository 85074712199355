import { Box, Button, Typography } from "@mui/material";
import { use100vh } from "react-div-100vh";
import { useOnlineStatus } from "../hooks/use-online-status";

export const ErrorInfo = () => {
  const isOnline = useOnlineStatus();

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: `${use100vh()}px` }}>
      <Box
        sx={{
          width: "500px",
          height: "auto",
          padding: "20px",
          textAlign: "center"
        }}
      >
        <Typography variant="h3">{isOnline ? "We are having technical issues." : "You appear to be offline."}</Typography>
        <Typography variant="h5">
          {isOnline ? "Please click the button below to attempt to resolve them." : "When you’re back online, please click Reload."}
        </Typography>

        <Button
          sx={{
            margin: 1
          }}
          variant="outlined"
          onClick={() => {
            location.reload();
          }}
        >
          Reload
        </Button>

        <Typography variant="h5">If this doesn’t work, please restart the app</Typography>
      </Box>
    </Box>
  );
};

export default ErrorInfo;
