/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.34.11
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppUpdateRequestDto } from '../models';
// @ts-ignore
import { AppsResponse } from '../models';
// @ts-ignore
import { BooleanResponse } from '../models';
// @ts-ignore
import { ControlPlayStoreRequest } from '../models';
// @ts-ignore
import { CreateAppTimeLimitRequestDto } from '../models';
// @ts-ignore
import { CreateAppTimeLimitResponseDto } from '../models';
// @ts-ignore
import { PlayStoreAppApproveRequest } from '../models';
// @ts-ignore
import { PlayStoreAppRejectRequest } from '../models';
// @ts-ignore
import { PlayStoreAppSubmissionCancelRequest } from '../models';
// @ts-ignore
import { PlayStoreAppSubmissionResponse } from '../models';
// @ts-ignore
import { PlayStoreAppSubmitRequest } from '../models';
// @ts-ignore
import { PlayStoreAppSubmitResponse } from '../models';
// @ts-ignore
import { PlayStoreAppUninstallRequest } from '../models';
// @ts-ignore
import { RemoveAppTimeLimitRequestDto } from '../models';
// @ts-ignore
import { RemoveAppTimeLimitResponseDto } from '../models';
/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlayStoreAppSubmission: async (playStoreAppApproveRequest: PlayStoreAppApproveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppApproveRequest' is not null or undefined
            assertParamExists('approvePlayStoreAppSubmission', 'playStoreAppApproveRequest', playStoreAppApproveRequest)
            const localVarPath = `/main/apps/approvePlayStoreAppSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppApproveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPlayStoreAppSubmission: async (playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppSubmissionCancelRequest' is not null or undefined
            assertParamExists('cancelPlayStoreAppSubmission', 'playStoreAppSubmissionCancelRequest', playStoreAppSubmissionCancelRequest)
            const localVarPath = `/main/apps/cancelPlayStoreAppSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppSubmissionCancelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlPlayStore: async (controlPlayStoreRequest: ControlPlayStoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlPlayStoreRequest' is not null or undefined
            assertParamExists('controlPlayStore', 'controlPlayStoreRequest', controlPlayStoreRequest)
            const localVarPath = `/main/apps/controlPlayStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlPlayStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeInfoApps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps: async (includeInfoApps?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (includeInfoApps !== undefined) {
                localVarQueryParameter['includeInfoApps'] = includeInfoApps;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsByIds: async (appIds: string, includeCategories?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appIds' is not null or undefined
            assertParamExists('getAppsByIds', 'appIds', appIds)
            const localVarPath = `/main/apps/appsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (appIds !== undefined) {
                localVarQueryParameter['appIds'] = appIds;
            }

            if (includeCategories !== undefined) {
                localVarQueryParameter['includeCategories'] = includeCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayStoreAppSubmissions: async (childId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/apps/playStoreAppSubmissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPlayStoreAppSubmission: async (playStoreAppRejectRequest: PlayStoreAppRejectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppRejectRequest' is not null or undefined
            assertParamExists('rejectPlayStoreAppSubmission', 'playStoreAppRejectRequest', playStoreAppRejectRequest)
            const localVarPath = `/main/apps/rejectPlayStoreAppSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppRejectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannedApps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/apps/bannedApps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppTimeLimit: async (removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeAppTimeLimitRequestDto' is not null or undefined
            assertParamExists('removeAppTimeLimit', 'removeAppTimeLimitRequestDto', removeAppTimeLimitRequestDto)
            const localVarPath = `/main/apps/removeWeeklyTimeLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeAppTimeLimitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppUpdateRequestDto} appUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAppUpdateRequest: async (appUpdateRequestDto: AppUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appUpdateRequestDto' is not null or undefined
            assertParamExists('sendAppUpdateRequest', 'appUpdateRequestDto', appUpdateRequestDto)
            const localVarPath = `/main/apps/appUpdateRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAppTimeLimit: async (createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppTimeLimitRequestDto' is not null or undefined
            assertParamExists('setAppTimeLimit', 'createAppTimeLimitRequestDto', createAppTimeLimitRequestDto)
            const localVarPath = `/main/apps/weeklyTimeLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppTimeLimitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppSubmitRequest} playStoreAppSubmitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPlayStoreApp: async (playStoreAppSubmitRequest: PlayStoreAppSubmitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppSubmitRequest' is not null or undefined
            assertParamExists('submitPlayStoreApp', 'playStoreAppSubmitRequest', playStoreAppSubmitRequest)
            const localVarPath = `/main/apps/submitPlayStoreApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppSubmitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppUninstallRequest} playStoreAppUninstallRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstallPlayStoreApp: async (playStoreAppUninstallRequest: PlayStoreAppUninstallRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppUninstallRequest' is not null or undefined
            assertParamExists('uninstallPlayStoreApp', 'playStoreAppUninstallRequest', playStoreAppUninstallRequest)
            const localVarPath = `/main/apps/uninstallPlayStoreApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppUninstallRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePlayStoreAppSubmission(playStoreAppApproveRequest: PlayStoreAppApproveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePlayStoreAppSubmission(playStoreAppApproveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlPlayStore(controlPlayStoreRequest: ControlPlayStoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlPlayStore(controlPlayStoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeInfoApps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApps(includeInfoApps?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApps(includeInfoApps, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppsByIds(appIds: string, includeCategories?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppsByIds(appIds, includeCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayStoreAppSubmissions(childId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayStoreAppSubmissionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayStoreAppSubmissions(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectPlayStoreAppSubmission(playStoreAppRejectRequest: PlayStoreAppRejectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectPlayStoreAppSubmission(playStoreAppRejectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBannedApps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBannedApps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAppTimeLimit(removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveAppTimeLimitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAppTimeLimit(removeAppTimeLimitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppUpdateRequestDto} appUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAppUpdateRequest(appUpdateRequestDto: AppUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAppUpdateRequest(appUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAppTimeLimit(createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAppTimeLimitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAppTimeLimit(createAppTimeLimitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppSubmitRequest} playStoreAppSubmitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPlayStoreApp(playStoreAppSubmitRequest: PlayStoreAppSubmitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayStoreAppSubmitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPlayStoreApp(playStoreAppSubmitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppUninstallRequest} playStoreAppUninstallRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uninstallPlayStoreApp(playStoreAppUninstallRequest: PlayStoreAppUninstallRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uninstallPlayStoreApp(playStoreAppUninstallRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppsApiFp(configuration)
    return {
        /**
         * 
         * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlayStoreAppSubmission(playStoreAppApproveRequest: PlayStoreAppApproveRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.approvePlayStoreAppSubmission(playStoreAppApproveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlPlayStore(controlPlayStoreRequest: ControlPlayStoreRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.controlPlayStore(controlPlayStoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeInfoApps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps(includeInfoApps?: boolean, options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getApps(includeInfoApps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsByIds(appIds: string, includeCategories?: boolean, options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getAppsByIds(appIds, includeCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayStoreAppSubmissions(childId?: string, options?: any): AxiosPromise<Array<PlayStoreAppSubmissionResponse>> {
            return localVarFp.getPlayStoreAppSubmissions(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPlayStoreAppSubmission(playStoreAppRejectRequest: PlayStoreAppRejectRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.rejectPlayStoreAppSubmission(playStoreAppRejectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannedApps(options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getBannedApps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppTimeLimit(removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options?: any): AxiosPromise<RemoveAppTimeLimitResponseDto> {
            return localVarFp.removeAppTimeLimit(removeAppTimeLimitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppUpdateRequestDto} appUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAppUpdateRequest(appUpdateRequestDto: AppUpdateRequestDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.sendAppUpdateRequest(appUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAppTimeLimit(createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options?: any): AxiosPromise<CreateAppTimeLimitResponseDto> {
            return localVarFp.setAppTimeLimit(createAppTimeLimitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppSubmitRequest} playStoreAppSubmitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPlayStoreApp(playStoreAppSubmitRequest: PlayStoreAppSubmitRequest, options?: any): AxiosPromise<PlayStoreAppSubmitResponse> {
            return localVarFp.submitPlayStoreApp(playStoreAppSubmitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppUninstallRequest} playStoreAppUninstallRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstallPlayStoreApp(playStoreAppUninstallRequest: PlayStoreAppUninstallRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.uninstallPlayStoreApp(playStoreAppUninstallRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     * 
     * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public approvePlayStoreAppSubmission(playStoreAppApproveRequest: PlayStoreAppApproveRequest, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).approvePlayStoreAppSubmission(playStoreAppApproveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public controlPlayStore(controlPlayStoreRequest: ControlPlayStoreRequest, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).controlPlayStore(controlPlayStoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeInfoApps] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getApps(includeInfoApps?: boolean, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getApps(includeInfoApps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appIds 
     * @param {boolean} [includeCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAppsByIds(appIds: string, includeCategories?: boolean, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAppsByIds(appIds, includeCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getPlayStoreAppSubmissions(childId?: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getPlayStoreAppSubmissions(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public rejectPlayStoreAppSubmission(playStoreAppRejectRequest: PlayStoreAppRejectRequest, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).rejectPlayStoreAppSubmission(playStoreAppRejectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getBannedApps(options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getBannedApps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public removeAppTimeLimit(removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).removeAppTimeLimit(removeAppTimeLimitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppUpdateRequestDto} appUpdateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public sendAppUpdateRequest(appUpdateRequestDto: AppUpdateRequestDto, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).sendAppUpdateRequest(appUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public setAppTimeLimit(createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).setAppTimeLimit(createAppTimeLimitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppSubmitRequest} playStoreAppSubmitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public submitPlayStoreApp(playStoreAppSubmitRequest: PlayStoreAppSubmitRequest, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).submitPlayStoreApp(playStoreAppSubmitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppUninstallRequest} playStoreAppUninstallRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public uninstallPlayStoreApp(playStoreAppUninstallRequest: PlayStoreAppUninstallRequest, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).uninstallPlayStoreApp(playStoreAppUninstallRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
