import * as Sentry from "@sentry/browser";
import { Extras } from "@sentry/types";
import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";
import axios, { AxiosError } from "axios";

const handleApiError = async (error: Error | AxiosError | unknown) => {
  let errorToReport: Error | undefined;
  let errorInfo: Extras | undefined;
  
  if (axios.isAxiosError(error)) {
    const axiosError = (error as AxiosError)
    // ignore when we are disconnected
    if (axiosError.message === "Network Error") {
      return;
    }
    
    errorToReport = new Error(`${axiosError.response?.status}, ${error.response?.statusText}, ${error.response?.config.url}`);
    errorToReport.name = "API Error";
    errorInfo = {
      status: error.response?.status,
      statusText: error.response?.statusText,
      url: error.response?.config.url,
      body: error.response?.data
    };
  } else if (error instanceof Error) {
    if ("noAuth" in error) {
      window.location.href = `${import.meta.env.BASE_URL}#/welcome`;
      return;
    }
    errorToReport = error;
  }
  if (errorToReport) {
    Sentry.withScope(async scope => {
      try {
        const user = await getCurrentUser();
        if (!user) {
          throw new Error("User not found");
        }
        const userAttributes = await fetchUserAttributes();
        scope.setUser({
          email: userAttributes.email,
          id: userAttributes.sub,
          username: user.username,
        });
      } catch (err) {
        if (errorInfo){
          errorInfo.authError = `Auth.currentAuthenticatedUser failed ${err}`;
        }
      }
      if (errorInfo) {
        scope.setExtras(errorInfo);
      }
      Sentry.captureException(errorToReport);
    });
  }
};

export { handleApiError };
