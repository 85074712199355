import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { DayPreset, DayPresetsState } from "./dayPresetsModel";

const initialState: DayPresetsState = {
  dayPresets: undefined,
};

export const dayPresetsSlice = createAppSlice({
  name: "dayPresets",
  initialState,
  reducers: (create) => ({
    storeDayPresets: create.reducer((state, action: PayloadAction<DayPreset[]>) => {
      state.dayPresets = action.payload;
    }),
    createDayPreset: create.reducer((state, action: PayloadAction<DayPreset>) => {
      state.dayPresets = state.dayPresets ? [...state.dayPresets, action.payload] : [action.payload];
    }),
    updateDayPreset: create.reducer((state, action: PayloadAction<DayPreset>) => {
      const updatedDayPreset = action.payload;
      state.dayPresets = state.dayPresets?.map((dayPreset) =>
        dayPreset.id === updatedDayPreset.id ? { ...dayPreset, ...updatedDayPreset } : dayPreset,
      );
    }),
    deleteDayPreset: create.reducer((state, action: PayloadAction<string>) => {
      state.dayPresets = state.dayPresets?.filter((dayPreset) => dayPreset.id !== action.payload);
    }),
    reassignModeId: create.reducer((state, action: PayloadAction<{ oldModeId: string; newModeId: string }>) => {
      const { oldModeId, newModeId } = action.payload;
      state.dayPresets = state.dayPresets?.map((dayPreset) => {
        dayPreset.blocks.forEach((block) => {
          if (block.modeId == oldModeId) {
            block.modeId = newModeId;
          }
        });
        return dayPreset;
      });
    }),
    clearStore: create.reducer((state) => {
      state.dayPresets = initialState.dayPresets;
    }),
  }),
  selectors: {
    selectDayPresets: (state) => state.dayPresets,
  },
});

export const { storeDayPresets, createDayPreset, updateDayPreset, deleteDayPreset, reassignModeId, clearStore } = dayPresetsSlice.actions;

export const { selectDayPresets } = dayPresetsSlice.selectors;
