import React from "react";
import { GeofenceNotificationLogDto } from "@/openapi";
import { NotificationTitle } from "./common";

interface Props {
  notification: GeofenceNotificationLogDto;
}

export const Geofence: React.FC<Props> = ({ notification }) => {
  return (
    <NotificationTitle>
      <b>{notification.notificationObjectMetadata.props.childName}</b>{" "}
      {notification.notificationObjectMetadata.props.direction === "Enter" ? "arrived at" : "left"}{" "}
      <b>{notification.notificationObjectMetadata.props.savedLocationName}</b>
    </NotificationTitle>
  );
};
