import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { Box, Divider, SxProps, Theme } from "@mui/material";
import { pinwheelPalette } from "@/styles/theme";
import { ContactRequest } from "./notification-types/contact-request";
import { NotificationAvatar } from "./notification-avatar";
import { NotificationEventTimestamp } from "./notification-event-timestamp";
import { useMeasure } from "@/hooks/use-measure";
import { getNotificationObject, isNotificationUnread } from "./utils";
import { useNotificationContext } from "./notification-context";
import {
  NotificationLogResponseDto,
  NotificationObjectCompletedAction,
  NotificationObjectType,
} from "@/openapi";
import { AppRequest } from "./notification-types/app-request";
import { Geofence } from "./notification-types/geo-fence";
import ChildContext from "@/context/child";
import { usePageNavigation } from "@/util/navigation-helper";
import { PaymentFailed } from "./notification-types/payment-failed";

interface Props {
  notification: NotificationLogResponseDto;
  divider?: boolean;
  index: number;
  sx?: SxProps<Theme>;
  setSize: (index: number, size: number) => void;
  switchChild: (childId: string, notificationObjectType: NotificationObjectType, path?: string) => void;
}

export const NotificationListItem = ({ notification, setSize, index, sx, switchChild, divider }: Props) => {
  const { toggleNotificationPanel } = useNotificationContext();
  const pageNavigate = usePageNavigation();
  const { child } = useContext(ChildContext);
  const ref = useRef<HTMLDivElement>(null);
  const { height, width } = useMeasure(ref);
  const { markAsRead } = useNotificationContext();

  const isNotificationRead = useMemo(() => {
    return !isNotificationUnread(notification);
  }, [notification]);

  const notificationChildrenId = useMemo(() => {
    return getNotificationObject(notification)?.childrenId;
  }, [notification]);

  const isCurrentChild = useMemo(() => {
    return child?.id === notificationChildrenId;
  }, [child?.id, notificationChildrenId]);

  useEffect(() => {
    if (height && width) {
      setSize(index, height);
    }
  }, [setSize, index, height, width]);

  const navigatePath = useMemo(() => {
    switch (notification.notificationObjectType) {
      case NotificationObjectType.Contact: {
        if (notification.contact && notification.completedAction === NotificationObjectCompletedAction.Approved) {
          return `/contacts/Approved/${notification.contact?.notificationObjectMetadata.props.contactId}`;
        } else if (notification.contact && notification.completedAction === NotificationObjectCompletedAction.Rejected) {
          return `/contacts/Rejected/${notification.contact?.notificationObjectMetadata.props.contactId}`;
        } else {
          return `/contacts/Pending/${notification.contact?.notificationObjectMetadata.props.contactId}`;
        }
      }
      case NotificationObjectType.App: {
        if (notification.app && notification.completedAction === NotificationObjectCompletedAction.Approved) {
          return "/apps/installed";
        } else if (notification.app && notification.completedAction === NotificationObjectCompletedAction.Proposed) {
          return "/apps/pending";
        } else if (notification.app && notification.completedAction === NotificationObjectCompletedAction.Rejected) {
          return "/apps";
        } else {
          return "/apps/pending";
        }
      }
      case NotificationObjectType.Geofence:
        return "/dashboard";
      case NotificationObjectType.PaymentFailed:
        return "/settings/account";
      default:
        return "/";
    }
  }, [notification]);

  const handleNavigate = useCallback(() => {
    pageNavigate(navigatePath);
  }, [pageNavigate, navigatePath]);

  const renderNotificationType = useMemo(() => {
    switch (notification.notificationObjectType) {
      case NotificationObjectType.Contact:
        if (notification.contact) {
          return (
            <ContactRequest
              notificationId={notification.id}
              completedAction={notification.completedAction}
              contactNotification={notification.contact}
              isSameChild={isCurrentChild}
            />
          );
        }
        return null;
      case NotificationObjectType.App:
        if (notification.app) {
          return <AppRequest notification={notification.app} isSameChild={isCurrentChild} />;
        }
        return null;
      case NotificationObjectType.Geofence:
        if (notification.geofence) {
          return <Geofence notification={notification.geofence} />;
        }
        return null;
      case NotificationObjectType.PaymentFailed:
        if (notification.paymentFailed) {
          return <PaymentFailed notification={notification.paymentFailed} />;
        }
        return null;
      default:
        return null;
    }
  }, [isCurrentChild, notification]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isNotificationRead) {
        markAsRead(notification.id);
      }
      if (!isCurrentChild && notificationChildrenId) {
        e.preventDefault();
        e.stopPropagation();
        switchChild(notificationChildrenId, notification.notificationObjectType, navigatePath);
        return;
      }
      toggleNotificationPanel();
      handleNavigate();
    },
    [
      handleNavigate,
      isCurrentChild,
      isNotificationRead,
      markAsRead,
      navigatePath,
      notification.id,
      notification.notificationObjectType,
      notificationChildrenId,
      switchChild,
      toggleNotificationPanel,
    ],
  );

  return (
    <Box
      key={index}
      sx={{
        ...sx,
        "&:hover": {
          cursor: "default",
        },
      }}
      onClick={handleClick}
    >
      <Box
        ref={ref}
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          width="100%"
          height="calc(100% - 1px)"
          padding="1rem"
          display="flex"
          columnGap="1rem"
          justifyContent="flex-start"
          alignItems="flex-start"
          bgcolor={isNotificationRead ? pinwheelPalette.white[100] : pinwheelPalette.purple[5]}
        >
          <NotificationAvatar notificationObjectType={notification.notificationObjectType} geofenceNotification={notification.geofence} />
          <Box
            width="calc(100% - 32px)"
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="space-between"
            alignItems="flex-start"
            id="notification-content"
          >
            {renderNotificationType}
            <NotificationEventTimestamp timestamp={notification.eventTs} />
          </Box>
        </Box>
        {divider ? (
          <Divider
            sx={{
              width: "calc(100% - 32px)",
              margin: "0 auto",
              borderColor: pinwheelPalette.lightGrey[100],
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};
