import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ChildResponse } from "@/openapi";
import { useAppDispatch } from "@/redux/store/hooks";
import { clearStore as clearAppsStore } from "@/redux/reducers/appsReducer/appsReducer";
import { clearStore as clearCalendarDaysStore } from "@/redux/reducers/calendarDaysReducer/calendarDaysReducer";
import { clearStore as clearContactsStore } from "@/redux/reducers/contactsReducer/contactsReducer";
import { clearStore as clearDayPresetsStore } from "@/redux/reducers/dayPresetsReducer/dayPresetsReducer";
import { clearStore as clearGroupsStore } from "@/redux/reducers/groupsReducer/groupsReducer";
import { clearStore as clearModesStore } from "@/redux/reducers/modesReducer/modesReducer";
import { clearStore as clearRoutinesStore } from "@/redux/reducers/routinesReducer/routinesReducer";
import { clearStore as clearAppsUsageStore } from "@/redux/reducers/appsUsageReducer/appsUsageReducer";
import { ChildrenContext } from "./children";

export const SELECTED_CHILD_LS_KEY = "selectedChildID";

const getSelectedChild = (childrenUsers: ChildResponse[]): ChildResponse | null => {
  if (!childrenUsers?.length) {
    return null;
  }

  const selectedChildID = localStorage.getItem(SELECTED_CHILD_LS_KEY);
  if (!selectedChildID) {
    return childrenUsers[0];
  }

  const selectedChild = childrenUsers.find(child => child.id === selectedChildID);
  if (selectedChild) {
    return selectedChild;
  }

  localStorage.removeItem(SELECTED_CHILD_LS_KEY);
  return childrenUsers[0];
};

interface ChildContextProps {
  child: ChildResponse | null;
  setChild: (a: ChildResponse) => void;
  updateChild: (a: ChildResponse) => void;
}

const ChildContext = React.createContext<ChildContextProps>({
  child: null,
  setChild: () => {},
  updateChild: () => {},
});

export default ChildContext;

interface ChildProviderProps {
  children: React.ReactNode;
}

export const ChildProvider: React.FC<ChildProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [child, setChild] = React.useState<ChildResponse | null>(null);
  const { update, data: childrenUsers } = useContext(ChildrenContext);

  const setChildOverride = useCallback(
    (newChild: ChildResponse): void => {
      if (newChild.id !== child?.id) {
        dispatch(clearAppsStore());
        dispatch(clearCalendarDaysStore());
        dispatch(clearContactsStore());
        dispatch(clearDayPresetsStore());
        dispatch(clearGroupsStore());
        dispatch(clearModesStore());
        dispatch(clearRoutinesStore());
        dispatch(clearAppsUsageStore());
        setChild(newChild);
        if (newChild?.id) {
          localStorage.setItem(SELECTED_CHILD_LS_KEY, newChild.id);
        }
      }
    },
    [child?.id, dispatch],
  );

  const updateChild = useCallback((updatedChild: ChildResponse) => {
    setChild((prevChild) => {
      if (prevChild?.id === updatedChild.id) {
        return structuredClone({ ...prevChild, ...updatedChild });
      }
      return prevChild;
    });
    update(updatedChild);
  }, [update]);

  useEffect(() => {
    if (childrenUsers) {
      const selectedChild = getSelectedChild(childrenUsers);
      if (selectedChild) {
        setChildOverride(selectedChild);
      }
    }
  }, [childrenUsers, setChildOverride]);

  const value = useMemo(() => ({ child, setChild: setChildOverride, updateChild }), [child, setChildOverride, updateChild]);

  return <ChildContext.Provider value={value}>{children}</ChildContext.Provider>;
};

export const useChildContext = () => {
  const context = React.useContext(ChildContext);
  if (context === undefined) {
    throw new Error("useChild must be used within a ChildProvider");
  }
  return context;
};
