import { NotificationObjectType } from "@/openapi";
import { pinwheelPalette } from "@/styles/theme";
import { faMobileScreenButton, faHouseHeart, faGraduationCap, faMapLocation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, alpha } from "@mui/material";
import { useMemo } from "react";
import { GeofenceNotificationLogDto } from "@/openapi";
import UserPlusIcon from "@/assets/icons/user-plus-solid.svg?react";

interface Props {
  notificationObjectType?: NotificationObjectType;
  geofenceNotification?: GeofenceNotificationLogDto;
}

export const NotificationAvatar = ({ notificationObjectType, geofenceNotification }: Props) => {
  const avatarIcon = useMemo(() => {
    switch (notificationObjectType) {
      case NotificationObjectType.App:
        return faMobileScreenButton;
      case NotificationObjectType.Geofence: {
        if (!geofenceNotification) {
          return faMapLocation;
        }
        switch (geofenceNotification?.notificationObjectMetadata.props.savedLocationName) {
          case "Home":
            return faHouseHeart;
          case "School":
            return faGraduationCap;
          default:
            return faMapLocation;
        }
      }
      default:
        return null;
    }
  }, [geofenceNotification, notificationObjectType]);

  const avatarIconColor = useMemo(() => {
    switch (notificationObjectType) {
      case NotificationObjectType.Contact:
        return pinwheelPalette.blue[50];
      case NotificationObjectType.App:
        return "#01B5B1";
      case NotificationObjectType.Geofence: {
        if (!geofenceNotification) {
          return "#C42CF9";
        }
        switch (geofenceNotification?.notificationObjectMetadata.props.savedLocationName) {
          case "Home":
            return pinwheelPalette.blue[50];
          case "School":
            return "#FFC700";
          default:
            return "#C42CF9";
        }
      }
      default:
        return pinwheelPalette.blue[50];
    }
  }, [geofenceNotification, notificationObjectType]);

  const avatarBgColor = useMemo(() => {
    switch (notificationObjectType) {
      case NotificationObjectType.Contact:
        return alpha("#0096FF", 0.08);
      case NotificationObjectType.App:
        return "#E6F9F8";
      case NotificationObjectType.Geofence: {
        if (!geofenceNotification) {
          return pinwheelPalette.lightBlue[50];
        }
        switch (geofenceNotification?.notificationObjectMetadata.props.savedLocationName) {
          case "Home":
            return "#C9E5FD";
          case "School":
            return "#FFF8DF";
          default:
            return "#F3D5FE";
        }
      }
      default:
        return pinwheelPalette.lightBlue[50];
    }
  }, [geofenceNotification, notificationObjectType]);

  return (
    <Box width="32px" height="32px" borderRadius="50%" display="flex" justifyContent="center" alignItems="center">
      <Avatar sx={{ width: "32px", height: "32px", padding: "8px", background: avatarBgColor }}>
        {notificationObjectType === NotificationObjectType.Contact ? (
          <UserPlusIcon />
        ) : avatarIcon ? (
          <FontAwesomeIcon icon={avatarIcon} color={avatarIconColor} style={{ height: "16px", width: "16px" }} />
        ) : null}
      </Avatar>
    </Box>
  );
};
