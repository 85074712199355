import { typographyTheme } from "@/styles/customTheme";
import { ThemeProvider } from "@emotion/react";
import { Typography } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import ModalWindow from "../ModalWindow";
import { useNavigate } from "react-router-dom";
import { ChildrenContext } from "@/context/children";
import { useNotificationContext } from "./notification-context";
import ChildContext from "@/context/child";
import { NotificationObjectType } from "../../openapi";

interface Props {
  childId: string;
  notificationObjectType: NotificationObjectType;
  isOpen: boolean;
  onClose: () => void;
  path?: string;
}

export const ChildSwitchModal = ({ childId, isOpen, onClose, notificationObjectType, path }: Props) => {
  const { toggleNotificationPanel } = useNotificationContext();
  const navigate = useNavigate();
  const { data: chindren } = useContext(ChildrenContext);
  const { setChild } = useContext(ChildContext);

  const child = useMemo(() => chindren?.find((child) => child.id === childId), [chindren, childId]);

  const childName = useMemo(() => {
    if (!child) return null;
    return child?.firstName;
  }, [child]);

  const handleConfirm = useCallback(async () => {
    onClose();
    toggleNotificationPanel();
    if (child) {
      setChild(child);
    }
    if (path) {
      navigate(path, { replace: true });
    }
  }, [child, navigate, onClose, path, setChild, toggleNotificationPanel]);

  return (
    <ThemeProvider theme={typographyTheme}>
      <ModalWindow
        showModal={isOpen}
        setShowModal={() => onClose()}
        headerText={
          <Typography variant="h2" p={0} m="0 !important">
            Switch Child Account
          </Typography>
        }
        renderBody={() => {
          return (
            <Typography variant="body1">
              Managing this {notificationObjectType.toLowerCase()} requires switching to {childName}’s account.
            </Typography>
          );
        }}
        onActionButtonPress={handleConfirm}
        actionButtonText="Switch"
        onCancelButtonPress={() => onClose()}
      />
    </ThemeProvider>
  );
};
