import { combineSlices, configureStore } from "@reduxjs/toolkit";
import { appsSlice } from "../reducers/appsReducer/appsReducer";
import { appsUsageSlice } from "../reducers/appsUsageReducer/appsUsageReducer";
import { calendarDaysSlice } from "../reducers/calendarDaysReducer/calendarDaysReducer";
import { contactsSlice } from "../reducers/contactsReducer/contactsReducer";
import { dayPresetsSlice } from "../reducers/dayPresetsReducer/dayPresetsReducer";
import { deviceConfigSlice } from "../reducers/deviceConfigReducer/deviceConfigReducer";
import { groupsSlice } from "../reducers/groupsReducer/groupsReducer";
import { modesSlice } from "../reducers/modesReducer/modesReducer";
import { onBoardSlice } from "../reducers/onBoardReducer/onBoardReducer";
import { routinesSlice } from "../reducers/routinesReducer/routinesReducer";
import { FLUSH, PAUSE, PERSIST, PURGE, PersistConfig, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { enableMapSet } from "immer";

enableMapSet();

const rootReducer = combineSlices(
  appsSlice,
  appsUsageSlice,
  calendarDaysSlice,
  contactsSlice,
  dayPresetsSlice,
  deviceConfigSlice,
  groupsSlice,
  modesSlice,
  onBoardSlice,
  routinesSlice,
);

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["onBoard", "deviceConfig"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
};

export const store = makeStore();

export const persistor = persistStore(store);

export type AppStore = typeof store;

export type AppDispatch = typeof store.dispatch;