/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiometricTokenRequest } from '../models';
// @ts-ignore
import { BiometricTokenResponse } from '../models';
// @ts-ignore
import { ErrorResponseWithCode } from '../models';
/**
 * BiometricsApi - axios parameter creator
 * @export
 */
export const BiometricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BiometricTokenRequest} biometricTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiometricToken: async (biometricTokenRequest: BiometricTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biometricTokenRequest' is not null or undefined
            assertParamExists('createBiometricToken', 'biometricTokenRequest', biometricTokenRequest)
            const localVarPath = `/main/biometrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biometricTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBiometricToken: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteBiometricToken', 'deviceId', deviceId)
            const localVarPath = `/main/biometrics/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiometricsApi - functional programming interface
 * @export
 */
export const BiometricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiometricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BiometricTokenRequest} biometricTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBiometricToken(biometricTokenRequest: BiometricTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiometricTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBiometricToken(biometricTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBiometricToken(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBiometricToken(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiometricsApi - factory interface
 * @export
 */
export const BiometricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiometricsApiFp(configuration)
    return {
        /**
         * 
         * @param {BiometricTokenRequest} biometricTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiometricToken(biometricTokenRequest: BiometricTokenRequest, options?: any): AxiosPromise<BiometricTokenResponse> {
            return localVarFp.createBiometricToken(biometricTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBiometricToken(deviceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBiometricToken(deviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BiometricsApi - object-oriented interface
 * @export
 * @class BiometricsApi
 * @extends {BaseAPI}
 */
export class BiometricsApi extends BaseAPI {
    /**
     * 
     * @param {BiometricTokenRequest} biometricTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiometricsApi
     */
    public createBiometricToken(biometricTokenRequest: BiometricTokenRequest, options?: AxiosRequestConfig) {
        return BiometricsApiFp(this.configuration).createBiometricToken(biometricTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiometricsApi
     */
    public deleteBiometricToken(deviceId: string, options?: AxiosRequestConfig) {
        return BiometricsApiFp(this.configuration).deleteBiometricToken(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
}

