import { pinwheelPalette } from "@/styles/theme";
import { faGear } from "@fortawesome/pro-light-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography, Box, alpha } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  onClose: () => void;
}

export const NotificationCenterHeader = ({ onClose }: Props) => {
  const navigate = useNavigate();

  const handleSettingsClick = useCallback(() => {
    onClose();
    navigate("/settings/notifications", { replace: true });
  }, [navigate, onClose]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      px="16px"
      pl={{
        xs: "8px",
        md: "16px",
      }}
      alignItems="center"
      width="100%"
    >
      <Box display="flex" alignItems="center" gap="16px">
        <IconButton aria-label="notification" onClick={onClose} sx={{ display: { xs: "flex", lg: "none" } }}>
          <FontAwesomeIcon title="Back" icon={faAngleLeft} color={alpha(pinwheelPalette.black[100], 0.75)} />
        </IconButton>
        <Typography variant="h2" lineHeight="33px">
          Notifications
        </Typography>
      </Box>
      <IconButton aria-label="Settings" onClick={handleSettingsClick} size="medium" sx={{ px: 0 }} disableRipple>
        <FontAwesomeIcon icon={faGear} color={alpha(pinwheelPalette.black[100], 0.75)} />
      </IconButton>
    </Box>
  );
};
