import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import ChildContext from "../context/child";
import { DESKTOP_VERSION_FOR_GEOFENCING, DESKTOP_VERSION_FOR_GPS, compareDesktopVersion, deviceType } from "../util/helper";
import { useMe } from "@/util/me-context";
import { ChildSubscriptionType } from "@/openapi";
import { useChild } from "@/util/child-helper";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setIsMobile(window.innerWidth <= 1023);
    };

    handleWindowSizeChange();

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};

const useLogout = () => {
  const navigate = useNavigate();

  const logOut = useCallback(async () => {
    Sentry.getGlobalScope().setUser(null);
    await signOut();
    if (deviceType === "mobile") {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "logout" }));
    }
    navigate("/welcome", { replace: true });
  }, [navigate]);

  return logOut;
};

const useCountHeight = () => {
  const HEIGHT_OF_MOBILE_NOTIFICATION = 55.5; // heigh of notification and header title
  const HEIGHT_OF_HEADER = 51;
  const isMobile = useIsMobile();
  const { child } = useContext(ChildContext);
  const hasAnyDeviceToken = child?.deviceInfos.some(deviceInfo => !!deviceInfo.fcmDeviceToken);
  const hasAnyPhoneNumber = child?.deviceInfos.some(deviceInfo => !!deviceInfo.phoneNumber);

  const showVerifyBanner = () => {
    if (hasAnyDeviceToken && !child?.configuration.allowGroupMessaging) return true;
    return false;
  };

  const currentHeight = window.innerHeight;
  
  let height;

  if (currentHeight === null) {
    height =
      (showVerifyBanner() || (hasAnyDeviceToken && !hasAnyPhoneNumber)) && isMobile
        ? window.innerWidth - (HEIGHT_OF_MOBILE_NOTIFICATION + HEIGHT_OF_HEADER)
        : window.innerWidth - HEIGHT_OF_HEADER;
  } else {
    height =
      (showVerifyBanner() || (hasAnyDeviceToken && !hasAnyPhoneNumber)) && isMobile
        ? currentHeight - (HEIGHT_OF_MOBILE_NOTIFICATION + HEIGHT_OF_HEADER)
        : currentHeight - HEIGHT_OF_HEADER;
  }

  return height;
};

const useIsGPSBetaUser = () => {
  return true;
};

const useIsGpsCapable = () => {
  const { child } = useContext(ChildContext);
  return useMemo(() => {
    return (child?.deviceInfos?.length ?? 0) > 0 ? compareDesktopVersion(child!.deviceInfos![0].desktopVersion!, DESKTOP_VERSION_FOR_GPS) : false;
  }, [child]);
};

const useIsGeoFencingCapable = () => {
  const { child } = useContext(ChildContext);
  return useMemo(() => {
    if(import.meta.env.VITE_ENABLE_GEOFENCING === "false") return false;
    if (child && child.deviceInfos && child.deviceInfos.length > 0 && child.deviceInfos[0].desktopVersion) {
      return compareDesktopVersion(child.deviceInfos[0].desktopVersion, DESKTOP_VERSION_FOR_GEOFENCING);
    } else {
      return false;
    }
  }, [child]);
};

const useIsWatchChild = () => {
  const child = useChild();
  return useMemo(() => 
    child?.subscriptionTypes?.[0] === ChildSubscriptionType.Watch,
    [child]
  );
};

const useIsDeviceAuthenticationCapable = () => {
  const user = useMe();
  return useMemo(() => user.cognitoGroups.includes("deviceAuthentication"), [user]);
};

export {
  useIsMobile,
  useLogout,
  useCountHeight,
  useIsGpsCapable,
  useIsGPSBetaUser,
  useIsGeoFencingCapable,
  useIsDeviceAuthenticationCapable,
  useIsWatchChild,
};