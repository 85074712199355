import { NotificationLogResponseDto, NotificationObjectCompletedAction } from "@/openapi";
import { markNotificationAsRead } from "./utils";
import { useReducer } from "react";

interface NotificationReducerState {
  data: NotificationLogResponseDto[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  isLoading: boolean;
  error?: string;
}

type Action =
  | { type: "request" }
  | { type: "success"; payload: NotificationLogResponseDto[] }
  | { type: "failure"; payload: string }
  | { type: "nextPageRequest" }
  | { type: "nextPageSuccess"; payload: { data: NotificationLogResponseDto[]; hasNextPage: boolean } }
  | { type: "nextPageFailure"; payload: string }
  | { type: "markAsRead"; payload: string }
  | { type: "markAllAsRead" }
  | { type: "completeAction"; payload: { id: string; completedAction: NotificationObjectCompletedAction } }
  | { type: "remove"; payload: string };

export const notificationReducerInitialState: NotificationReducerState = {
  data: [],
  hasNextPage: true,
  isNextPageLoading: false,
  isLoading: false,
  error: undefined,
};

function notificationReducer(state: NotificationReducerState, action: Action): NotificationReducerState {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true };
    case "success":
      return { ...state, isLoading: false, data: action.payload };
    case "failure":
      return { ...state, isLoading: false, error: action.payload };
    case "nextPageRequest":
      return { ...state, isNextPageLoading: true };
    case "nextPageSuccess":
      return {
        ...state,
        isNextPageLoading: false,
        data: [...(state.data || []), ...action.payload.data],
        hasNextPage: action.payload.hasNextPage,
      };
    case "nextPageFailure":
      return { ...state, isNextPageLoading: false, error: action.payload };
    case "markAsRead": {
      const notificationIndex = state.data.findIndex((notification) => notification.id === action.payload);
      if (notificationIndex === -1) {
        return state;
      }
      const notification = structuredClone(state.data[notificationIndex]);
      const updatedNotification = markNotificationAsRead(notification);
      const updatedData = structuredClone(state.data);
      updatedData[notificationIndex] = updatedNotification;
      return { ...state, data: updatedData };
    }
    case "markAllAsRead":
      return { ...state, data: state.data.map(markNotificationAsRead) };
    case "completeAction": {
      const notificationIndex = state.data.findIndex((notification) => notification.id === action.payload.id);
      if (notificationIndex === -1) {
        return state;
      }
      const notification = structuredClone(state.data[notificationIndex]);
      const updatedNotification = {
        ...notification,
        completedAction: action.payload.completedAction,
      };
      if (updatedNotification.app) {
        updatedNotification.app.completedAction = action.payload.completedAction;
      } else if (updatedNotification.contact) {
        updatedNotification.contact.completedAction = action.payload.completedAction;
      } else if (updatedNotification.geofence) {
        updatedNotification.geofence.completedAction = action.payload.completedAction;
      } else if (updatedNotification.paymentFailed) {
        updatedNotification.paymentFailed.completedAction = action.payload.completedAction;
      }
      const updatedData = structuredClone(state.data);
      updatedData[notificationIndex] = updatedNotification;
      return { ...state, data: updatedData };
    }
    case "remove": {
      const updatedData = state.data.filter((notification) => notification.id !== action.payload);
      return { ...state, data: updatedData };
    }
    default:
      return state;
  }
}

export const useNotificationReducer = () => useReducer(notificationReducer, notificationReducerInitialState);
