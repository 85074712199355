/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.37.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BooleanResponse } from '../models';
// @ts-ignore
import { CreateGeolocationRequest } from '../models';
// @ts-ignore
import { CreateSavedLocationRequest } from '../models';
// @ts-ignore
import { GeolocationResponse } from '../models';
// @ts-ignore
import { LocationType } from '../models';
// @ts-ignore
import { SavedLocationResponse } from '../models';
// @ts-ignore
import { TriggerGeolocationRequest } from '../models';
// @ts-ignore
import { TriggerGeolocationResponse } from '../models';
// @ts-ignore
import { UpdateSavedLocationRequest } from '../models';
/**
 * GeolocationsApi - axios parameter creator
 * @export
 */
export const GeolocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateGeolocationRequest} createGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeoLocation: async (createGeolocationRequest: CreateGeolocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGeolocationRequest' is not null or undefined
            assertParamExists('createGeoLocation', 'createGeolocationRequest', createGeolocationRequest)
            const localVarPath = `/main/geolocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGeolocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateGeolocationRequest>} createGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeoLocations: async (createGeolocationRequest: Array<CreateGeolocationRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGeolocationRequest' is not null or undefined
            assertParamExists('createGeoLocations', 'createGeolocationRequest', createGeolocationRequest)
            const localVarPath = `/main/geolocations/batchInsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGeolocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSavedLocationRequest} createSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedLocation: async (createSavedLocationRequest: CreateSavedLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSavedLocationRequest' is not null or undefined
            assertParamExists('createSavedLocation', 'createSavedLocationRequest', createSavedLocationRequest)
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSavedLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedLocation: async (id: string, childrenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSavedLocation', 'id', id)
            // verify required parameter 'childrenId' is not null or undefined
            assertParamExists('deleteSavedLocation', 'childrenId', childrenId)
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {Array<LocationType>} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildLocations: async (childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildLocations', 'childId', childId)
            const localVarPath = `/main/geolocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedLocations: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerGeolocation: async (triggerGeolocationRequest: TriggerGeolocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerGeolocationRequest' is not null or undefined
            assertParamExists('triggerGeolocation', 'triggerGeolocationRequest', triggerGeolocationRequest)
            const localVarPath = `/main/geolocations/trigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(triggerGeolocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSavedLocation: async (updateSavedLocationRequest: UpdateSavedLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSavedLocationRequest' is not null or undefined
            assertParamExists('updateSavedLocation', 'updateSavedLocationRequest', updateSavedLocationRequest)
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSavedLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeolocationsApi - functional programming interface
 * @export
 */
export const GeolocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeolocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateGeolocationRequest} createGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeoLocation(createGeolocationRequest: CreateGeolocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeolocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeoLocation(createGeolocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateGeolocationRequest>} createGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeoLocations(createGeolocationRequest: Array<CreateGeolocationRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeolocationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeoLocations(createGeolocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSavedLocationRequest} createSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSavedLocation(createSavedLocationRequest: CreateSavedLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSavedLocation(createSavedLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedLocation(id: string, childrenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedLocation(id, childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {Array<LocationType>} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildLocations(childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeolocationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildLocations(childId, type, limit, offset, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedLocations(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedLocationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedLocations(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerGeolocation(triggerGeolocationRequest: TriggerGeolocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerGeolocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerGeolocation(triggerGeolocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSavedLocation(updateSavedLocationRequest: UpdateSavedLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSavedLocation(updateSavedLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeolocationsApi - factory interface
 * @export
 */
export const GeolocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeolocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateGeolocationRequest} createGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeoLocation(createGeolocationRequest: CreateGeolocationRequest, options?: any): AxiosPromise<GeolocationResponse> {
            return localVarFp.createGeoLocation(createGeolocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateGeolocationRequest>} createGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeoLocations(createGeolocationRequest: Array<CreateGeolocationRequest>, options?: any): AxiosPromise<Array<GeolocationResponse>> {
            return localVarFp.createGeoLocations(createGeolocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSavedLocationRequest} createSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedLocation(createSavedLocationRequest: CreateSavedLocationRequest, options?: any): AxiosPromise<SavedLocationResponse> {
            return localVarFp.createSavedLocation(createSavedLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedLocation(id: string, childrenId: string, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.deleteSavedLocation(id, childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {Array<LocationType>} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildLocations(childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options?: any): AxiosPromise<Array<GeolocationResponse>> {
            return localVarFp.getChildLocations(childId, type, limit, offset, since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedLocations(childrenId?: string, options?: any): AxiosPromise<Array<SavedLocationResponse>> {
            return localVarFp.getSavedLocations(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerGeolocation(triggerGeolocationRequest: TriggerGeolocationRequest, options?: any): AxiosPromise<TriggerGeolocationResponse> {
            return localVarFp.triggerGeolocation(triggerGeolocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSavedLocation(updateSavedLocationRequest: UpdateSavedLocationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateSavedLocation(updateSavedLocationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeolocationsApi - object-oriented interface
 * @export
 * @class GeolocationsApi
 * @extends {BaseAPI}
 */
export class GeolocationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateGeolocationRequest} createGeolocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public createGeoLocation(createGeolocationRequest: CreateGeolocationRequest, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).createGeoLocation(createGeolocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateGeolocationRequest>} createGeolocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public createGeoLocations(createGeolocationRequest: Array<CreateGeolocationRequest>, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).createGeoLocations(createGeolocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSavedLocationRequest} createSavedLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public createSavedLocation(createSavedLocationRequest: CreateSavedLocationRequest, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).createSavedLocation(createSavedLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} childrenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public deleteSavedLocation(id: string, childrenId: string, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).deleteSavedLocation(id, childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {Array<LocationType>} [type] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {Date} [since] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public getChildLocations(childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).getChildLocations(childId, type, limit, offset, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public getSavedLocations(childrenId?: string, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).getSavedLocations(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public triggerGeolocation(triggerGeolocationRequest: TriggerGeolocationRequest, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).triggerGeolocation(triggerGeolocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public updateSavedLocation(updateSavedLocationRequest: UpdateSavedLocationRequest, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).updateSavedLocation(updateSavedLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
