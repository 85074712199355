/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.41.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BooleanResponse } from '../models';
// @ts-ignore
import { NotificationInteraction } from '../models';
// @ts-ignore
import { NotificationLogResponseDto } from '../models';
// @ts-ignore
import { NotificationObjectCompletedAction } from '../models';
// @ts-ignore
import { NotificationObjectType } from '../models';
// @ts-ignore
import { NotificationSettingsResponseDto } from '../models';
// @ts-ignore
import { UpdateNotificationLogDto } from '../models';
// @ts-ignore
import { UpdateNotificationSettingsDto } from '../models';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
         * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
         * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
         * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
         * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
         * @param {string} [childrenId] 
         * @param {Date} [since] 
         * @param {string} [notificationObjectId] 
         * @param {boolean} [isActionCompleted] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (notificationObjectType) {
                localVarQueryParameter['notificationObjectType'] = notificationObjectType;
            }

            if (interaction) {
                localVarQueryParameter['interaction'] = interaction;
            }

            if (excludeInteraction) {
                localVarQueryParameter['excludeInteraction'] = excludeInteraction;
            }

            if (completedAction) {
                localVarQueryParameter['completedAction'] = completedAction;
            }

            if (excludeCompletedAction) {
                localVarQueryParameter['excludeCompletedAction'] = excludeCompletedAction;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (notificationObjectId !== undefined) {
                localVarQueryParameter['notificationObjectId'] = notificationObjectId;
            }

            if (isActionCompleted !== undefined) {
                localVarQueryParameter['isActionCompleted'] = isActionCompleted;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings: async (updateNotificationSettingsDto: UpdateNotificationSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationSettingsDto' is not null or undefined
            assertParamExists('updateNotificationSettings', 'updateNotificationSettingsDto', updateNotificationSettingsDto)
            const localVarPath = `/main/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications: async (updateNotificationLogDto: Array<UpdateNotificationLogDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationLogDto' is not null or undefined
            assertParamExists('updateNotifications', 'updateNotificationLogDto', updateNotificationLogDto)
            const localVarPath = `/main/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
         * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
         * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
         * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
         * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
         * @param {string} [childrenId] 
         * @param {Date} [since] 
         * @param {string} [notificationObjectId] 
         * @param {boolean} [isActionCompleted] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationLogResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(notificationObjectType, interaction, excludeInteraction, completedAction, excludeCompletedAction, childrenId, since, notificationObjectId, isActionCompleted, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationSettings(updateNotificationSettingsDto: UpdateNotificationSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationSettings(updateNotificationSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(updateNotificationLogDto: Array<UpdateNotificationLogDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifications(updateNotificationLogDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings(options?: any): AxiosPromise<NotificationSettingsResponseDto> {
            return localVarFp.getNotificationSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
         * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
         * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
         * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
         * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
         * @param {string} [childrenId] 
         * @param {Date} [since] 
         * @param {string} [notificationObjectId] 
         * @param {boolean} [isActionCompleted] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options?: any): AxiosPromise<Array<NotificationLogResponseDto>> {
            return localVarFp.getNotifications(notificationObjectType, interaction, excludeInteraction, completedAction, excludeCompletedAction, childrenId, since, notificationObjectId, isActionCompleted, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings(updateNotificationSettingsDto: UpdateNotificationSettingsDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateNotificationSettings(updateNotificationSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(updateNotificationLogDto: Array<UpdateNotificationLogDto>, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateNotifications(updateNotificationLogDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationSettings(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
     * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
     * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
     * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
     * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
     * @param {string} [childrenId] 
     * @param {Date} [since] 
     * @param {string} [notificationObjectId] 
     * @param {boolean} [isActionCompleted] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifications(notificationObjectType, interaction, excludeInteraction, completedAction, excludeCompletedAction, childrenId, since, notificationObjectId, isActionCompleted, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationSettings(updateNotificationSettingsDto: UpdateNotificationSettingsDto, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationSettings(updateNotificationSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotifications(updateNotificationLogDto: Array<UpdateNotificationLogDto>, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotifications(updateNotificationLogDto, options).then((request) => request(this.axios, this.basePath));
    }
}
