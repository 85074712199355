import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { ChildResponse } from "../openapi";
import { getChildren } from "../util/children-helper";
import ErrorInfo from "./errorInfo";
import { useMe } from "@/util/me-context";
import { useAlert } from "@/hooks/useAlert";

interface IChildrenContext {
  data?: ChildResponse[];
  refetch: () => void;
  update: (child: ChildResponse) => void;
  addChild: (child: ChildResponse) => void;
}

export const ChildrenContext = React.createContext<IChildrenContext>({
  data: undefined,
  refetch: () => {},
  update: () => {},
  addChild: () => {},
});

interface ChildrenProviderProps {
  children: React.ReactNode;
}

export const ChildrenProvider = (props: ChildrenProviderProps) => {
  const [children, setChildren] = useState<ChildResponse[] | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const me = useMe();
  const alert = useAlert();

  const update = useCallback(
    (child: ChildResponse) => {
      if (children) {
        const index = children.findIndex((c) => c.id === child.id);
        if (index >= 0) {
          const newChildren = structuredClone(children);
          newChildren[index] = child;
          setChildren(newChildren);
        }
      }
    },
    [children],
  );

  const refetch = useCallback(async () => {
    const allChildren = await getChildren();
    if (allChildren) {
      setChildren(allChildren);
    } else {
      setShowError(true);
      alert.error("Failed to reach child. Reload to try again.");
    }
  }, [alert]);

  const addChild = useCallback(
    (child: ChildResponse) => {
      if (children) {
        setChildren([...children, child]);
      } else {
        setChildren([child]);
      }
    },
    [children],
  );

  useEffect(() => {
    if (me) {
      refetch();
    }
  }, [me, refetch]);

  const value = useMemo(() => ({ data: children ? children : [], refetch, update, addChild }), [addChild, children, refetch, update]);

  return <ChildrenContext.Provider value={value}>{children ? props.children : showError ? <ErrorInfo /> : null}</ChildrenContext.Provider>;
};

export default ChildrenProvider;

export const useChildrenContext = () => {
  const context = useContext(ChildrenContext);
  if (!context) {
    throw new Error("useChildrenContext must be used within a ChildrenProvider");
  }
  return context;
};

export const useChildren = () => {
  const { data } = useChildrenContext();
  return data;
};
