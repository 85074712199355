import React from "react";
import { Snackbar, Stack } from "@mui/material";
import MuiAlert, { AlertColor } from "@mui/material/Alert";

export interface Props {
  text: string;
  color: AlertColor;
  timeout: number;
  handleClose: () => void;
}

const CustomizedSnackbars: React.FC<Props> = ({text, color, timeout, handleClose}) => {

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={true}
        autoHideDuration={timeout}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          severity={color}
          onClose={handleClose}
          sx={{
            width: "100%"
          }}
          variant="filled"
          className={`alert ${color}`}
        >
          {text}
        </MuiAlert>
      </Snackbar>
    </Stack>
  );
};

export default CustomizedSnackbars;
