/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRoutineRequest } from '../models';
// @ts-ignore
import { RoutineResponse } from '../models';
// @ts-ignore
import { RoutinesResponse } from '../models';
// @ts-ignore
import { UpdateRoutineRequest } from '../models';
/**
 * RoutinesApi - axios parameter creator
 * @export
 */
export const RoutinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoutineRequest} createRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoutine: async (createRoutineRequest: CreateRoutineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoutineRequest' is not null or undefined
            assertParamExists('createRoutine', 'createRoutineRequest', createRoutineRequest)
            const localVarPath = `/main/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoutineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoutine: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoutine', 'id', id)
            const localVarPath = `/main/routines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoutines: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getRoutines', 'childId', childId)
            const localVarPath = `/main/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRoutineRequest} updateRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoutine: async (updateRoutineRequest: UpdateRoutineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRoutineRequest' is not null or undefined
            assertParamExists('updateRoutine', 'updateRoutineRequest', updateRoutineRequest)
            const localVarPath = `/main/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoutineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutinesApi - functional programming interface
 * @export
 */
export const RoutinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutinesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoutineRequest} createRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoutine(createRoutineRequest: CreateRoutineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoutine(createRoutineRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoutine(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoutine(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoutines(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutinesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoutines(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateRoutineRequest} updateRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoutine(updateRoutineRequest: UpdateRoutineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoutine(updateRoutineRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoutinesApi - factory interface
 * @export
 */
export const RoutinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutinesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoutineRequest} createRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoutine(createRoutineRequest: CreateRoutineRequest, options?: any): AxiosPromise<RoutineResponse> {
            return localVarFp.createRoutine(createRoutineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoutine(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteRoutine(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoutines(childId: string, options?: any): AxiosPromise<RoutinesResponse> {
            return localVarFp.getRoutines(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRoutineRequest} updateRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoutine(updateRoutineRequest: UpdateRoutineRequest, options?: any): AxiosPromise<RoutineResponse> {
            return localVarFp.updateRoutine(updateRoutineRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutinesApi - object-oriented interface
 * @export
 * @class RoutinesApi
 * @extends {BaseAPI}
 */
export class RoutinesApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoutineRequest} createRoutineRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutinesApi
     */
    public createRoutine(createRoutineRequest: CreateRoutineRequest, options?: AxiosRequestConfig) {
        return RoutinesApiFp(this.configuration).createRoutine(createRoutineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutinesApi
     */
    public deleteRoutine(id: string, options?: AxiosRequestConfig) {
        return RoutinesApiFp(this.configuration).deleteRoutine(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutinesApi
     */
    public getRoutines(childId: string, options?: AxiosRequestConfig) {
        return RoutinesApiFp(this.configuration).getRoutines(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRoutineRequest} updateRoutineRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutinesApi
     */
    public updateRoutine(updateRoutineRequest: UpdateRoutineRequest, options?: AxiosRequestConfig) {
        return RoutinesApiFp(this.configuration).updateRoutine(updateRoutineRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
