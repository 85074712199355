import { Box, SxProps, Theme, alpha, keyframes, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUrl } from "aws-amplify/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-duotone-svg-icons";
import { pinwheelPalette } from "@/styles/theme";
import ProfileIcon from "@/assets/icons/profile.svg?react";

const sizeMap = {
  small: "34px",
  medium: "52px",
};

const fontSizeMap = {
  small: "0.75rem",
  medium: "1rem",
};

const iconSizeMap = {
  small: "16px",
  medium: "22px",
};

const StyledContactPhoto = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  overflow: "hidden",
  position: "relative",
}));

const StyledLoaderContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
  backgroundColor: "transparent",
  display: "flex",
}));

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled(FontAwesomeIcon)(() => ({
  color: pinwheelPalette.purple[100],
  animation: `${spin} 1s linear infinite`,
}));

const StyledNameBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Poppins",
  color: "black",
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",
  borderRadius: "50%",
  overflow: "hidden",
}));

const StyledImage = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

interface Props {
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
  photoUrl?: string | null;
  name?: string | null;
  icon?: React.ReactNode;
}

export const ContactPhoto: React.FC<Props> = ({ sx, photoUrl, name, icon, size = "medium" }) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!photoUrl);
  const [contactPhotoURL, setContactPhotoURL] = useState<string | null>(null);

  useEffect(() => {
    if (photoUrl) {
      setIsLoading(true);
      getUrl({ key: photoUrl })
        .then((response) => {
          setContactPhotoURL(response.url.toString());
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setContactPhotoURL(null);
    }
  }, [photoUrl]);

  const renderPhoto = () => {
    if (contactPhotoURL) {
      return <StyledImage style={{ backgroundImage: `url(${contactPhotoURL})` }} />;
    }
    if (name && name.length > 0) {
      return <StyledNameBox sx={{ fontSize: fontSizeMap[size] }}>{name[0].toUpperCase()}</StyledNameBox>;
    }
    return renderIcon();
  };

  const renderIcon = () => {
    if (icon) {
      return icon;
    }
    return <ProfileIcon width={iconSizeMap[size]} height={iconSizeMap[size]} />;
  };

  return (
    <StyledContactPhoto
      sx={{
        backgroundColor: alpha(pinwheelPalette.black[100], 0.05),
        width: sizeMap[size],
        height: sizeMap[size],
        minWidth: sizeMap[size],
        minHeight: sizeMap[size],
        ...sx,
      }}
    >
      {isLoading ? (
        <StyledLoaderContainer>
          <StyledLoader icon={faLoader} size="3x" />
        </StyledLoaderContainer>
      ) : contactPhotoURL || name ? (
        renderPhoto()
      ) : (
        renderIcon()
      )}
    </StyledContactPhoto>
  );
};
