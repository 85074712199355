import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { Stack } from "@mui/material";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  size?: SizeProp;
}

export default function Loader({ size = "2x" }: Props) {
  return (
    <Stack alignItems="center" sx={{ color: theme => theme.palette.primary.main, my: 2 }}>
      <FontAwesomeIcon icon={faCircleNotch} spin size={size} />
    </Stack>
  )
}
