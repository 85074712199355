import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { App, AppResponseLevelEnum, AppsState } from "./appsModel";

const initialState: AppsState = {
  categories: undefined,
  apps: undefined,
};

const normalizeApps = (apps: App[] | undefined) => {
  return apps?.map((app) => {
    // Information Only apps aren't a "real" app level, we are making one up
    // An app is Information Only if it doesn't have a package name
    // Core apps are special as they don't have package names, so we want to respect Core Apps without Package Names
    if (app.packageName === undefined && app.level !== AppResponseLevelEnum.CoreApps) {
      app.level = AppResponseLevelEnum.InformationOnly;
    }
    return app;
  });
};

export const appsSlice = createAppSlice({
  name: "apps",
  initialState,
  reducers: (create) => ({
    storeApps: create.reducer((state, action: PayloadAction<AppsState["apps"]>) => {
      state.apps = normalizeApps(action.payload);
    }),
    storeCategories: create.reducer((state, action: PayloadAction<AppsState["categories"]>) => {
      state.categories = action.payload;
    }),
    storeDescriptions: create.reducer((state, action: PayloadAction<AppsState["descriptions"]>) => {
      state.descriptions = action.payload;
    }),
    clearStore: create.reducer((state) => {
      state.apps = initialState.apps;
      state.categories = initialState.categories;
      state.descriptions = initialState.descriptions;
    }),
  }),
  selectors: {
    selectApps: (state) => state.apps,
    selectCategories: (state) => state.categories,
    selectDescriptions: (state) => state.descriptions,
  },
});

export const { storeApps, storeCategories, storeDescriptions, clearStore } = appsSlice.actions;

export const { selectApps, selectCategories, selectDescriptions } = appsSlice.selectors;
