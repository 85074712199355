import { useMeasure } from "@/hooks/use-measure";
import { useCallback, useRef, useState } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Box, SxProps, Theme } from "@mui/material";
import InfiniteLoader from "react-window-infinite-loader";
import { NotificationListItem } from "./notification-list-item";
import CircularLoader from "../CircularLoader";
import { mergeRefs } from "@/util/merge-refs";
import { NotificationLogResponseDto, NotificationObjectType } from "@/openapi";
import { ChildSwitchModal } from "./child-switch-modal";

interface Props {
  notifications: NotificationLogResponseDto[];
  sx?: SxProps<Theme>;
  loadNextPage: () => void;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  isLoading: boolean;
}

export const NotificationList = ({ sx, notifications, loadNextPage, hasNextPage, isNextPageLoading }: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { height } = useMeasure(elementRef);
  const listRef = useRef<VariableSizeList>(null);
  const sizeMap = useRef<{ [index: number]: number }>({});
  const [switchChild, setSwitchChild] = useState<{
    childId: string;
    notificationObjectType: NotificationObjectType;
    path?: string;
  } | null>(null);

  const handleSwitchChild = useCallback(
    (childId: string, notificationObjectType: NotificationObjectType, path?: string) => {
      setSwitchChild({ childId, notificationObjectType, path });
    },
    [],
  );

  const handleCloseChildSwitchModal = useCallback(() => {
    setSwitchChild(null);
  }, []);

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  }, []);

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < notifications.length;
    },
    [notifications.length, hasNextPage],
  );

  const loadMoreItems = useCallback(
    (startIndex: number) => {
      if (isNextPageLoading || startIndex < notifications.length) {
        return;
      }
      if (hasNextPage) {
        loadNextPage();
      }
    },
    [notifications.length, hasNextPage, isNextPageLoading, loadNextPage],
  );

  const getSize = useCallback(
    (index: number) => {
      const size = sizeMap.current[index];
      if (size) {
        return size;
      }
      if (index >= notifications.length) {
        return 102;
      }
      return 128;
    },
    [notifications],
  );

  return (
    <>
      <Box
        ref={elementRef}
        width="100%"
        height="100%"
        flex="1 1 0%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={sx}
      >
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={notifications.length + 1} loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              height={height}
              itemCount={hasNextPage ? notifications.length + 1 : notifications.length}
              itemSize={getSize}
              onItemsRendered={onItemsRendered}
              ref={mergeRefs([ref, listRef])}
              width="100%"
              itemData={hasNextPage ? [...notifications, { loading: true }] : notifications} // Add a loading item at the end if there is a next page to show a loading spinner
            >
              {/* Array of NotificationResponse or { loading: true } */}
              {({ index, style, data }: ListChildComponentProps<Array<NotificationLogResponseDto | { loading: true }>>) => {
                const obj = data[index];
                if ("loading" in obj) {
                  return (
                    <Box key={index} sx={{ ...style, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <CircularLoader />
                    </Box>
                  );
                }
                return (
                  <NotificationListItem
                    key={index}
                    sx={{ ...style, width: "100%" }}
                    notification={obj}
                    index={index}
                    setSize={setSize}
                    divider={index !== notifications.length - 1}
                    switchChild={handleSwitchChild}
                  />
                );
              }}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      </Box>
      {switchChild ? (
        <ChildSwitchModal
          isOpen={!!switchChild}
          childId={switchChild.childId}
          notificationObjectType={switchChild.notificationObjectType}
          path={switchChild.path}
          onClose={handleCloseChildSwitchModal}
        />
      ) : null}
    </>
  );
};
