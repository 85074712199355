import { Popover } from "@mui/material";
import { NotificationCenter } from "./notification-center";

interface Props {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const NotificationCenterDesktopPopover = ({ open, anchorEl, onClose, id }: Props) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        p: "0px",
        height: "600px",
      }}
    >
      <NotificationCenter
        onClose={onClose}
        sx={{
          width: "400px",
          height: "calc(600px - 32px)",
        }}
      />
    </Popover>
  );
};
