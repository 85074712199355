export interface AppsState {
  apps?: Array<App>;
  categories?: Array<Category>;
  descriptions?: Array<Description>;
}

export interface Category {
  id: string;
  name: string;
  dataCY?: string;
}

export interface Description {
  subtitle?: string;
  recommendation?: string;
  id: number;
  type: string;
  label: string;
  description: string;
  level: number;
}

export interface App {
  id: string;
  name: string;
  description: string;
  packageName?: string;
  madePublicOn?: Date;
  appCategoryIds: string[];
  appDescriptionIds?: number[];
  img?: string;
  level?: AppResponseLevelEnum; 
  shortDescription?: string;
  formattedDescription?: string;
  minimumAge?: number;
  monitoredByBark?: boolean;
  neverDisable?: boolean;
  playStoreURL?: string;
  developerSite?: string;
  downloads?:number;
  hideForNewUsers?: boolean;
}

export enum AppResponseLevelEnum {
  PinwheelApproved = "PinwheelApproved",
  SlightlyOutOfBounds = "SlightlyOutOfBounds",
  ViolatesGuidelines = "ViolatesGuidelines",
  UntestedByPinwheel = "UntestedByPinwheel",
  InformationOnly = "InformationOnly",
  CoreApps = "CoreApps"
}
