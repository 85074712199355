import "./auth-page.sass";
import "./auth-page-temporary.sass";
import { Outlet } from "react-router-dom";

export default function AuthPage() {
  return (
    <div className="auth-page">
      <Outlet />
    </div>
  );
}
