import React from "react";
import { Typography, Box } from "@mui/material";
import ScheduleFAQ from "@/img/schedule-faq-ss.jpg";
import ModalWindow from "../ModalWindow";
import { pinwheelPalette } from "@/styles/theme";

interface CancelModalProps {
  onActionButtonPress: () => Promise<void>;
  showModal: boolean;
  setShowModal: (close: boolean) => void;
}

const CancelModal: React.FC<CancelModalProps> = ({ onActionButtonPress, showModal, setShowModal }) => {
  return (
    <ModalWindow
      headerText={
        <Typography variant="h2" fontWeight="500" fontSize="22px" lineHeight="33px" color={pinwheelPalette.black[100]}>
          Dismiss Tutorial Video
        </Typography>
      }
      showModal={showModal}
      setShowModal={setShowModal}
      actionButtonText="Confirm"
      actionButtonType="primary"
      onActionButtonPress={onActionButtonPress}
      renderBody={() => (
        <Box>
          <Typography variant="body2" fontSize="16px" fontWeight={600} color={pinwheelPalette.black[100]} mb={3}>
            Are you sure you want to dismiss the Schedules Tutorial Video?
          </Typography>
          <Typography variant="body1" fontSize="16px" fontWeight={400} color={pinwheelPalette.black[100]} mb={2}>
            You can always view this later in our FAQ page.
          </Typography>
          <img src={ScheduleFAQ} alt="Schedule FAQ" />
        </Box>
      )}
      cancelButtonText="Close"
      sx={{
        maxWidth: "350px",
        margin: "auto",
        "&.modal-container": {
          ".MuiDialogContent-root": {
            padding: "16px",
          },
          ".MuiDialogActions-root": {
            flexDirection: "row-reverse",
            justifyContent: "right",
          },
        },
      }}
    />
  );
};

export default CancelModal;
