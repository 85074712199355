import React, { useCallback } from "react";
import { NotificationStatus, NotificationTitle, StyledButton } from "./common";
import { Box } from "@mui/material";
import { NotificationObjectCompletedAction, PaymentFailedNotificationLogDto } from "@/openapi";
import { usePageNavigation } from "@/util/navigation-helper";
import { useNotificationContext } from "../notification-context";

interface Props {
  notification: PaymentFailedNotificationLogDto;
}

export const PaymentFailed: React.FC<Props> = ({ notification }) => {
  const pageNavigate = usePageNavigation();
  const { toggleNotificationPanel } = useNotificationContext();

  const handleUpdatePayment = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      toggleNotificationPanel();
      pageNavigate("/settings/account");
    },
    [pageNavigate, toggleNotificationPanel],
  );

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", rowGap: 1 }}
    >
      <NotificationTitle>Your recent Pinwheel subscription payment has failed.</NotificationTitle>
      <Box sx={{ display: "flex", columnGap: 2, justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
        {notification.completedAction && notification.completedAction === NotificationObjectCompletedAction.PaymentMethodUpdated ? (
          <NotificationStatus status="success" text="Payment method updated" />
        ) : (
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleUpdatePayment}
            sx={{ width: "auto", height: "auto", padding: "0.5rem 1.5rem", fontSize: "0.875rem" }}
          >
            Update Payment
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};
