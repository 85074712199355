import * as React from "react";

export interface Measure {
  left: number;
  top: number;
  width: number;
  bottom: number;
  right: number;
  x: number;
  y: number;
  height: number;
  offsetLeft: number;
  offsetTop: number;
  offsetHeight: number;
  offsetWidth: number;
}

export const useMeasure = (ref: React.RefObject<HTMLElement>): Measure => {
  const frameID = React.useRef(0);
  const [measure, setMeasure] = React.useState<Measure>({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    x: 0,
    y: 0,
    offsetLeft: 0,
    offsetTop: 0,
    offsetHeight: 0,
    offsetWidth: 0,
  });

  const observer = React.useMemo(
    () =>
      typeof window !== "undefined"
        ? new window.ResizeObserver((entries) => {
            const entry = entries[0];

            if (entry) {
              cancelAnimationFrame(frameID.current);

              frameID.current = requestAnimationFrame(() => {
                const { bottom, height, left, right, top, width, x, y } = entry.contentRect;
                if (!ref.current) return;
                const { offsetLeft, offsetTop, offsetHeight, offsetWidth } = ref.current;
                setMeasure({
                  bottom,
                  height,
                  left,
                  right,
                  top,
                  width,
                  x,
                  y,
                  offsetLeft,
                  offsetTop,
                  offsetHeight,
                  offsetWidth,
                });
              });
            }
          })
        : null,
    [ref],
  );

  React.useEffect(() => {
    if (!observer || !ref.current) return;

    observer.observe(ref.current);

    return () => {
      observer.disconnect();

      if (frameID.current) {
        cancelAnimationFrame(frameID.current);
      }
    };
  }, [observer, ref]);

  return measure;
};
