import React from "react";
import { Box, Typography } from "@mui/material";
import ModalWindow from "../ModalWindow";
import { pinwheelPalette } from "@/styles/theme";

interface WelcomeScheduleModalProps {
  showModal: boolean;
  setShowModal: (close: boolean) => void;
  welcomeModalSteps: number;
  onActionButtonPress?:  () => Promise<void>;
}

const WelcomeScheduleModal: React.FC<WelcomeScheduleModalProps> = ({ showModal, setShowModal, welcomeModalSteps, onActionButtonPress }) => {
  
  const renderBody = () => (
    <Box>
      {welcomeModalSteps !== 1 ? (
        <>
          <Typography variant="body2" mb={3}>
            Want to learn how to schedule contacts and apps for school?
          </Typography>
          <Typography variant="body1">Watch this 67s video to get started!</Typography>
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingTop: "177.7778%",
            paddingBottom: 0,
            boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
            overflow: "hidden",
            borderRadius: "8px",
            willChange: "transform",
          }}
        >
          <iframe
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: "none",
              padding: 0,
              margin: 0,
            }}
            src="https://www.canva.com/design/DAGAuqfzvYM/LBNA1FqBeGmhxW9TM67dMA/watch?embed"
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </Box>
      )}
    </Box>
  );

  return (
    <ModalWindow
      headerText={
        <Typography variant="h2" fontWeight="500" fontSize="22px" lineHeight="33px" color={pinwheelPalette.black[100]}>
          Welcome to Pinwheel!
        </Typography>
      }
      showModal={showModal}
      setShowModal={setShowModal}
      renderBody={renderBody}
      actionButtonText="Yes"
      onActionButtonPress={welcomeModalSteps !== 1 ? onActionButtonPress : undefined}
      cancelButtonText={welcomeModalSteps !== 1 ? "Later" : "Close"}
      sx={{
        maxWidth: "350px",
        margin: "auto",
        "&.modal-container": {
          ".MuiDialogContent-root": {
            padding: "16px",
          },
          ".MuiDialogActions-root": {
            flexDirection: "row-reverse",
            justifyContent: "right",
          },
        },
      }}
    />
  );
};

export default WelcomeScheduleModal;
