import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import moment from "moment";
import "./birth-date-modal.sass";
import { updateChildInfo } from "../../util/children-helper";
import ModalWindow from "../ModalWindow";

import { TextField, Typography } from "@mui/material";

import { ChildResponse } from "../../openapi";
import { useAlert } from "@/hooks/useAlert";

interface MaskProps {
  component: React.ElementType;
  mask: Array<string | RegExp>;
  guide: boolean;
  name: string;
  required: boolean;
  placeholderChar: string;
  placeholder: string;
  [x: string]: unknown;
}

const TextMaskInput = React.forwardRef((props: MaskProps, ref: React.Ref<unknown>) => {
  const { component: Component, ...other } = props;
  const BIRTHDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  return (
    <Component
      ref={ref}
      {...other}
      mask={BIRTHDATE}
      guide={false}
      name="birthYearMonth"
      required
      placeholderChar={"\u2000"}
      placeholder="MM/YYYY"
    />
  );
});

TextMaskInput.displayName = "TextMaskInput";

interface ModalProps {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  childrenList: ChildResponse[]
}

interface IChild {
  childId: string;
  firstName: string;
  lastName: string;
  birthYearMonth: string;
}

interface IError {
  childId: string;
  errorText: string;
}

const ModalBirthDate = ({ showModal, childrenList, setShowModal }: ModalProps) => {
  const [childData, setChildData] = useState<IChild[]>([]);
  const [error, setError] = useState<IError[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const alert = useAlert();

  const findChild = (child: ChildResponse): IChild | undefined => childData.find(prevChild => prevChild.childId === child.id);

  const birthYearMonthValidation = (value: string): string | undefined => {
    if (value) {
      if (/^(0[1-9]|1[0-2])\/[0-9]{4}$/i.test(value.trim())) {
        const today = new Date();
        const CurrentDate = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        const yearMonthValue = value.split("/");
        const yearMonth = new Date(Number(yearMonthValue[1]), Number(yearMonthValue[0]), 0);

        if (Number(yearMonthValue[1]) < 1921) {
          return "Year must be greater then 1920";
        }
        return CurrentDate > moment(yearMonth) ? undefined : "Please enter valid date";
      } else {
        return "Please enter valid date";
      }
    }
  };

  const handleValidations = (value: string, child: ChildResponse) => {
    const errorText = value === "" ? "Required" : birthYearMonthValidation(value);
    if (errorText) {
      setError([
        ...error.filter(prev => prev.childId !== child.id),
        { childId: child.id, errorText }
      ]);
    } else {
      setError(error.filter(prev => prev.childId !== child.id));
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, child: ChildResponse) => {
    handleValidations(e.target.value, child);
    const data = {
      childId: child.id,
      firstName: child.firstName,
      lastName: child.lastName,
      birthYearMonth: e.target.value
    };
    setChildData(prev => [...prev.filter(prevChild => prevChild.childId !== child.id), data]);
  };

  const getError = (childId: string): IError | undefined => error.find(item => item.childId === childId);

  const saveBirthYearMonth = async (child: IChild) => {
    const yearMonthSplitted = child.birthYearMonth.split("/");
    return await updateChildInfo(child.childId, {
      ...child,
      birthYearMonth: `${yearMonthSplitted[1]}-${yearMonthSplitted[0]}`
    });
  };

  const isButtonDisabled = childData.length !== childrenList.length || error.length > 0;

  const handleSave = async (): Promise<void> => {
    setErrorMessage("");
    await Promise.all(
      childData.map(async child => {
        const updatedChildren = await saveBirthYearMonth(child);
        if (!updatedChildren) setErrorMessage("Error updating child's Birthday. Please try again");
      })
    );
    alert.success("Birth date updated.");
  };

  const getModalContent = () => (
    <div className="birth-year-month">
      <p>
        Provide your {childrenList.length === 1 ? "child's" : "children's"} birthday so that
        Pinwheel can provide you with age-specific information and features.
      </p>

      <div className="date-input-wrapper">
        {errorMessage ? 
          <Typography
            paragraph={true}
            sx={{ color: "error.main", px: 1, mb: 0 }}
          >
            {errorMessage}
          </Typography> : null
        }
        {childrenList.map((child) => (
          <div key={child.id} className="date-input-container">
            <label>{child.firstName}</label>
            <TextField
              value={findChild(child) ? findChild(child)?.birthYearMonth : ""}
              onChange={e => handleOnChange(e, child)}
              id="formatted-text-mask-input-2"
              InputProps={{
                inputComponent: TextMaskInput,
                inputProps: {
                  component: MaskedInput
                }
              }}
              margin="dense"
              size="small"
            />
            <Typography
              paragraph={true}
              sx={{ color: "error.main", pt: 2 }}
            >
              {getError(child.id)?.errorText}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <ModalWindow
      headerText="What is your child's birthday?"
      showModal={showModal}
      setShowModal={setShowModal}
      renderBody={getModalContent}
      onActionButtonPress={handleSave}
      actionButtonType="primary"
      actionButtonText="Done"
      confirmDisabled={isButtonDisabled}
    />
  );
};

export default ModalBirthDate;
