import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import React from "react";
import { pinwheelPalette } from "../styles/theme";

// extend the TypographyOptions interface to add our custom variants
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display1?: true;
    body3?: true;
    body4?: true;
    body5?: true;
    body6?: true;
  }
}

// create an interface for our custom variants
interface ExtendedTypographyOptions extends TypographyOptions {
  display1?: React.CSSProperties;
  body3?: React.CSSProperties;
  body4?: React.CSSProperties;
  body5?: React.CSSProperties;
  body6?: React.CSSProperties;
}

export const pinwheelTypography = {
  display1: {
    fontSize: "32px",
    fontWeight: 500,
  },
  h1: {
    fontSize: "26px",
    fontWeight: 500,
  },
  h2: {
    fontSize: "22px",
    fontWeight: 500,
  },
  h3: {
    fontSize: "18px",
    fontWeight: 500,
  },
  body1: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: pinwheelPalette.black[100],
  },
  body2: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  body3: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    color: pinwheelPalette.black[100],
    fontFamily: "Poppins",
  },
  body4: {
    fontSize: "16px",
    fontWeight: 400,
    color: pinwheelPalette.lightGrey[50],
    fontFamily: "Poppins",
  },
  body5: {
    fontSize: "14px",
    fontWeight: 400,
    color: pinwheelPalette.lightGrey[50],
    fontFamily: "Poppins",
  },
  body6: {
    fontSize: "12px",
    fontWeight: 400,
    color: pinwheelPalette.lightGrey[50],
    fontFamily: "Poppins",
  },
  caption: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    fontFamily: "Poppins",
  },
  button: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
};

export const typographyTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 769,
      lg: 1024,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: pinwheelPalette.purple[100],
    },
    secondary: {
      main: pinwheelPalette.yellow[100],
    },
    error: {
      main: pinwheelPalette.red[100],
    },
    info: {
      main: pinwheelPalette.blue[100],
    },
    success: {
      main: pinwheelPalette.green[100],
    },
    warning: {
      main: pinwheelPalette.orange[100],
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "capitalize",
      ...pinwheelTypography.button,
    },
    display1: {
      ...pinwheelTypography.display1,
    },
    h1: {
      ...pinwheelTypography.h1,
    },
    h2: {
      ...pinwheelTypography.h2,
    },
    h3: {
      ...pinwheelTypography.h3,
    },
    body1: {
      ...pinwheelTypography.body1,
    },
    body2: {
      ...pinwheelTypography.body2,
    },
    body3: {
      ...pinwheelTypography.body3,
    },
    body4: {
      ...pinwheelTypography.body4,
    },
    body5: {
      ...pinwheelTypography.body5,
    },
    body6: {
      ...pinwheelTypography.body6,
    },
    caption: {
      ...pinwheelTypography.caption,
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // boxShadow and border have a hardcoded value temporarily until design is finalized
          boxShadow: "none",
          border: "1px solid #e6e6e6",
          padding: "1rem",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // this is the backdrop that appears behind the modal
          // the "B3" is the 70% opacity added to the end of the color hex
          backgroundColor: pinwheelPalette.grey[50] + "B3",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontSize: "12px",
          fontWeight: 500,
          transform: "translate(14px, -9px) scale(1)",
        },
        root: {
          color: pinwheelPalette.lightGrey[50],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: 400,
        },
        input: {
          color: `${pinwheelPalette.black[100]} !important`,
          "&::placeholder": {
            color: pinwheelPalette.lightGrey[50],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          fontSize: "14px",
          fontWeight: 400,
          color: pinwheelPalette.black[100],
          "&.Mui-disabled": {
            color: pinwheelPalette.lightGrey[50],
          },
        },
      },
    },
  },
});
