import { PayloadAction, createSelector } from "@reduxjs/toolkit";
import { createAppSlice } from "@/redux/store/createAppSlice";
import { OnBoardState } from "./onBoardModel";

const initialState: OnBoardState = {
  caregiverData: {},
  childData: {},
};

export const onBoardSlice = createAppSlice({
  name: "onBoard",
  initialState,
  reducers: (create) => ({
    storeCaregiverData: create.reducer((state, action: PayloadAction<OnBoardState["caregiverData"]>) => {
      state.caregiverData = action.payload;
    }),
    storeChildData: create.reducer((state, action: PayloadAction<OnBoardState["childData"]>) => {
      state.childData = action.payload;
    }),
    clearStore: create.reducer((state) => {
      state.caregiverData = initialState.caregiverData;
      state.childData = initialState.childData;
    }),
  }),
  selectors: {
    selectCaregiverData: (state) => state.caregiverData,
    selectChildData: (state) => state.childData,
  },
});

export const { storeCaregiverData, storeChildData, clearStore } = onBoardSlice.actions;

export const { selectCaregiverData, selectChildData } = onBoardSlice.selectors;

export const selectOnBoardData = createSelector(selectCaregiverData, selectChildData, (caregiverData, childData) => ({
  caregiverData,
  childData,
}));
