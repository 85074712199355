import React from "react";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { pinwheelPalette } from "@/styles/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";

export const StyledButton = styled(LoadingButton)(() => ({
  maxHeight: "30px",
  padding: "4px, 8px, 4px, 8px",
  borderRadius: "4px",
  fontSize: "14px",

  "&.MuiButton-outlinedError": {
    borderWidth: "1.5px !important",
    background: "white",
  },
}));

const StyledNotificationTitle = styled(Typography)(() => ({
  width: "100%",
  textAlign: "left",
  color: pinwheelPalette.black[100],
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",

  "& *": {
    fontWeight: "600",
  },
}));

export const NotificationTitle = ({ children }: { children: React.ReactNode }) => {
  return <StyledNotificationTitle variant="body3">{children}</StyledNotificationTitle>;
};

const StyledNotificationBody = styled(Typography)(() => ({
  width: "100%",
  textAlign: "left",
  color: pinwheelPalette.black[100],
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  maxHeight: "42px",
}));

export const NotificationBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", columnGap: 1 }}>
      <Box
        sx={{
          width: "4px",
          minHeight: "100%",
          background: "#DDDEE1",
          borderRadius: "2px",
        }}
      />
      <StyledNotificationBody variant="body3">{children}</StyledNotificationBody>
    </Box>
  );
};

interface NotificationStatusProps {
  status: "success" | "error";
  text: string;
}

export const NotificationStatus = ({ status, text }: NotificationStatusProps) => {
  return (
    <Typography variant="caption" color={status === "success" ? pinwheelPalette.green[100] : pinwheelPalette.red[50]}>
      <>
        {text}{" "}
        <FontAwesomeIcon
          icon={status === "success" ? faCheck : faXmark}
          color={status === "success" ? pinwheelPalette.green[100] : pinwheelPalette.red[50]}
        />
      </>
    </Typography>
  );
};
