/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Events API
 * Pinwheel Events API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppTimeLimitRequestDto } from '../models';
// @ts-ignore
import { AppTimeLimitResponseDto } from '../models';
// @ts-ignore
import { HappinessRatingRequestDto } from '../models';
// @ts-ignore
import { HappinessRatingResponseDto } from '../models';
// @ts-ignore
import { PageVisitRequestDto } from '../models';
// @ts-ignore
import { PageVisitResponseDto } from '../models';
/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppTimeLimit: async (appTimeLimitRequestDto: AppTimeLimitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appTimeLimitRequestDto' is not null or undefined
            assertParamExists('saveAppTimeLimit', 'appTimeLimitRequestDto', appTimeLimitRequestDto)
            const localVarPath = `/events/analytics/appTimeLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appTimeLimitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HappinessRatingRequestDto} happinessRatingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHappinessRating: async (happinessRatingRequestDto: HappinessRatingRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'happinessRatingRequestDto' is not null or undefined
            assertParamExists('saveHappinessRating', 'happinessRatingRequestDto', happinessRatingRequestDto)
            const localVarPath = `/events/analytics/happinessRatings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(happinessRatingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageVisitRequestDto} pageVisitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePageVisit: async (pageVisitRequestDto: PageVisitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageVisitRequestDto' is not null or undefined
            assertParamExists('savePageVisit', 'pageVisitRequestDto', pageVisitRequestDto)
            const localVarPath = `/events/analytics/pageVisits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageVisitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAppTimeLimit(appTimeLimitRequestDto: AppTimeLimitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppTimeLimitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAppTimeLimit(appTimeLimitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HappinessRatingRequestDto} happinessRatingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveHappinessRating(happinessRatingRequestDto: HappinessRatingRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HappinessRatingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveHappinessRating(happinessRatingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageVisitRequestDto} pageVisitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePageVisit(pageVisitRequestDto: PageVisitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVisitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePageVisit(pageVisitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppTimeLimit(appTimeLimitRequestDto: AppTimeLimitRequestDto, options?: any): AxiosPromise<AppTimeLimitResponseDto> {
            return localVarFp.saveAppTimeLimit(appTimeLimitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HappinessRatingRequestDto} happinessRatingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHappinessRating(happinessRatingRequestDto: HappinessRatingRequestDto, options?: any): AxiosPromise<HappinessRatingResponseDto> {
            return localVarFp.saveHappinessRating(happinessRatingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageVisitRequestDto} pageVisitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePageVisit(pageVisitRequestDto: PageVisitRequestDto, options?: any): AxiosPromise<PageVisitResponseDto> {
            return localVarFp.savePageVisit(pageVisitRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public saveAppTimeLimit(appTimeLimitRequestDto: AppTimeLimitRequestDto, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).saveAppTimeLimit(appTimeLimitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HappinessRatingRequestDto} happinessRatingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public saveHappinessRating(happinessRatingRequestDto: HappinessRatingRequestDto, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).saveHappinessRating(happinessRatingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageVisitRequestDto} pageVisitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public savePageVisit(pageVisitRequestDto: PageVisitRequestDto, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).savePageVisit(pageVisitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
