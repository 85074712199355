import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export const useLocalStorage = <T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const dispatchEvent = useCallback((key: string, newValue: string | null) => {
    window.dispatchEvent(
      new StorageEvent("storage", {
        key,
        newValue,
      }),
    );
  }, []);

  const readValue = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  }, [initialValue, key]);

  const writeValue = useCallback(
    (value: T) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
        setStoredValue(value);
        dispatchEvent(key, JSON.stringify(value));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatchEvent, key],
  );

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue = useCallback((value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      writeValue(valueToStore);
    } catch (error) {
      console.error(error);
    }
  }, [storedValue, writeValue]);

  const removeValue = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(initialValue);
      dispatchEvent(key, initialValue ? JSON.stringify(initialValue) : null);
    } catch (error) {
      console.error(error);
    }
  }, [dispatchEvent, initialValue, key]);


  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [readValue, storedValue]);

  return [storedValue, setValue, removeValue];
};
