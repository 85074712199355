import { type ResourcesConfig } from "aws-amplify";

export const amplifyConfiguration: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
      identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
    },
  },
  Storage: {
    S3: {
      bucket: "pinwheel-contacts",
      region: "us-east-2",
    },
  },
};
