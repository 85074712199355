import { fetchAuthSession } from "aws-amplify/auth";
import {
  AppsApi,
  AppCategoriesApi,
  AppDescriptionsApi,
  CallLogsApi,
  CaregiversApi,
  ChildrenApi,
  ContactGroupsApi,
  ContactsApi,
  DayPresetsApi,
  FcmTokensApi,
  GeolocationsApi,
  MessagesApi,
  ModesApi,
  RoutinesApi,
  CaregiversPublicEndpointsApi,
  DeviceLockApi,
  ReportsApi,
  AnalyticsApi,
  DeviceControlApi,
  NotificationsApi,
  DeviceAuthApi,
} from "../openapi";
import { getServiceConfig } from "./helper";
import { BiometricsApi } from "../openapi/api/biometrics-api";

let appsAPI: AppsApi;
let appsCategoriesApi: AppCategoriesApi;
let appDescriptionsApi: AppDescriptionsApi;
let callLogsApi: CallLogsApi;
let caregiversApi: CaregiversApi;
let childrenApi: ChildrenApi;
let contactGroupsApi: ContactGroupsApi;
let contactsApi: ContactsApi;
let dayPresetsApi: DayPresetsApi;
let deviceLockApi: DeviceLockApi;
let fcmApi: FcmTokensApi;
let geolocationsApi: GeolocationsApi;
let messagesApi: MessagesApi;
let modesAPI: ModesApi;
let routinesApi: RoutinesApi;
let caregiverPublicApi: CaregiversPublicEndpointsApi;
let reportsApi: ReportsApi;
let analyticsApi: AnalyticsApi;
let biometricsApi: BiometricsApi;
let deviceControl: DeviceControlApi;
let notificationsApi: NotificationsApi;
let deviceAuthApi: DeviceAuthApi;

export interface AppTimeLimit {
  packageName: string;
  minutes?: number;
}

export interface DeviceData {
  lastReportedTime: string;
  realTimeStats: {
    wifiSSID: string;
    batteryLevel: number;
    sdCardSize: number;
    sdCardUsed: number;
  };
  hardwareInfo: {
    manufacturer: string;
    model: string;
  };
  imeiNumber: string;
  deviceId: string;
  appLimits?: {
    appUsage?: {
      Monday: [AppTimeLimit];
      Tuesday: [AppTimeLimit];
      Wednesday: [AppTimeLimit];
      Thursday: [AppTimeLimit];
      Friday: [AppTimeLimit];
      Saturday: [AppTimeLimit];
      Sunday: [AppTimeLimit];
    };
  };
}

const getAppsService = async () => {
  if (!appsAPI) appsAPI = new AppsApi(await getServiceConfig());
  return appsAPI;
};
const getAppCategoriesService = async () => {
  if (!appsCategoriesApi) appsCategoriesApi = new AppCategoriesApi(await getServiceConfig());
  return appsCategoriesApi;
};
const getAppsDescriptionService = async () => {
  if (!appDescriptionsApi) appDescriptionsApi = new AppDescriptionsApi(await getServiceConfig());
  return appDescriptionsApi;
};
const getCallLogService = async () => {
  if (!callLogsApi) callLogsApi = new CallLogsApi(await getServiceConfig());
  return callLogsApi;
};
const getCaregiversService = async () => {
  if (!caregiversApi) caregiversApi = new CaregiversApi(await getServiceConfig());
  return caregiversApi;
};
const getChildrenService = async () => {
  if (!childrenApi) childrenApi = new ChildrenApi(await getServiceConfig());
  return childrenApi;
};
const getContactGroupsService = async () => {
  if (!contactGroupsApi) contactGroupsApi = new ContactGroupsApi(await getServiceConfig());
  return contactGroupsApi;
};
const getContactsService = async () => {
  if (!contactsApi) contactsApi = new ContactsApi(await getServiceConfig());
  return contactsApi;
};
const getDaysPresetService = async () => {
  if (!dayPresetsApi) dayPresetsApi = new DayPresetsApi(await getServiceConfig());
  return dayPresetsApi;
};
const getDeviceLockService = async () => {
  if (!deviceLockApi) deviceLockApi = new DeviceLockApi(await getServiceConfig());
  return deviceLockApi;
};
const getFCMService = async () => {
  if (!fcmApi) fcmApi = new FcmTokensApi(await getServiceConfig());
  return fcmApi;
};
const getGeolocationsService = async () => {
  if (!geolocationsApi) geolocationsApi = new GeolocationsApi(await getServiceConfig());
  return geolocationsApi;
};
const getModesService = async () => {
  if (!modesAPI) modesAPI = new ModesApi(await getServiceConfig());
  return modesAPI;
};
const getMessagesService = async () => {
  if (!messagesApi) messagesApi = new MessagesApi(await getServiceConfig());
  return messagesApi;
};
const getRoutinesService = async () => {
  if (!routinesApi) routinesApi = new RoutinesApi(await getServiceConfig());
  return routinesApi;
};
const getCaregiverPublicService = async () => {
  if (!caregiverPublicApi) {
    caregiverPublicApi = new CaregiversPublicEndpointsApi(await getServiceConfig());
  }
  return caregiverPublicApi;
};
const getReportsService = async () => {
  if (!reportsApi) reportsApi = new ReportsApi(await getServiceConfig());
  return reportsApi;
};
const getAnalyticsService = async () => {
  if (!analyticsApi) analyticsApi = new AnalyticsApi(await getServiceConfig());
  return analyticsApi;
};

const getDeviceDataById = async (deviceId: string) => {
  const session = await fetchAuthSession();
  const accessToken = session?.tokens?.accessToken.toString();
  const response = await fetch(`${import.meta.env.VITE_FIELDX_API_URL}?deviceId=${deviceId}`, {
    method: "GET",
    headers: {
      authorization: accessToken ?? "",
    },
  });
  return response;
};

const getBiometricsService = async () => {
  if (!biometricsApi) biometricsApi = new BiometricsApi(await getServiceConfig());
  return biometricsApi;
};

const getDeviceGroupsService = async () => {
  if (!deviceControl) deviceControl = new DeviceControlApi(await getServiceConfig());
  return deviceControl;
};

const getNotificationsService = async () => {
  if (!notificationsApi) notificationsApi = new NotificationsApi(await getServiceConfig());
  return notificationsApi;
};

const getDeviceAuthService = async () => {
  if (!deviceAuthApi) deviceAuthApi = new DeviceAuthApi(await getServiceConfig());
  return deviceAuthApi;
};

export {
  getAnalyticsService,
  getAppsService,
  getAppCategoriesService,
  getAppsDescriptionService,
  getModesService,
  getCallLogService,
  getCaregiversService,
  getChildrenService,
  getContactGroupsService,
  getContactsService,
  getDaysPresetService,
  getDeviceLockService,
  getFCMService,
  getGeolocationsService,
  getMessagesService,
  getRoutinesService,
  getCaregiverPublicService,
  getReportsService,
  getDeviceDataById,
  getBiometricsService,
  getDeviceGroupsService,
  getNotificationsService,
  getDeviceAuthService,
};
