import { NoNotification } from "./no-notification";
import { useNotificationContext } from "./notification-context";
import { NotificationList } from "./notification-list";

export const ActionNotificationPanel = () => {
  const {
    actionNotifications: { hasNextPage, isLoading, isNextPageLoading, loadNextPage, data: notifications },
  } = useNotificationContext();
  if (notifications.length === 0) {
    return <NoNotification panel="action" />;
  }
  return (
    <NotificationList
      notifications={notifications}
      loadNextPage={loadNextPage}
      hasNextPage={hasNextPage}
      isNextPageLoading={isNextPageLoading}
      isLoading={isLoading}
    />
  );
};
