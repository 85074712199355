import { useCallback, useEffect, useMemo, useRef } from "react";
import { isCancel } from "axios";
import { handleApiError } from "@/util/error-handlers.ts";
import { getNotificationsService } from "@/util/api-helper";
import { getNotificationObject, isNotificationUnread } from "./utils";
import { useAlert } from "@/hooks/useAlert";
import { useNotificationReducer } from "./notification-reducer";
import {
  NotificationInteraction,
  NotificationObjectCompletedAction,
  NotificationObjectType,
  UpdateNotificationLogDto,
} from "@/openapi";
import { useMe } from "@/util/me-context";

const useAbortControllers = () => {
  const existingAbortControllers = useRef<AbortController[]>([]);

  const cleanup = useCallback(() => {
    if (existingAbortControllers.current.length > 0) {
      existingAbortControllers.current.forEach((controller) => controller.abort());
    }
  }, []);

  const newAbortController = useCallback(() => {
    cleanup();
    const abortController = new AbortController();
    existingAbortControllers.current = [abortController];
    return abortController;
  }, [cleanup]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return useMemo(() => ({ newAbortController }), [newAbortController]);
};

interface UseNotificationLogsProps {
  notificationObjectType?: Array<NotificationObjectType>;
  excludeCompletedAction?: NotificationObjectCompletedAction[];
  isActionCompleted?: boolean;
  limit?: number;
  offset?: number;
}

export const useNotificationLogs = ({
  notificationObjectType,
  excludeCompletedAction,
  isActionCompleted,
  limit: limitProps = 0,
  offset: offsetProps = 0,
}: UseNotificationLogsProps = {}) => {
  const offset = useRef(offsetProps || 0);
  const [state, dispatch] = useNotificationReducer();
  const { newAbortController } = useAbortControllers();
  const optionsRef = useRef({ limit: limitProps, offset: offsetProps, notificationObjectType, excludeCompletedAction, isActionCompleted });
  const cancelRequestRef = useRef(false);

  const handleGetNotifications = useCallback(
    async ({
      notificationObjectType,
      excludeCompletedAction,
      isActionCompleted,
      limit,
      offset,
    }: {
      notificationObjectType?: Array<NotificationObjectType>;
      excludeCompletedAction?: NotificationObjectCompletedAction[];
      isActionCompleted?: boolean;
      limit: number;
      offset: number;
    }) => {
      const abortController = newAbortController();
      const notificationService = await getNotificationsService();
      try {
        const response = await notificationService.getNotifications(
          notificationObjectType,
          undefined,
          undefined,
          undefined,
          excludeCompletedAction,
          undefined,
          undefined,
          undefined,
          isActionCompleted,
          offset,
          limit,
          {
            signal: abortController.signal,
          },
        );
        return response.data;
      } catch (e) {
        if (isCancel(e)) {
          return [];
        }
        handleApiError(e as Error);
        throw e;
      } finally {
        abortController.abort();
      }
    },
    [newAbortController],
  );

  const handleLoadNextPage = useCallback(async () => {
    if (!state.hasNextPage) return;
    dispatch({ type: "nextPageRequest" });
    const newOffset = offset.current + optionsRef.current.limit;
    try {
      const data = await handleGetNotifications({
        notificationObjectType: optionsRef.current.notificationObjectType,
        excludeCompletedAction: optionsRef.current.excludeCompletedAction,
        isActionCompleted: optionsRef.current.isActionCompleted,
        limit: optionsRef.current.limit,
        offset: newOffset,
      });

      const hasNextPage = data.length >= optionsRef.current.limit;

      dispatch({ type: "nextPageSuccess", payload: { data, hasNextPage: hasNextPage } });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: "nextPageFailure", payload: e.message });
      } else {
        dispatch({ type: "nextPageFailure", payload: "Unknown error" });
      }
    }
  }, [dispatch, handleGetNotifications, state.hasNextPage]);

  const handleRefetch = useCallback(async () => {
    dispatch({ type: "request" });
    offset.current = 0;
    optionsRef.current = { limit: limitProps, offset: offsetProps, notificationObjectType, excludeCompletedAction, isActionCompleted };

    try {
      const res = await handleGetNotifications({
        notificationObjectType,
        excludeCompletedAction,
        isActionCompleted,
        limit: limitProps,
        offset: offsetProps,
      });
      if (cancelRequestRef.current) return;
      dispatch({ type: "success", payload: res });
    } catch (e) {
      if (cancelRequestRef.current) return;
      if (e instanceof Error) {
        dispatch({ type: "failure", payload: e.message });
      } else {
        dispatch({ type: "failure", payload: "Unknown error" });
      }
    }
  }, [dispatch, excludeCompletedAction, handleGetNotifications, isActionCompleted, limitProps, notificationObjectType, offsetProps]);

  const markAsRead = useCallback(
    (id: string) => {
      dispatch({ type: "markAsRead", payload: id });
    },
    [dispatch],
  );

  const markAllAsRead = useCallback(() => {
    dispatch({ type: "markAllAsRead" });
  }, [dispatch]);

  const updateNotificationCompletedAction = useCallback(
    (id: string, completedAction: NotificationObjectCompletedAction) => {
      dispatch({ type: "completeAction", payload: { id, completedAction } });
    },
    [dispatch],
  );

  const removeNotification = useCallback(
    (id: string) => {
      dispatch({ type: "remove", payload: id });
    },
    [dispatch],
  );

  useEffect(() => {
    cancelRequestRef.current = false;
    handleRefetch();
    return () => {
      cancelRequestRef.current = true;
    };
  }, [handleRefetch]);

  return useMemo(
    () => ({
      ...state,
      loadNextPage: handleLoadNextPage,
      markAllAsRead,
      markAsRead,
      updateNotificationCompletedAction,
      removeNotification,
      refetch: handleRefetch,
    }),
    [handleLoadNextPage, handleRefetch, markAllAsRead, markAsRead, removeNotification, state, updateNotificationCompletedAction],
  );
};

export const useNotificationOps = (
  allNotifications: Pick<
    ReturnType<typeof useNotificationLogs>,
    "data" | "markAsRead" | "markAllAsRead" | "updateNotificationCompletedAction"
  >,
  actionNotifications: Pick<
    ReturnType<typeof useNotificationLogs>,
    "data" | "markAsRead" | "markAllAsRead" | "updateNotificationCompletedAction"
  >,
) => {
  const alert = useAlert();
  const me = useMe();

  const updateNotifications = useCallback(async (updatedNotifications: UpdateNotificationLogDto[]) => {
    try {
      const notificationService = await getNotificationsService();
      await notificationService.updateNotifications(updatedNotifications);
      return true;
    } catch (e) {
      handleApiError(e as Error);
      return false;
    }
  }, []);

  const markAsRead = useCallback(
    async (id: string) => {
      const updatedNotification: UpdateNotificationLogDto = {
        id: id,
        caregiverId: me.userId,
        interaction: NotificationInteraction.Seen,
      };
      const success = await updateNotifications([updatedNotification]);
      if (success) {
        allNotifications.markAsRead(id);
        actionNotifications.markAsRead(id);
      } else {
        alert.error("Failed to mark as read");
      }
    },
    [actionNotifications, alert, allNotifications, me.userId, updateNotifications],
  );

  const markAllAsRead = useCallback(async () => {
    const unreadNotifications = allNotifications.data.filter(isNotificationUnread);
    const unreadActionNotifications = actionNotifications.data.filter(isNotificationUnread);
    if (unreadNotifications.length === 0 && unreadActionNotifications.length === 0) {
      return;
    }

    const updatedNotifications = new Map<string, UpdateNotificationLogDto>();
    for (const notification of unreadNotifications) {
      updatedNotifications.set(notification.id, {
        id: notification.id,
        caregiverId: getNotificationObject(notification)?.caregiverId ?? "",
        interaction: NotificationInteraction.Seen,
      });
    }

    for (const notification of unreadActionNotifications) {
      updatedNotifications.set(notification.id, {
        id: notification.id,
        caregiverId: getNotificationObject(notification)?.caregiverId ?? "",
        interaction: NotificationInteraction.Seen,
      });
    }

    const success = await updateNotifications(Array.from(updatedNotifications.values()));
    if (success) {
      allNotifications.markAllAsRead();
      actionNotifications.markAllAsRead();
      alert.success("Marked all as read");
    } else {
      alert.error("Failed to mark all as read");
    }
  }, [actionNotifications, alert, allNotifications, updateNotifications]);

  const updateNotificationCompletedAction = useCallback(
    (id: string, completedAction: NotificationObjectCompletedAction) => {
      allNotifications.updateNotificationCompletedAction(id, completedAction);
      actionNotifications.updateNotificationCompletedAction(id, completedAction);
    },
    [actionNotifications, allNotifications],
  );

  const setCompletedAction = useCallback(
    async (ids: string[], completedAction: NotificationObjectCompletedAction) => {
      const success = await updateNotifications(ids.map(id => {
        return {
          id,
          caregiverId: me.userId,
          interaction: NotificationInteraction.Seen,
          completedAction
        }
      }));
      if (success) {
        ids.forEach(id => {
          actionNotifications.markAsRead(id);
          allNotifications.markAsRead(id);
          actionNotifications.updateNotificationCompletedAction(id, completedAction);
          allNotifications.updateNotificationCompletedAction(id, completedAction);
        });
      } else {
        alert.error("Failed to set completed action.");
      }
    },
    [actionNotifications, alert, allNotifications, me.userId, updateNotifications],
  );

  return useMemo(
    () => ({ markAsRead, markAllAsRead, updateNotificationCompletedAction, setCompletedAction }),
    [markAsRead, markAllAsRead, updateNotificationCompletedAction, setCompletedAction],
  );
};
