import { AsYouType, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import ApiConfiguration from "./apiConfiguration";
import { Mode } from "../redux/reducers/modesReducer/modesModel";
import moment from "moment";
import { App, Description } from "../redux/reducers/appsReducer/appsModel";
import { AppFilterType } from "../routes/apps";
import { getAnalyticsService, getModesService } from "./api-helper";

import { handleApiError } from "./error-handlers";
import { DeviceType, DeviceType1, ModeResponse } from "../openapi";
import { Me } from "@/context/me";

export const phoneRegex = new RegExp(/^\+?\*?[0-9()]+$/, "g");

// we will compare with this version to determine wether to show or hide the mode override dropdown
export const DESKTOP_VERSION_FOR_MODE_OVERRIDE = "2.3.0";

// we will compare with this version to determine wether to show or hide google play store
export const DESKTOP_VERSION_FOR_GPS = "2.25.1";

// we will compare with this version to determine wether to show or hide geofencing
export const DESKTOP_VERSION_FOR_GEOFENCING = "2.27.0";

// we will compare with this version to determine wether to show or hide geofencing
export const CAREGIVER_NATIVE_VERSION_FOR_SKIP_BILLING_IOS = "1.5.2";

// check if the browser is safari
export const isSafari = (navigator.userAgent.search("Safari") > -1 ||
navigator.userAgent.search("iPhone") > -1) &&
navigator.userAgent.search("Chrome") < 0;

const deviceType = (() => {
  return window?.isNative ? DeviceType.Mobile : DeviceType.Browser;
})();
const analyticsDeviceType =
  deviceType === DeviceType.Mobile ? DeviceType1.MobileApp : /Mobi/i.test(window?.navigator?.userAgent) ? DeviceType1.MobileWeb : DeviceType1.Desktop;

const convertFrom24To12Format = (time24: string) => {
  const matched = time24.match(/([0-9]{1,2}):([0-9]{2})/);
  // to avoid error while using keyboard on time input
  if (!matched) {
    return null;
  } else {
    const [sHours, minutes] = matched.slice(1);
    const period = +sHours < 12 || +sHours === 24 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  }
};

const trackEvent = async (event: string, email: string, eventData?: unknown) => {
  console.log(event, email, eventData);
};

function appTimeConvert(num: number) {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  let totalTime = "";
  if (hours > 0) {
    totalTime = `${hours} Hr `;
  }
  if (minutes > 0) {
    totalTime = totalTime + `${minutes} mins`;
  }
  return totalTime;
}

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\+1)|( )|(\()|(\)|(-))/g, "");
};

const formatPhoneNumberToE164Format = (phoneNumber: string) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith("+")) {
    return phoneNumber;
  }
  const allDigits = /^\d+$/.test(phoneNumber);
  let result = !allDigits ? formatPhoneNumber(phoneNumber) : phoneNumber;
  if (result.length === 10) {
    result = "+1" + result;
  } else if (result.length === 11 && result.startsWith("1")) {
    result = "+" + result;
  }
  return result;
};

const standardPhoneNumberFormat = (phoneNumber: string) => {
  if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "US");
    if (parsedPhoneNumber.country === "US") {
      return parsedPhoneNumber.formatNational();
    } else {
      return parsedPhoneNumber.formatInternational();
    }
  } else {
    return phoneNumber;
  }
};

const parsePhoneNumberAsYouType = (phoneNumber: string) => {
  if (phoneNumber.length > 8 && phoneNumber.match(phoneRegex)) {
    return new AsYouType("US").input(phoneNumber);
  }
  return phoneNumber;
};

const isNameIsANumber = (name: string) => {
  return !isNaN(parseInt(name, 10));
};

const getCognitoAPIWrapperURL = () => {
  return import.meta.env.VITE_ENV === "production"
    ? "https://4s92h8dt3i.execute-api.us-east-2.amazonaws.com/prod/"
    : "https://fsla6tjmoa.execute-api.us-east-2.amazonaws.com/dev/";
};

const isAppEnabled = (appId: string, modes: Mode[]): boolean => {
  if (modes) {
    return modes.some(mode => {
      const currentApp = mode.appIds.filter(a => a === appId);
      return currentApp.length > 0;
    });
  }
  return false;
};

const appHasKnownIssues = (app: { knownIssues: string }) => {
  return app && app.knownIssues && app.knownIssues.trim().length > 0;
};

// TODO: define a User model
const getUpvotyToken = async (user: Me) => {
  try {
    const response = await fetch(`${import.meta.env.VITE_UPVOTY_ENDPOINT}/get-token`, {
      method: "POST",
      body: JSON.stringify({
        email: user.attributes.email,
        name: user.username,
        id: user.attributes.sub
      })
    });
    return await response.text();
  } catch (error) {
    console.error("Error", error);
  }
};

function generateArrayOfYears() {
  const max = new Date().getFullYear();
  const min = max - 100;
  const years: number[] = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

function generateArrayOfMonths() {
  return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
}

export function generateArrayOfCountries() {
  return [
    { value: "US", label: "US" },
    { value: "CA", label: "Canada" },
  ];
}

export function generateArrayOfUSStates() {
  return [
    { value: "AK", label: "Alaska" },
    { value: "AL", label: "Alabama" },
    { value: "AR", label: "Arkansas" },
    { value: "AS", label: "American Samoa" },
    { value: "AZ", label: "Arizona" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DC", label: "District of Columbia" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "GU", label: "Guam" },
    { value: "HI", label: "Hawaii" },
    { value: "IA", label: "Iowa" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "MA", label: "Massachusetts" },
    { value: "MD", label: "Maryland" },
    { value: "ME", label: "Maine" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MO", label: "Missouri" },
    { value: "MS", label: "Mississippi" },
    { value: "MT", label: "Montana" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "NE", label: "Nebraska" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NV", label: "Nevada" },
    { value: "NY", label: "New York" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "PR", label: "Puerto Rico" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VA", label: "Virginia" },
    { value: "VI", label: "Virgin Islands" },
    { value: "VT", label: "Vermont" },
    { value: "WA", label: "Washington" },
    { value: "WI", label: "Wisconsin" },
    { value: "WV", label: "West Virginia" },
    { value: "WY", label: "Wyoming" }
  ];
}

export function generateArrayOfCanadaProvinces() {
  return [
    { value: "AB", label: "Alberta" },
    { value: "BC", label: "British Columbia" },
    { value: "MB", label: "Manitoba" },
    { value: "NB", label: "New Brunswick" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NS", label: "Nova Scotia" },
    { value: "NT", label: "Northwest Territories" },
    { value: "NU", label: "Nunavut" },
    { value: "ON", label: "Ontario" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "QC", label: "Quebec" },
    { value: "SK", label: "Saskatchewan" },
    { value: "YT", label: "Yukon" }
  ];
}


let myServiceConfig: ApiConfiguration;
const getServiceConfig = async () => {
  if (!myServiceConfig) {
    myServiceConfig = new ApiConfiguration();
  }
  return myServiceConfig;
};

// regExp for check short phone number like *87 or 12345;
const shortPhoneNumber = /^([*|\d][\d+]{2,7})$/g;

const calculatePhoneNumber = (phoneNumber: string) => {
  const shortNumber = phoneNumber.match(shortPhoneNumber);
  // if not checked, short number +1 will be added
  if (shortNumber) {
    const [number] = shortNumber;
    return number;
  } else {
    return parsePhoneNumber(phoneNumber, "US").number;
  }
};

const createNewLine = (a: string) => {
  const b = a.split(" ");
  let c = "";
  b.forEach((item, index) => {
    if (index % 2 === 0) {
      c += item + " ";
    } else {
      c += item + "\n";
    }
  });
  return c;
};

const shortTimeFormat = (ts: Date) => {
  const formattedTime = moment(ts).fromNow();
  const customFormattedTime = formattedTime
    .replace("minutes", "min")
    .replace("minute", "min")
    .replace("hours", "hr")
    .replace("hour", "hr")
    .replace("seconds", "sec")
    .replace("an", "1")
    .replace("second", "sec");
    return customFormattedTime
};

const mintTohrs = (mins: number) => {
  const minsHour = moment
    .duration(Math.round(mins), "minutes")
    .toString()
    .replace("PT", "")
    .replace("H", "_")
    .replace("M", "");
  const n = minsHour.split("_");
  if (n.length > 1) {
    const _hr = parseInt(n[0]) > 1 ? " hrs" : " hr";
    return n[0] + _hr + "\n" + n[1] + " mins ";
  } else {
    if (n[0] != "P0D") {
      return n[0] + " mins";
    } else {
      return ""
    }
  }
};

const hoursMinutesToDate = (hourAndDate?: string | null) => {
  const segments = hourAndDate?.split(":") || [];
  if (segments.length !== 2) {
    return null;
  }
  const hours = Number(segments[0]);
  const minutes = Number(segments[1]);

  if (hours == 24) {
    return new Date(`1970-01-02T00:${padToTwoPlaces(minutes)}`);
  }
  return new Date(`1970-01-01T${padToTwoPlaces(hours)}:${padToTwoPlaces(minutes)}`);
}

const dateToHoursMinutes = (timeString: string | null, midnightIsTomorrow = false) => {
  const newDate = hoursMinutesToDate(timeString);
  if (newDate === null) {
    return null;
  }
  let hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  if (hours === 0 && minutes === 0 && midnightIsTomorrow) {
    hours = 24;
  }
  return padToTwoPlaces(hours) + ":" + padToTwoPlaces(minutes);
}

const padToTwoPlaces = (n: number) => {
  return (n <= 9) ? "0" + n : n;
}

const filterApps = (apps:App[],appsFilterOption:AppFilterType,appDescriptions:Description[] | undefined)=>{
  if (apps.length>0) {
    let sortApps: App[] = [...apps].sort((a1, a2) => {
      if (appsFilterOption.sortByOption === "alphabetical") {
        return a1.name.localeCompare(a2.name);
      } else {
        return (a2.downloads ? a2.downloads : 0) - (a1.downloads ? a1.downloads : 0);
      }
    });

    if (appsFilterOption.appLevels.length > 0) {
      sortApps = sortApps?.filter(app => {
        if (app.level && appsFilterOption.appLevels.includes(app.level)) {
          return app;
        }
      });
    }

    if(appsFilterOption.freeApps) {
      const freeAppsIds = appDescriptions?.filter((elm)=>{
        if(elm.label.includes("Free")){
          return elm;
        }
      }).map((e)=>{
        return e.id
      })
      sortApps = sortApps?.filter(app => {
        if("appDescriptionIds" in app){
           return app.appDescriptionIds?.filter((desId)=>{
              return  freeAppsIds?.includes(desId)
            })
        }
      });
    }
    return sortApps
  }
}
const trackPage = async (route:string) =>{
  try {
    const analyticsService = await getAnalyticsService();
    await analyticsService.savePageVisit({route:route, deviceType: analyticsDeviceType, userAgent: window.navigator.userAgent})
  } catch (error) {
    handleApiError(error);
  } 
}

const isValidEmail = (email: string) => {
  // https://stackoverflow.com/a/46181
  return email
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const fixPredefinedAllModeAppsRlt = async (modes: ModeResponse[]) => {
  if (localStorage.getItem("predefinedAllModeAppsRltFixed")) {
    return modes;
  }
  let predefinedAllModeIndex = -1;
  const enabledAppIds = new Set<string>();
  // Loop through modes to find the index of predefined-all-mode
  // and prepare unique list (set) of all appIds assigned to modes other than predefined-all-mode.
  modes.forEach((mode, index) => {
    if (mode.isPredefined && mode.name.toLowerCase() === "all mode") {
      if (predefinedAllModeIndex === -1) {
        predefinedAllModeIndex = index;
      }
    } else {
      mode.appIds.forEach(id => enabledAppIds.add(id));
    }
  });

  // Then find which enabled appIds are missing from the predefined-all-mode.
  if (predefinedAllModeIndex !== -1) {
    const predefinedAllModeAppIds = new Set(modes[predefinedAllModeIndex].appIds);
    const predefinedAllModeMissingAppIds: string[] = [];
    enabledAppIds.forEach(appId => {
      if (!predefinedAllModeAppIds.has(appId)) {
        predefinedAllModeMissingAppIds.push(appId);
      }
    });
    // Assign missing appIds (if any) to predefined-all-mode.
    if (predefinedAllModeMissingAppIds.length > 0) {
      modes[predefinedAllModeIndex].appIds.push(...predefinedAllModeMissingAppIds);
      const modesService = await getModesService();
      modesService.modifyModeAppRelations({
        modeId: modes[predefinedAllModeIndex].id,
        addIds: predefinedAllModeMissingAppIds
      });
    }
  }
  localStorage.setItem("predefinedAllModeAppsRltFixed", "true");
  return modes;
}

const compareDesktopVersion = (versionString: string, compareVersion: string) => {
  const splittedArray = versionString.split(".");
  const currentVersion = `${splittedArray[0]}.${splittedArray[1]}.${splittedArray[2]}`;
  return currentVersion.localeCompare(compareVersion, undefined, { numeric: true }) >= 0;
}

const MAIN_TITLE = "Caregiver Admin - Pinwheel";

const setTitle = (title: string) => {
  title ? (document.title = title + " | " + MAIN_TITLE) : (document.title = MAIN_TITLE);
};

export interface AppTimeLimitOptions {
  label: string,
  value: number
}

const appTimeLimitOptions: AppTimeLimitOptions[] = [
  {
    label: "Unlimited",
    value: 24*60
  },
  {
    label: "10 minutes",
    value: 10
  },
  {
    label: "20 minutes",
    value: 20
  },
  {
    label: "30 minutes",
    value: 30
  },
  {
    label: "40 minutes",
    value: 40
  },
  {
    label: "50 minutes",
    value: 50
  },
  {
    label: "1 hour",
    value: 60
  },
  {
    label: "1 hour 10 minutes",
    value: 70
  },
  {
    label: "1 hour 20 minutes",
    value: 80
  },
  {
    label: "1 hour 30 minutes",
    value: 90
  },
]

const getReactNativeDeviceInfo = () => {
  if (window?.isNative && window?.rnDeviceInfo) {
    return window.rnDeviceInfo;
  }
};

const getReactNativeOS = () => {
  const rnDeviceInfo = getReactNativeDeviceInfo();
  if (rnDeviceInfo) {
    return rnDeviceInfo.osName.toLowerCase();
  }
};

const getReactNativeAppVersion = () => {
  const rnDeviceInfo = getReactNativeDeviceInfo();
  if (rnDeviceInfo) {
    return rnDeviceInfo.appVersion;
  }
};

const hashCode = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
      const code = string.charCodeAt(i);
      hash = ((hash<<5)-hash)+code;
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}


export {
  deviceType,
  analyticsDeviceType,
  convertFrom24To12Format,
  trackEvent,
  formatPhoneNumber,
  getCognitoAPIWrapperURL,
  formatPhoneNumberToE164Format,
  isAppEnabled,
  getUpvotyToken,
  getServiceConfig,
  generateArrayOfYears,
  generateArrayOfMonths,
  appHasKnownIssues,
  shortPhoneNumber,
  calculatePhoneNumber,
  mintTohrs,
  createNewLine,
  filterApps,
  trackPage,
  isValidEmail,
  hoursMinutesToDate,
  dateToHoursMinutes,
  appTimeConvert,
  fixPredefinedAllModeAppsRlt,
  compareDesktopVersion,
  setTitle,
  shortTimeFormat,
  appTimeLimitOptions,
  getReactNativeOS,
  getReactNativeDeviceInfo,
  getReactNativeAppVersion,
  parsePhoneNumberAsYouType,
  isNameIsANumber,
  hashCode,
  standardPhoneNumberFormat
};
