import { Box, Button, Typography, alpha } from "@mui/material";
import { useCallback } from "react";
import { faBell, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "./notification-context";
import { pinwheelPalette } from "@/styles/theme";

interface Props {
  panel: "all" | "action";
}

export const NoNotification = ({ panel }: Props) => {
  const navigate = useNavigate();
  const { toggleNotificationPanel } = useNotificationContext();

  const handleSettingsClick = useCallback(() => {
    toggleNotificationPanel();
    navigate("/settings/notifications", { replace: true });
  }, [navigate, toggleNotificationPanel]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%" pt="5rem">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
        <FontAwesomeIcon icon={panel === "all" ? faBell : faThumbsUp} size="5x" color={alpha(pinwheelPalette.black[100], 0.25)} />
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
          <Typography variant="h3" textAlign="center" color={pinwheelPalette.black[100]}>
            {panel === "all" ? "No Notifications Yet!" : "You’re all caught up!"}
          </Typography>
          <Typography variant="body3" color={alpha(pinwheelPalette.black[100], 0.55)} textAlign="center">
            {panel === "all" ? "You can manage notifications in your Settings." : "You have no actions right now."}
          </Typography>
        </Box>
        {panel === "all" ? (
          <Button variant="contained" onClick={handleSettingsClick} sx={{ textTransform: "none" }}>
            Manage notifications
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};
