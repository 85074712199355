import { Menu, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleHistoryCirclePlus } from "@fortawesome/pro-light-svg-icons";

interface Props {
  isModalMenuOpen: boolean;
  setModalMenuOpen: (b: boolean) => void;
  showUpvotyBoard: (s: string) => Promise<void>;
  setFeatureWidgetOpen: (b: boolean) => void;
  anchorEl: null | HTMLElement;
}

const BulbMenuModal = ({ isModalMenuOpen, setModalMenuOpen, showUpvotyBoard, setFeatureWidgetOpen, anchorEl }: Props) => {
  const handleFeatureRequestClick = async () => {
    await showUpvotyBoard(`${import.meta.env.VITE_UPVOTY_TOKEN}`);
    setFeatureWidgetOpen(true);
    setModalMenuOpen(false);
  };

  return (
    <Menu
      id="bulb-menu"
      open={isModalMenuOpen}
      onClose={() => setModalMenuOpen(!isModalMenuOpen)}
      anchorEl={anchorEl}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: "\"\"",
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem sx={{
        "&:active, &:focus": {
          background: "transparent"
        }
      }} onClick={handleFeatureRequestClick}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faRectangleHistoryCirclePlus} />
        </ListItemIcon>
        <Typography fontSize={18}>Feature Request</Typography>
      </MenuItem>
    </Menu>
  );
};

export default BulbMenuModal;
