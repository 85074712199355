import React, { useCallback, useMemo, useState } from "react";
import { typographyTheme } from "@/styles/customTheme";
import { Box, ThemeProvider } from "@mui/material";
import { NotificationButton } from "./notification-button";
import { NotificationCenterMobileDialog } from "./notification-center-mobile-dialog";
import { NotificationCenterDesktopPopover } from "./notification-cernter-desktop-popover";
import { useNotificationContext } from "./notification-context";
import { useIsMobile } from "@/hooks";

export const Notification = () => {
  const [notificationPanelAnchor, setNotificationPanel] = useState<HTMLButtonElement | null>(null);
  const isMobile = useIsMobile();
  const { isNotificationPanelOpen, toggleNotificationPanel, allNotifications } = useNotificationContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setNotificationPanel(event.currentTarget);
      toggleNotificationPanel();
    },
    [toggleNotificationPanel],
  );

  const handleClose = useCallback(() => {
    setNotificationPanel(null);
    toggleNotificationPanel();
  }, [toggleNotificationPanel]);

  const notificationPanelOpen = useMemo(
    () => Boolean(notificationPanelAnchor && isNotificationPanelOpen),
    [notificationPanelAnchor, isNotificationPanelOpen],
  );
  const id = useMemo(() => (notificationPanelOpen ? "simple-popover" : undefined), [notificationPanelOpen]);

  return (
    <ThemeProvider theme={typographyTheme}>
      <Box>
        <NotificationButton aria-describedby={id} onClick={handleClick} notificationCount={allNotifications.unreadNotifications.length} />
        {isMobile ? (
          <NotificationCenterMobileDialog open={notificationPanelOpen && isNotificationPanelOpen} onClose={handleClose} />
        ) : (
          <NotificationCenterDesktopPopover
            id={id}
            open={notificationPanelOpen && isNotificationPanelOpen}
            onClose={handleClose}
            anchorEl={notificationPanelAnchor}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};
