/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.14.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasicModeResponse } from '../models';
// @ts-ignore
import { CreateModeRequest } from '../models';
// @ts-ignore
import { ModesResponse } from '../models';
// @ts-ignore
import { ModifyModeAppRequest } from '../models';
// @ts-ignore
import { ModifyModeAppResponse } from '../models';
// @ts-ignore
import { ModifyModeContactGroupRequest } from '../models';
// @ts-ignore
import { ModifyModeContactGroupResponse } from '../models';
// @ts-ignore
import { UpdateModeRequest } from '../models';
/**
 * ModesApi - axios parameter creator
 * @export
 */
export const ModesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateModeRequest} createModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMode: async (createModeRequest: CreateModeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createModeRequest' is not null or undefined
            assertParamExists('createMode', 'createModeRequest', createModeRequest)
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modeId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMode: async (modeId: string, replacementId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modeId' is not null or undefined
            assertParamExists('deleteMode', 'modeId', modeId)
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (modeId !== undefined) {
                localVarQueryParameter['modeId'] = modeId;
            }

            if (replacementId !== undefined) {
                localVarQueryParameter['replacementId'] = replacementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModes: async (childId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyModeAppRequest} modifyModeAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeAppRelations: async (modifyModeAppRequest: ModifyModeAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyModeAppRequest' is not null or undefined
            assertParamExists('modifyModeAppRelations', 'modifyModeAppRequest', modifyModeAppRequest)
            const localVarPath = `/main/modes/modeApps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyModeAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeContactGroupRelations: async (modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyModeContactGroupRequest' is not null or undefined
            assertParamExists('modifyModeContactGroupRelations', 'modifyModeContactGroupRequest', modifyModeContactGroupRequest)
            const localVarPath = `/main/modes/modeContactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyModeContactGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateModeRequest} updateModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMode: async (updateModeRequest: UpdateModeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateModeRequest' is not null or undefined
            assertParamExists('updateMode', 'updateModeRequest', updateModeRequest)
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModesApi - functional programming interface
 * @export
 */
export const ModesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateModeRequest} createModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMode(createModeRequest: CreateModeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicModeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMode(createModeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modeId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMode(modeId: string, replacementId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMode(modeId, replacementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModes(childId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModes(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyModeAppRequest} modifyModeAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyModeAppRelations(modifyModeAppRequest: ModifyModeAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyModeAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyModeAppRelations(modifyModeAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyModeContactGroupRelations(modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyModeContactGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyModeContactGroupRelations(modifyModeContactGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateModeRequest} updateModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMode(updateModeRequest: UpdateModeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicModeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMode(updateModeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModesApi - factory interface
 * @export
 */
export const ModesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateModeRequest} createModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMode(createModeRequest: CreateModeRequest, options?: any): AxiosPromise<BasicModeResponse> {
            return localVarFp.createMode(createModeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modeId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMode(modeId: string, replacementId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteMode(modeId, replacementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModes(childId?: string, options?: any): AxiosPromise<ModesResponse> {
            return localVarFp.getModes(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyModeAppRequest} modifyModeAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeAppRelations(modifyModeAppRequest: ModifyModeAppRequest, options?: any): AxiosPromise<ModifyModeAppResponse> {
            return localVarFp.modifyModeAppRelations(modifyModeAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeContactGroupRelations(modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options?: any): AxiosPromise<ModifyModeContactGroupResponse> {
            return localVarFp.modifyModeContactGroupRelations(modifyModeContactGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateModeRequest} updateModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMode(updateModeRequest: UpdateModeRequest, options?: any): AxiosPromise<BasicModeResponse> {
            return localVarFp.updateMode(updateModeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModesApi - object-oriented interface
 * @export
 * @class ModesApi
 * @extends {BaseAPI}
 */
export class ModesApi extends BaseAPI {
    /**
     * 
     * @param {CreateModeRequest} createModeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModesApi
     */
    public createMode(createModeRequest: CreateModeRequest, options?: AxiosRequestConfig) {
        return ModesApiFp(this.configuration).createMode(createModeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modeId 
     * @param {string} [replacementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModesApi
     */
    public deleteMode(modeId: string, replacementId?: string, options?: AxiosRequestConfig) {
        return ModesApiFp(this.configuration).deleteMode(modeId, replacementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModesApi
     */
    public getModes(childId?: string, options?: AxiosRequestConfig) {
        return ModesApiFp(this.configuration).getModes(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyModeAppRequest} modifyModeAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModesApi
     */
    public modifyModeAppRelations(modifyModeAppRequest: ModifyModeAppRequest, options?: AxiosRequestConfig) {
        return ModesApiFp(this.configuration).modifyModeAppRelations(modifyModeAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModesApi
     */
    public modifyModeContactGroupRelations(modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options?: AxiosRequestConfig) {
        return ModesApiFp(this.configuration).modifyModeContactGroupRelations(modifyModeContactGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateModeRequest} updateModeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModesApi
     */
    public updateMode(updateModeRequest: UpdateModeRequest, options?: AxiosRequestConfig) {
        return ModesApiFp(this.configuration).updateMode(updateModeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
