import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

let isFirebaseInit = false;
const firebaseInit = async () => {
  if (!isFirebaseInit) {
    // eslint-disable-next-line no-undef
    // we have to change firebase config in firebase-messaging-sws.js file as well
    firebase.initializeApp({
      apiKey: "AIzaSyAX7y86NmjJtG2nThEL5SwcL_anchpiI_o",
      authDomain: "pinwheel-fcm.firebaseapp.com",
      databaseURL: "https://pinwheel-fcm.firebaseio.com",
      projectId: "pinwheel-fcm",
      storageBucket: "pinwheel-fcm.appspot.com",
      messagingSenderId: "970235282312",
      appId: "1:970235282312:web:67e662dd29ec4ea61bf03d"
    });
    isFirebaseInit = true;
  }
  return firebase;
};

export default firebaseInit;
