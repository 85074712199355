import { useChildContext } from "@/context/child";
import { useMemo } from "react";

export const useChild = () => {
  const { child } = useChildContext();
  return child;
};

export const useSafeListMode = () => {
  const child = useChild();
  return useMemo(() => child?.configuration.safelistMode, [child?.configuration.safelistMode]);
};
