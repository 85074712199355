import { Box } from "@mui/material";
import LogoImg from "../../img/pinwheel-logo-icon-white_2.svg";

const Logo = () => {
  return (
    <Box
      sx={{
        "&": {
          margin: "2rem 0 .5rem -10px",
        },
          "& img": {
            maxWidth: "35%",
            height: "auto"
        }
        }}
        >
        <img src={LogoImg} alt="logo"/>
    </Box>
    )
}

export default Logo
