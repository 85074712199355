import { useNotificationContext } from "@/components/NotificationCenter/notification-context";
import ChildContext from "@/context/child";
import { Me } from "@/context/me";
import { useMe } from "@/util/me-context";
import { ChildResponse, ContactStatus, CreateContactRequest, UpdateContactRequest } from "@/openapi";
import {
  createContact as createContactAction,
  updateContact as updateContactAction,
  deleteContact as deleteContactAction,
  selectContacts,
  storeContacts,
} from "@/redux/reducers/contactsReducer/contactsReducer";
import { useAppDispatch, useAppSelector } from "@/redux/store/hooks";
import { getContactsService } from "@/util/api-helper";
import { useChild } from "@/util/child-helper";
import { handleApiError } from "@/util/error-handlers";
import { formatPhoneNumberToE164Format } from "@/util/helper";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

export const useFetchContacts = () => {
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const fetchContacts = useCallback(async (childId: string) => {
    setIsLoadingContacts(true);
    try {
      const contactsService = await getContactsService();
      const contactsData = (await contactsService.getChildContacts(childId)).data.contacts;
      return contactsData;
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoadingContacts(false);
    }
  }, []);

  return useMemo(() => ({ fetchContacts, isLoadingContacts }), [fetchContacts, isLoadingContacts]);
};

export const useGetContacts = () => {
  const dispatch = useAppDispatch();
  const { child } = useContext(ChildContext);
  const me = useMe();
  const contacts = useAppSelector(selectContacts);
  const [isCreatingContact, setIsCreatingContact] = useState(false);
  const { fetchContacts, isLoadingContacts } = useFetchContacts();

  const pendingContacts = useMemo(() => contacts?.filter((contact) => contact.status === ContactStatus.Proposed) || [], [contacts]);

  const createDefaultContact = useCallback(async (me: Me, child: ChildResponse) => {
    setIsCreatingContact(true);
    try {
      const newContact: {
        name: string;
        phoneNumber: string;
        status: ContactStatus;
        childrenId: string;
        isEmergency: boolean;
      } = {
        name: me.attributes["custom:firstName"] + " " + me?.attributes["custom:lastName"],
        phoneNumber: formatPhoneNumberToE164Format(me.attributes.phone_number),
        status: ContactStatus.Approved,
        childrenId: child?.id,
        isEmergency: true,
      };

      // create contact with rest api
      const contactsService = await getContactsService();
      const newContactResponse = (await contactsService.createContact(newContact)).data;
      if (newContactResponse) {
        dispatch(createContactAction(newContactResponse));
      }
    } catch (err) {
      handleApiError(err);
    } finally {
      setIsCreatingContact(false);
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (contacts && contacts.length === 0 && me && child && !isCreatingContact) {
        await createDefaultContact(me, child);
      }
    })();
  }, [contacts, child, me, isCreatingContact, createDefaultContact]);

  useEffect(() => {
    (async () => {
      if (child && !contacts) {
        const contacts = await fetchContacts(child.id);
        if (contacts) {
          dispatch(storeContacts(contacts));
        }
      }
    })();
  }, [child, contacts, dispatch, fetchContacts]);

  return useMemo(() => ({ contacts, isLoadingContacts, pendingContacts }), [contacts, isLoadingContacts, pendingContacts]);
};

export const useContactOperations = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const contacts = useAppSelector(selectContacts);
  const { refetch } = useNotificationContext();
  const child = useChild();

  // create contact
  const createContact = useCallback(
    async (contact: CreateContactRequest) => {
      try {
        setIsLoading(true);
        const contactsService = await getContactsService();
        const response = (await contactsService.createContact(contact)).data;
        if (child?.id === contact.childrenId) {
          dispatch(createContactAction(response));
        }
        return response;
      } catch (error) {
        handleApiError(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [child?.id, dispatch],
  );

  const updateContact = useCallback(async (contact: UpdateContactRequest) => {
    try {
      setIsLoading(true);
      const contactsService = await getContactsService();
      const response = (await contactsService.updateContact(contact)).data;
      if (child?.id === contact.childrenId) {
        dispatch(updateContactAction(response));
      }
      return response;
    } catch (error) {
      handleApiError(error as Error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [child?.id, dispatch]);

  // delete contact
  const deleteContact = useCallback(
    async (contactId: string) => {
      setIsLoading(true);
      const contactsService = await getContactsService();
      try {
        await contactsService.deleteContact(contactId);
        dispatch(deleteContactAction(contactId));
        refetch();
      } catch (error) {
        handleApiError(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, refetch],
  );

  // approve contact
  const approveContact = useCallback(
    async (contactId: string) => {
      setIsLoading(true);
      const contactsService = await getContactsService();
      try {
        const contact = contacts!.find((c) => c.id === contactId)!;
        if (!contact) {
          return;
        }
        const request: UpdateContactRequest = {
          ...contact,
          status: ContactStatus.Approved,
        };
        const response = (await contactsService.updateContact(request)).data;
        dispatch(updateContactAction(response));
      } catch (error) {
        handleApiError(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [contacts, dispatch],
  );

  // reject contact
  const rejectContact = useCallback(
    async ({ name, phoneNumber, childrenId }: { name?: string; phoneNumber: string; childrenId: string }) => {
      try {
        setIsLoading(true);
        const contactsService = await getContactsService();
        const contact = contacts?.find((c) => c.phoneNumber === phoneNumber && c.childrenId === childrenId);
        if (contact) {
          const result = await updateContact({
            ...contact,
            name: name ?? contact.name,
            status: ContactStatus.Rejected,
          });
          return result;
        }
        const createContactRequest: CreateContactRequest = {
          status: ContactStatus.Rejected,
          isEmergency: false,
          name: name ?? phoneNumber,
          phoneNumber: phoneNumber,
          childrenId: childrenId,
        };
        const createContactResponse = (await contactsService.createContact(createContactRequest)).data;
        dispatch(createContactAction(createContactResponse));
      } catch (error) {
        handleApiError(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [contacts, dispatch, updateContact],
  );

  return useMemo(
    () => ({ createContact, updateContact, deleteContact, approveContact, rejectContact, isLoading }),
    [createContact, updateContact, deleteContact, approveContact, rejectContact, isLoading],
  );
};
