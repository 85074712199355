import { ComponentType, lazy } from "react";

const key = "pinwheel-error-reloaded";

// Need this any as it breaks some of the component imports if we use unknown.  Too much time to figure out why
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithErrorHandling = <T extends ComponentType<any>>(load: () => Promise<{ default: T }>) =>  {
  return lazy(async () => {
    const sessionStorage = window.sessionStorage;
    try {
      const result = await load();
      sessionStorage.removeItem(key)
      return result;
    } catch (error: unknown) {
      const {name} = error as {name: string};
      if (name === "ChunkLoadError" && sessionStorage.getItem(key) !== "true") {
        sessionStorage.setItem(key, "true");
        window.location.reload();
      }
      throw error;
    }
  });
}