/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.35.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BooleanResponse } from '../models';
// @ts-ignore
import { DeviceGroupsResponseDto } from '../models';
// @ts-ignore
import { ModifyDeviceGroupRequestDto } from '../models';
// @ts-ignore
import { SetPasscodeRequest } from '../models';
/**
 * DeviceControlApi - axios parameter creator
 * @export
 */
export const DeviceControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} childId 
         * @param {any} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceGroups: async (childId: string, deviceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getDeviceGroups', 'childId', childId)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceGroups', 'deviceId', deviceId)
            const localVarPath = `/main/deviceControl/deviceGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceGroup: async (modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyDeviceGroupRequestDto' is not null or undefined
            assertParamExists('modifyDeviceGroup', 'modifyDeviceGroupRequestDto', modifyDeviceGroupRequestDto)
            const localVarPath = `/main/deviceControl/deviceGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyDeviceGroupRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasscodeRequest} setPasscodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasscode: async (setPasscodeRequest: SetPasscodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasscodeRequest' is not null or undefined
            assertParamExists('setPasscode', 'setPasscodeRequest', setPasscodeRequest)
            const localVarPath = `/main/deviceControl/setPasscode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasscodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceControlApi - functional programming interface
 * @export
 */
export const DeviceControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceControlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} childId 
         * @param {any} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceGroups(childId: string, deviceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceGroupsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceGroups(childId, deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDeviceGroup(modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyDeviceGroup(modifyDeviceGroupRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetPasscodeRequest} setPasscodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPasscode(setPasscodeRequest: SetPasscodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPasscode(setPasscodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceControlApi - factory interface
 * @export
 */
export const DeviceControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceControlApiFp(configuration)
    return {
        /**
         * 
         * @param {string} childId 
         * @param {any} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceGroups(childId: string, deviceId: any, options?: any): AxiosPromise<DeviceGroupsResponseDto> {
            return localVarFp.getDeviceGroups(childId, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceGroup(modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.modifyDeviceGroup(modifyDeviceGroupRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPasscodeRequest} setPasscodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasscode(setPasscodeRequest: SetPasscodeRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.setPasscode(setPasscodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceControlApi - object-oriented interface
 * @export
 * @class DeviceControlApi
 * @extends {BaseAPI}
 */
export class DeviceControlApi extends BaseAPI {
    /**
     * 
     * @param {string} childId 
     * @param {any} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControlApi
     */
    public getDeviceGroups(childId: string, deviceId: any, options?: AxiosRequestConfig) {
        return DeviceControlApiFp(this.configuration).getDeviceGroups(childId, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControlApi
     */
    public modifyDeviceGroup(modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options?: AxiosRequestConfig) {
        return DeviceControlApiFp(this.configuration).modifyDeviceGroup(modifyDeviceGroupRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPasscodeRequest} setPasscodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControlApi
     */
    public setPasscode(setPasscodeRequest: SetPasscodeRequest, options?: AxiosRequestConfig) {
        return DeviceControlApiFp(this.configuration).setPasscode(setPasscodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
