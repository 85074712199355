import { useNavigate } from "react-router-dom";

import "./no-child.sass";
import { Button, Typography } from "@mui/material";

const NoChild = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      {
        pathname: "/sign-up-child"
      },
      {
        state: {
          fromDashboard: true
        }
      }
    );
  };

  return (
    <div className="no-child">
      <Typography variant="h2">You haven&apos;t added a child to your account yet</Typography>
      <p className="no-child-message">Please add a child to set up your Pinwheel phone.</p>
      <Button size="large" onClick={handleClick}>
        Add Child
      </Button>
    </div>
  );
};

export default NoChild;
